
import React, { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {updateSuperCategory,validateSuperCatSlugUrl} from "../../../redux/superCategory/superCategorySlice"

const UpdateSuperCategory = () => {
  const { superCatTotal} = useSelector((store) => store.superCategory);
  const [names, setNames] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [supercatId, setSupercatId] = useState("");

  const dispatch = useDispatch();
  
  const config = {
    Headers: { "Content-Type": "application/json" },
  };

  const params = useParams();

  useEffect(() => {
    const supercatUrl = params.slugurl;

    const updatesupercat = superCatTotal.find((supercat) => supercat.slugUrl === supercatUrl);
    setSupercatId(updatesupercat._id);
    setNames(updatesupercat.name);
    setSlugUrl(updatesupercat.slugUrl);
    setMetatitle(updatesupercat.metaTitle);
    setMetakeyword(updatesupercat.metaKeyword);
    setMetadesc(updatesupercat.metaDesc);
  }, []);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setNames(value);
    setCheckSlugUrl("");
    const catSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(catSlug);


  };

  const handlesubmit = async (e) => {
    e.preventDefault();

      const formData = {
        name: names.charAt(0).toUpperCase() + names.slice(1).toLowerCase(),
        slugUrl: slugUrl,
        metaTitle: metatitle,
        metaKeyword: metakeyword,
        metaDesc: metadesc,
        supercatid: supercatId,
      };
      console.log(formData);
      const data = await dispatch(updateSuperCategory(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Supercategory Updated");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Update Super Category</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update Super Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter name....."
                        value={names}
                        onChange={verifyslugurl}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Title *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                   
                    <Col>
                      <Form.Label>Meta Keyword *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Descriptrion *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                <button className="btn btn-primary" type="submit">
                      Update Super Category
                    </button>
                </div>
                {/* <div className="card-footer ">
                  {checkslugUrl !== "" ? (
                    <button className="btn btn-primary" type="submit">
                      Update Super Category
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Update Super Category
                    </button>
                  )}
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateSuperCategory;
