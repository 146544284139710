import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import moment from "moment/moment";
import { Button } from "@mui/material";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";

const OrderGeneratePage = () => {
  const { ordertotal, singleOrder } = useSelector((store) => store.orders);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [temploading, setTempLoading] = useState(true);
  const [orderCreating, setOrderCreating] = useState(true);
  const [tempButtonloading, setTempButtonloading] = useState(false);
  const [activeClientIds, setActiveClientIds] = useState([]);
  const [takenOrders, setTakenOrders] = useState([]);

  const [eligibleOrders, seteligibleOrders] = useState([]);
  const [createdOrder, setCreatedOrder] = useState([]);

  const orderGenerateClick = async () => {
    if (!tempButtonloading) {
      setTempButtonloading(true);
      try {
        const generateOrders = await axios.get(
          `${Baseurl}/api/v1/subscriptiontakenOrder/order-generation`
        );
        if (generateOrders.data.success === true) {
          const orders = generateOrders.data.ordercreate;
          setCreatedOrder(orders);
          setTempButtonloading(false);
        }
      } catch (error) {}
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Order ID",
      width: 70,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto" style={{ textTransform: "uppercase" }}>
            <Link to={`/admin/subscription-order-info/${params.row._id}`}>
              {params.row._id.slice(18)}
            </Link>
          </div>
        );
      },
    },

    {
      field: "ClientId",
      width: 80,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto" style={{ textTransform: "uppercase" }}>
            {params.row.ClientId.slice(18)}
          </div>
        );
      },
    },

    {
      field: "ClientName",
      headerName: "ClientName",
      width: 130,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "Order DateTime",
      width: 150,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto flex-column my-2">
            <h6>{moment(params.row.createdAt).format("MMM/DD/YYYY")}</h6>
            <h6>{moment(params.row.createdAt).format("h:mm:ss a")}</h6>
          </div>
        );
      },
    },
    {
      field: "TotalAmount",
      headerName: "Amount",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Address",
      headerName: "Address",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Mobile",
      headerName: "Mobile",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div className="card-footer d-flex justify-content-between">
              <h5>SubScription Order Generate</h5>
              {tempButtonloading ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ background: "#556ee6" }}
                  >
                    Order Generating...
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => orderGenerateClick()}
                    style={{ background: "#556ee6" }}
                  >
                    Order Generate
                  </Button>
                </>
              )}
            </div>

            {createdOrder.length > 0 ? (
              <>
                <div
                  style={{ height: 650, width: "100%" }}
                  className="d-flex m-auto text-align-center"
                >
                  <DataGrid
                    rows={createdOrder}
                    columns={columns}
                    getRowId={(row) => row._id}
                    rowHeight={100}
                    getRowHeight={() => "auto"}
                    pageSize={20}
                    rowsPerPageOptions={[5]}
                    sx={{
                      ".MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                      },
                    }}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default OrderGeneratePage;
