import React, { useEffect } from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";
import { UpdateGroceryPackSize } from "../../../redux/grocery/grocerySlice";

const GroceryPackSizeUpload = () => {
  const { mobileimageicon } = useSelector((store) => store.groceries);
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [uploadedProductName, setUploadedProductName] = useState("");
  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);

    setData(datas);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    let numbers = 0;
    for (let i in data) {
      let myrow = data[i];

      const formData = {
        ProductId: myrow.ItemId,
        PackSize: myrow.PackSize,
        ImgUrlDeskTiles: `https://www.kiranaworld.in/Admin/Products/Desktop/${myrow.ImqUrlTiles}`,
        ImgUrlMbl: `https://www.kiranaworld.in/Admin/Products/Desktop/${myrow.ImqUrlDesk}`,
        ImgUrlMblIcon: `https://www.kiranaworld.in/Admin/Products/Desktop/${myrow.ImqUrlDesk}`,
        ImgUrlDesk: `https://www.kiranaworld.in/Admin/Products/Desktop/${myrow.ImqUrlDesk}`,
        ImgUrlDeskIcon: `https://www.kiranaworld.in/Admin/Products/Desktop/${myrow.ImqUrlDesk}`,
        CostPrc: 0,
        GstCost: 18,
        SellingPrice: myrow.SellingPrice,
        GstSellPrc: 18,
        Mrp: myrow.Mrp,
        OutOfStack: myrow.OutofStack,
        Pri: myrow.pri,
        StockAutoUpdate: myrow.StockAutoUpdate,
        Discount: myrow.Discount,
        HotProducts: myrow.HotProducts,
        Trending: myrow.trending,
        CartQuantity: 0,
        maximumQuantity: 10,
      };
      console.log(formData, "formData");
      const packupdate = await dispatch(UpdateGroceryPackSize(formData));
      if (packupdate.payload.success) {
        setUploadedProductName(
          `${numbers + 1}) ${myrow.Itenname}  upload successfull`
        );
      }
    }
  };
  return (
    <div className="content-wrapper">
      <div className="employeesContainer">
        <form onSubmit={handlesubmit}>
          <div className="card m-4">
            <div className="card-footer">
              <h5>Grocery PackSize Bulk Upload</h5>
            </div>
            <div className="card-body">
              <h6 className="ps-3">Upload Excel File *</h6>
              <Row className="g-2  m-2">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput"></Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="enter name....."
                    onChange={(e) => handlefile(e)}
                  />
                </Col>
                <Col md>
                  <button
                    className="btn btn-primary me-2 mt-4 ml-5 "
                    type="submit"
                  >
                    Grocery PackSize Bulk Upload
                  </button>
                </Col>
              </Row>
              <p style={{ color: "green" }} className="mt-2">
                {uploadedProductName}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GroceryPackSizeUpload;
