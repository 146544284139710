
import React from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom'
import Footer from '../Footer'
import Header from '../Header';
import Menu from '../Menu';

const MainLayout = () => {
    const { isAuth } = useSelector((store) => store.auth);

  const navigate = useNavigate();
  
    useEffect(() => {
      if (!isAuth) {
        navigate("/");
      }
    }, []);
  
  return (
    <>
    <Header />
    <Menu/>
    <Outlet />
    <Footer />
    </>
  )
}

export default MainLayout;