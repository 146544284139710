import React from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { subscriptionDelete } from "../../../redux/subscription/subscriptionSlice";

const ListSubscription = () => {
  const dispatch = useDispatch();

  const { subscriptiontotal } = useSelector((store) => store.subscriptions);

  const deleteClick = async (e, id) => {
    const data = await dispatch(subscriptionDelete(id));
  };
  const columns = [
    {
      field: "id",
      headerName: "S.No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "NAME",
      width: 260,
      // align: "center",
      headerAlign: "center",
    },
    {
      headerName: "IMAGE",
      width: 230,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.ImgUrlMbl}
              width={100}
              alt="thumnail"
            />
          </>
        );
      },
    },

    {
      field: "ACTION",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
            <Link to={`/admin/updatesubscription/${params.row.slugUrl}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 200,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto my-3">
            <Button
              variant="contained"
              onClick={(e) => deleteClick(e, cellValues.row._id)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div
              style={{ height: 1000, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={subscriptiontotal}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={10}
                rowHeight={50}
                getRowHeight={() => "auto"}
                rowsPerPageOptions={[5]}
              />
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ListSubscription;
