import React from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const ProductSEO = () => {
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            {/* <form onSubmit={handlesubmit}> */}
            <div className="card m-4">
              <div className="card-footer">
                <h5>Product SEO</h5>
              </div>
              <div className="card-body">
                <Row className="g-2  m-2">
                  <Col md>
                    {/* <Form.Label htmlFor="disabledTextInput">
                      Category
                    </Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Enter Item Name"
                      // value={brand}
                      // required
                      // onChange={(e) => verifyslugurl(e)}
                    />
                  </Col>
                  <Col md>
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ProductSEO;
