import React, { useState,useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import {
  validateProductSlugUrl,
  productImages,
  productPost
} from "../../../../redux/product/productSlice";
import { MdOutlineRemove } from "react-icons/md";

const AddElectronics = () => {
  const { brandtotal } = useSelector((store) => store.brand);
  const { electronicssuperCatTotal } = useSelector((store) => store.superCategory);
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const { electronicscategory } = useSelector((store) => store.categoryAll);

  const {
    mobileimage,
    desktopimage,
    isproductdeskimageLoading
  } = useSelector((store) => store.products);

  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [brand, setBrand] = useState("");
  const [brandid, setBrandid] = useState("");
  const [catbrand, setCatbrand] = useState([]);
  const [slugUrl, setSlugUrl] = useState("");
  const [electronics, setElectronics] = useState("");
  const [details, setDetails] = useState("");
  const [design, setDesign] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [rating, setRating] = useState("");
  const [costprice, setCostprice] = useState("");
  const [mrp, setMrp] = useState("");
  const [dealoftheday, setDealoftheday] = useState(Boolean(0));
  const [trending, setTrending] = useState(Boolean(0));
  const [outofstock, setOutofstock] = useState(Boolean(0));
  const [offers, setOffers] = useState(Boolean(0));
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");


  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };


  
  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

  useEffect(() => {
    const subcatbycat = subcategorytotal.filter((cat) =>cat.categoryId === categoryid )
    setSubcat(subcatbycat);
  }, [categoryid,subcategorytotal])

  const selectBrand = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setBrand(text);
    setBrandid(value);
  };

  useEffect(() => {
    const brandbycat = brandtotal.filter(
      (brand) => brand.superCategoryId === electronicssuperCatTotal._id
    );
    setCatbrand(brandbycat);
  }, [brandtotal]);



  const dispatch = useDispatch();

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setElectronics(value);
    setErrorcolor("green");
    setCheckSlugUrl("");
    setError("Electronics available");
    const electronicsSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(electronicsSlug);

    if (electronicsSlug !== "") {
      let responce = await dispatch(validateProductSlugUrl(electronicsSlug));
      if (responce.payload.success) {
        setError("Electronics already exist");
        setErrorcolor("red");
      } else if (electronicsSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (electronicsSlug.length >= 3) {
        setErrorcolor("green");
        setError("Electronics available");
        setCheckSlugUrl(electronicsSlug);
      }
    }
  };

  const discount = Math.round(((mrp - costprice) * 100) / mrp);
  
  const handlesubmit = async (e) => {

    if (checkslugUrl !== "") {
    e.preventDefault();
    const formData = {
      name: electronics.charAt(0).toUpperCase() + electronics.slice(1).toLowerCase(),
      slugUrl: checkslugUrl,
      superCategory: electronicssuperCatTotal.name,
      superCategoryId: electronicssuperCatTotal._id,
      category: category,
      categoryId: categoryid,
      subCategory: subcategory,
      subCategoryId: subcategoryid,
      brand: brand,
      // brandId: brandid,
      metaTitle: metatitle,
      metaKeyword: metakeyword,
      metaDesc: metadesc,
      details: details,
      design: design,
      rating: rating,
      dealoftheday: dealoftheday,
      trending: trending,
      outOfStock: outofstock,
      offers: offers,
      options:options
    };
    console.log(formData);
    const data = await dispatch(productPost(formData));

    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("Electronics Added");
      } else {
        alert(data.message);
        console.log(data.error);
      }
    }
  } else {
    alert("please enter correct values");
  }
  };



  const ImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
             dispatch(productImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  useEffect(() => {
    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["mobileImage"] = desktopimage;
      temp[index]["desktopImage"] = desktopimage;
    }
    setOptions(temp);
  }, [mobileimage, desktopimage]);




  const [options, setOptions] = useState([
    {
      desktopImage: desktopimage,
      mobileImage: mobileimage,
      optionName: "",
      skuCode: "",
      barcode: "",
      color: "",
      varientName:"",
      vspName:"",
      vspDetails:"",
      spName:"",
      spDetails:"",
      costPrice: 0,
      gstCost: 0,
      sellingPrice: 0,
      gstSellPrice: 0,
      mrp: 0,
      discounts: 0,
    },
  ]);
console.log(options);
  const handleCheck = (index, e, selected) => {
    console.log(index, e.target.value, selected);
    let temp = [...options];
    temp[index][selected] = e.target.value;
    setOptions(temp);
    if (
      (selected === "mrp" || selected === "sellprice") &&
      temp[index]["mrp"] > 0 &&
      temp[index]["sellprice"] > 0 &&
      temp[index]["mrp"] > temp[index]["sellprice"]
    ) {
      handleDiscount(index, e, selected);
    }
  };
  const handleDiscount = (index, e, selected) => {
    let temp = [...options];
    const discounts = Math.round(
      ((temp[index]["mrp"] - temp[index]["sellprice"]) * 100) /
        temp[index]["mrp"]
    );
    console.log(discounts);
    console.log(index);
    temp[index]["discounts"] = discounts;

    setOptions(temp);
  };

  const handleNewRow = () => {
    setOptions([
      ...options,
      {
        desktopImage: desktopimage,
        mobileImage: mobileimage,
        optionName: "",
        skuCode: "",
        barcode: "",
        color: "",
        varientName:"",
        vspName:"",
        vspDetails:"",
        spName:"",
        spDetails:"",
        costPrice: 0,
        gstCost: 0,
        sellingPrice: 0,
        gstSellPrice: 0,
        mrp: 0,
        discounts: 0,
      },
    ]);
  };

  const removeRowClick = (index) => {
    const optionList = [...options];
    optionList.splice(index, 1);
    setOptions(optionList);
  };
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Electronics</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={electronicscategory._id}
                        name={electronicscategory.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {electronicscategory.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub Category *
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={subCat._id}
                        name={subCat.name}
                      >
                        <option value="0">{"Select Sub Category"}</option>
                        {subCat.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Brand *
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectBrand(e);
                        }}
                        value={catbrand.name}
                        name={catbrand.name}
                      >
                        <option value="0">{"Select Brand"}</option>
                        {catbrand.map((data) => (
                          <option
                            key={data.name}
                            value={data.name}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        value={electronics}
                        onChange={(e) => verifyslugurl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Details *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Details....."
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Design*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Design....."
                        value={design}
                        onChange={(e) => setDesign(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="dealoftheday"
                        checked={dealoftheday === true}
                        onChange={(e) => {
                          setDealoftheday(e.target.checked);
                        }}
                      />
                      <Form.Label>Deal of the day</Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        value="trending"
                        className="me-1"
                        checked={trending === true}
                        onChange={(e) => {
                          setTrending(e.target.checked);
                        }}
                      />
                      <Form.Label>Trending </Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        value="outofstock"
                        className="me-1"
                        checked={outofstock === true}
                        onChange={(e) => {
                          setOutofstock(e.target.checked);
                        }}
                      />
                      <Form.Label>Out Of Stock </Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="offers"
                        checked={offers === true}
                        onChange={(e) => {
                          setOffers(e.target.checked);
                        }}
                      />
                      <Form.Label>Offers</Form.Label>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Rating *
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="enter Rating....."
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Title *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Keyword *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Descriptrion *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 w-50">
                    <Col md>
                      <Form.Label>Electronic Image</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={ImageChange}
                      />
                      <p>
                        * The image width and height should be 500px * 500px
                      </p>
                      <div>
                        {isproductdeskimageLoading ? (
                          <div></div>
                        ) : (
                          <img src={desktopimage} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>

                  <div className="card-footer mt-4">
                    <h3 className="text-center">Option</h3>
                  </div>
                  <div>
                    {options.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              SkuCode
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.skuCode}
                              required
                              onChange={(e) => handleCheck(index, e, "skuCode")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Barcode
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.barcode}
                              required
                              onChange={(e) => handleCheck(index, e, "barcode")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Option Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.optionName}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "optionName")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Color
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.color}
                              required
                              onChange={(e) => handleCheck(index, e, "color")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Varient Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.varientName}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "varientName")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              VSP Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.vspName}
                              required
                              onChange={(e) => handleCheck(index, e, "vspName")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              VSP Details
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.vspDetails}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "vspDetails")
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              SP Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.spName}
                              required
                              onChange={(e) => handleCheck(index, e, "spName")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              SP Details
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.spDetails}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "spDetails")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Purches P
                            </Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.costPrice}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "costPrice")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="gstcost">GST Cost</Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.gstCost}
                              required
                              onChange={(e) => handleCheck(index, e, "gstCost")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Sell Price
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.sellingPrice}
                              onChange={(e) =>
                                handleCheck(index, e, "sellingPrice")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              GST Sell P
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.gstSellPrice}
                              onChange={(e) =>
                                handleCheck(index, e, "gstSellPrice")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              MRP
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.mrp}
                              onChange={(e) => handleCheck(index, e, "mrp")}
                            />
                          </Col>
                          {options.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) => removeRowClick(e, index)}
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={handleNewRow}
                    >
                      Add More
                    </Button>
                  </div>
                </div>
                {/* <div className="card-footer text-center ">
                <button className="btn btn-primary me-2 " type="submit">
                  Add Electronics
                </button>
              </div> */}
                <div className="card-footer text-center">
                  {checkslugUrl !== "" &&
                  categoryid !== "" &&
                  subcategoryid !== "" &&
                  brandid !== "" &&
                  !isproductdeskimageLoading ? (
                    <button className="btn btn-danger" type="submit">
                      Add Electronics
                    </button>
                  ) : (
                    <button className="btn btn-danger" disabled type="submit">
                      Add Electronics
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddElectronics;

