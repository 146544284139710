import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import moment from "moment/moment";
import { Button } from "@mui/material";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const SubscriptionOrderInfo = () => {
  const { isAuth, loginData } = useSelector((store) => store.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [products, setProducts] = useState([]);
  const [orderinfo, setOrderinfo] = useState("");

  useEffect(() => {
    const orderId = params.orderid;
    if (params.orderid) {
      const fetchData = async () => {
        try {
          const url = `${Baseurl}/api/v1/subscriptionorder/sub-single-order/${params.orderid}`;
          const orderFetch = await axios.get(url);

          try {
            if (orderFetch.data.success) {
              const orderdetails = orderFetch.data.subscriptionorder;
              setOrderinfo(orderdetails);
              setProducts(orderdetails.OrderProducts);
            }
          } catch (error) {}
        } catch (error) {}
      };
      fetchData();
    }
  }, [params.orderid]);

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ImgUrl",
      headerName: "Image",
      width: 200,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.ImgUrl}
              width={100}
              alt="image"
            />
          </>
        );
      },
    },

    {
      field: "ProductName",
      headerName: "Product Name",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Price",
      headerName: "Price",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Qty",
      headerName: "Quantity",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "TotalPrice",
      headerName: "Total Price",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card m-4">
            <div
              style={{ height: 650, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={products}
                columns={columns}
                getRowId={(row) => row._id}
                rowHeight={100}
                getRowHeight={() => "auto"}
                pageSize={10}
                rowsPerPageOptions={[10]}
              />
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default SubscriptionOrderInfo;
