import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
 
  universaltag: localStorage.getItem("universaltag")
    ? JSON.parse(localStorage.getItem("universaltag"))
    : [],
  categorytag: localStorage.getItem("categorytag")
    ? JSON.parse(localStorage.getItem("categorytag"))
    : [],
  bannertag: localStorage.getItem("bannertag")
    ? JSON.parse(localStorage.getItem("bannertag"))
    : [],
  unimobileimage: "",
  unidesktopimage: "",
  unimobileimageLoading: true,
  unidesktopimageLoading: true,
  catmobileimage: "",
  catdesktopimage: "",
  catmobileimageLoading: true,
  catdesktopimageLoading: true,
  banmobileimage: "",
  bandesktopimage: "",
  banmobileimageLoading: true,
  bandesktopimageLoading: true,
  isLoading: true,
  isUnivtagLoading: true,
  isdelUnitagLoading: true,
  isCattagLoading: true,
  isdelCattagLoading: true,
  isBannertagLoading: true,
  isdelBannertagLoading: true,
};


// ==============universaltag======================

export const getuniversaltag = createAsyncThunk(
  "tag/getuniversal",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/universaltag/all`;
      const resp = await axios(url);
      return resp.data.universaltags;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const postuniversaltag = createAsyncThunk(
  "tag/postuniversaltag",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/universaltag/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const uniTagMobileImages = createAsyncThunk(
  "uniTag/uniTagMobileImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/universaltag/unitagmobileimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("unitagmobileimages Not create");
    }
  }
);

export const uniTagDeskImages = createAsyncThunk(
  "uniTag/uniTagDeskImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/universaltag/unitagdeskimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("unitagdeskimages Not create");
    }
  }
);

export const uniTagDelete = createAsyncThunk(
  "uniTag/uniTagDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/universaltag/deleteunitag/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("universaltag Not create");
    }
  }
);

// ==============categorytag======================

export const getcategorytag = createAsyncThunk(
  "tag/getcategorytag",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/categorytag/all`;
      const resp = await axios(url);
      return resp.data.categorytags;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const postcategorytag = createAsyncThunk(
  "tag/postuniversaltag",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/categorytag/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const catTagMobileImages = createAsyncThunk(
  "catTag/catTagMobileImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/categorytag/cattagmobileimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("cattagmobileimages Not create");
    }
  }
);

export const catTagDeskImages = createAsyncThunk(
  "catTag/catTagDeskImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/categorytag/cattagdeskimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("cattagdeskimages Not create");
    }
  }
);
export const catTagDelete = createAsyncThunk(
  "catTag/catTagDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/categorytag/deletecattag/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("categorytag Not create");
    }
  }
);


// ==============bannertag======================

export const getbannertag = createAsyncThunk("tag/getbannertag", async (thunkAPI) => {
  try {
    const url = `${Baseurl}/api/v1/bannertag/all`;
    const resp = await axios(url);
    return resp.data.bannertags;
  } catch (error) {
    return thunkAPI.rejectWithValue("404 Not Found");
  }
});

export const postbannertag = createAsyncThunk(
  "tag/postbannertag",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/bannertag/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const bannerTagMobileImages = createAsyncThunk(
  "bannerTag/bannerTagMobileImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        // Headers: { "Content-Type": "application/json" },
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
          Accept: "application/json",
          type: "formData",
        },
      };
      const url = `${Baseurl}/api/v1/bannertag/bannermobileimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("bannermobileimages Not create");
    }
  }
);

export const bannerTagDeskImages = createAsyncThunk(
  "bannerTag/bannerTagDeskImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        // Headers: { "Content-Type": "application/json" },
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
          Accept: "application/json",
          type: "formData",
        },
      };
      const url = `${Baseurl}/api/v1/bannertag/bannerdeskimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("bannerdeskimages Not create");
    }
  }
);

export const bannerTagDelete = createAsyncThunk(
  "bannerTag/bannerTagDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/bannertag/deletebannertag/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("bannertag Not create");
    }
  }
);



const TagsSlice = createSlice({
  name: "Tags",
  initialState,
  extraReducers: {
    //============== universaltag====================

    [getuniversaltag.pending]: (state) => {
      state.isLoading = true;
    },
    [getuniversaltag.fulfilled]: (state, action) => {
      state.universaltag = action.payload;

      state.isLoading = false;
      state.isUnivtagLoading = false;
      localStorage.setItem("universaltag", JSON.stringify(state.universaltag));
    },
    [getuniversaltag.rejected]: (state, action) => {
      state.isLoading = true;
    },

    [postuniversaltag.pending]: (state) => {
      state.isUnivtagLoading = true;
    },

    [postuniversaltag.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.universaltag = [
          ...state.universaltag,
          action.payload.universaltag,
        ];
        localStorage.setItem(
          "universaltag",
          JSON.stringify(state.universaltag)
        );
      }
      state.isUnivtagLoading = false;
    },
    [postuniversaltag.rejected]: (state, action) => {
      state.isUnivtagLoading = true;
    },

    [uniTagMobileImages.pending]: (state) => {
      state.unimobileimageLoading = true;
    },
    [uniTagMobileImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.unimobileimage = action.payload.mobileImages;
      }
      state.unimobileimageLoading = false;
    },
    [uniTagMobileImages.rejected]: (state, action) => {
      state.unimobileimageLoading = true;
    },

    [uniTagDeskImages.pending]: (state) => {
      state.unidesktopimageLoading = true;
    },
    [uniTagDeskImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.unidesktopimage = action.payload.desktopImages;
      }
      state.unidesktopimageLoading = false;
    },
    [uniTagDeskImages.rejected]: (state, action) => {
      state.unidesktopimageLoading = true;
    },

    [uniTagDelete.pending]: (state) => {
      state.isdelUnitagLoading = true;
    },

    [uniTagDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.universaltag = state.universaltag.filter(
          (unitag) => unitag._id !== action.payload.id
        );
        state.isdelUnitagLoading = false;
        localStorage.setItem(
          "universaltag",
          JSON.stringify(state.universaltag)
        );
      }
    },

    [uniTagDelete.rejected]: (state, action) => {
      state.isdelUnitagLoading = true;
    },

    //============== category tag====================

    [getcategorytag.pending]: (state) => {
      state.isLoading = true;
    },
    [getcategorytag.fulfilled]: (state, action) => {
      state.categorytag = action.payload;

      state.isLoading = false;
      state.isCattagLoading = false;
      localStorage.setItem("categorytag", JSON.stringify(state.categorytag));
    },
    [getcategorytag.rejected]: (state, action) => {
      state.isLoading = true;
    },

    [postcategorytag.pending]: (state) => {
      state.isCattagLoading = true;
    },

    [postcategorytag.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.categorytag = [...state.categorytag, action.payload.categorytag];
        localStorage.setItem("categorytag", JSON.stringify(state.categorytag));
      }
      state.isCattagLoading = false;
    },
    [postcategorytag.rejected]: (state, action) => {
      state.isCattagLoading = true;
    },

    [catTagMobileImages.pending]: (state) => {
      state.catmobileimageLoading = true;
    },
    [catTagMobileImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.catmobileimage = action.payload.mobileImages;
      }
      state.catmobileimageLoading = false;
    },
    [catTagMobileImages.rejected]: (state, action) => {
      state.catmobileimageLoading = true;
    },

    [catTagDeskImages.pending]: (state) => {
      state.catdesktopimageLoading = true;
    },
    [catTagDeskImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.catdesktopimage = action.payload.desktopImages;
      }
      state.catdesktopimageLoading = false;
    },
    [catTagDeskImages.rejected]: (state, action) => {
      state.catdesktopimageLoading = true;
    },

    [catTagDelete.pending]: (state) => {
      state.isdelCattagLoading = true;
    },

    [catTagDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.categorytag = state.categorytag.filter(
          (cattag) => cattag._id !== action.payload.id
        );
        state.isdelCattagLoading = false;
        localStorage.setItem("categorytag", JSON.stringify(state.categorytag));
      }
    },

    [catTagDelete.rejected]: (state, action) => {
      state.isdelCattagLoading = true;
    },

    //============== banner tag====================

    [getbannertag.pending]: (state) => {
      state.isLoading = true;
    },
    [getbannertag.fulfilled]: (state, action) => {
      state.bannertag = action.payload;

      state.isLoading = false;
      state.isBannertagLoading = false;
      localStorage.setItem("bannertag", JSON.stringify(state.bannertag));
    },
    [getbannertag.rejected]: (state, action) => {
      state.isLoading = true;
    },

    [postbannertag.pending]: (state) => {
      state.isBannertagLoading = true;
    },

    [postbannertag.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.bannertag = [...state.bannertag, action.payload.bannertag];
        localStorage.setItem("bannertag", JSON.stringify(state.bannertag));
      }
      state.isBannertagLoading = false;
    },
    [postbannertag.rejected]: (state, action) => {
      state.isBannertagLoading = true;
    },

    [bannerTagMobileImages.pending]: (state) => {
      state.banmobileimageLoading = true;
    },
    [bannerTagMobileImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.banmobileimage = action.payload.mobileImages;
      }
      state.banmobileimageLoading = false;
    },
    [bannerTagMobileImages.rejected]: (state, action) => {
      state.banmobileimageLoading = true;
    },

    [bannerTagDeskImages.pending]: (state) => {
      state.bandesktopimageLoading = true;
    },
    [bannerTagDeskImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.bandesktopimage = action.payload.desktopImages;
      }
      state.bandesktopimageLoading = false;
    },
    [bannerTagDeskImages.rejected]: (state, action) => {
      state.bandesktopimageLoading = true;
    },

    [bannerTagDelete.pending]: (state) => {
      state.isdelBannertagLoading = true;
    },

    [bannerTagDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.bannertag = state.bannertag.filter(
          (btag) => btag._id !== action.payload.id
        );
        state.isdelBannertagLoading = false;
        localStorage.setItem(
          "bannertag",
          JSON.stringify(state.bannertag)
        );
      }
    },

    [bannerTagDelete.rejected]: (state, action) => {
      state.isdelBannertagLoading = true;
    },
  },
});
export default TagsSlice.reducer;