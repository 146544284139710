import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  topbannertotal: localStorage.getItem("topbannertotal")
    ? JSON.parse(localStorage.getItem("topbannertotal"))
    : [],
  mobileimage: "",
  desktopimage: "",
  posterimage: "",
  mobileimageLoading: true,
  desktopimageLoading: true,
  posterimageLoading: true,
  isLoading: true,
  isTopBannerLoading: true,
  delTopBannerLoading: true,
};

export const getTopBanner = createAsyncThunk(
  "TopBanner/getTopBanner",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/topbanner/all`;
      const resp = await axios(url);
      return resp.data.topBanner;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const TopBannerPost = createAsyncThunk(
  "TopBanner/TopBannerPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/topbanner/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("TopBannerPost Not create");
    }
  }
);

export const DesktopImages = createAsyncThunk(
  "Desktop/DesktopImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/topbanner/deskimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("DesktopImages Not create");
    }
  }
);

export const MobileImages = createAsyncThunk(
  "Mobile/MobileImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/topbanner/mobileimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("MobileImages Not create");
    }
  }
);

export const MobilePosterImages = createAsyncThunk(
  "MobilePoster/MobilePosterImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/topbanner/posterimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("MobilePosterImages Not create");
    }
  }
);

export const TopBannerDelete = createAsyncThunk(
  "TopBanner/TopBannerDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/topbanner/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("topbanner Not create");
    }
  }
);

const TopBannerSlice = createSlice({
  name: "TopBanner",
  initialState,
  reducers: {
    resetimages(state, action) {
      state.desktopimage = "";
      state.mobileimage = "";
      state.posterimage = "";
    },
  },
  extraReducers: {
    [getTopBanner.pending]: (state) => {
      state.isLoading = true;
    },
    [getTopBanner.fulfilled]: (state, action) => {
      state.topbannertotal = action.payload;

      state.isLoading = false;
      state.isTopBannerLoading = false;
      localStorage.setItem(
        "topbannertotal",
        JSON.stringify(state.topbannertotal)
      );
    },
    [getTopBanner.rejected]: (state) => {
      state.isLoading = true;
    },

    [TopBannerPost.pending]: (state) => {
      state.isTopBannerLoading = true;
    },

    [TopBannerPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.topbannertotal = [
          ...state.topbannertotal,
          action.payload.topBanner,
        ];
        localStorage.setItem(
          "topbannertotal",
          JSON.stringify(state.topbannertotal)
        );
      }
      state.isTopBannerLoading = false;
    },

    [TopBannerPost.rejected]: (state) => {
      state.isTopBannerLoading = true;
    },

    [DesktopImages.pending]: (state) => {
      state.desktopimageLoading = true;
    },
    [DesktopImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.desktopimage = action.payload.desktopImages;
      }
      state.desktopimageLoading = false;
    },
    [DesktopImages.rejected]: (state) => {
      state.desktopimageLoading = true;
    },

    [MobileImages.pending]: (state) => {
      state.mobileimageLoading = true;
    },
    [MobileImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.mobileimage = action.payload.mobileImages;
      }
      state.mobileimageLoading = false;
    },
    [MobileImages.rejected]: (state) => {
      state.mobileimageLoading = true;
    },

    [MobilePosterImages.pending]: (state) => {
      state.posterimageLoading = true;
    },
    [MobilePosterImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.posterimage = action.payload.posterImages;
      }
      state.posterimageLoading = false;
    },
    [MobilePosterImages.rejected]: (state) => {
      state.posterimageLoading = true;
    },

    [TopBannerDelete.pending]: (state) => {
      state.delTopBannerLoading = true;
    },

    [TopBannerDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.topbannertotal = state.topbannertotal.filter(
          (slider) => slider._id !== action.payload.id
        );
        localStorage.setItem(
          "topbannertotal",
          JSON.stringify(state.topbannertotal)
        );
      }
      state.delTopBannerLoading = false;
    },

    [TopBannerDelete.rejected]: (state) => {
      state.delTopBannerLoading = true;
    },
  },
});

export const {resetimages} = TopBannerSlice.actions;
export default TopBannerSlice.reducer;
