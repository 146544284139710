import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  featuredInWeektotal: localStorage.getItem("featuredInWeektotal")
    ? JSON.parse(localStorage.getItem("featuredInWeektotal"))
    : [],
  mobileimage: "",
  desktopimage: "",
  posterimage: "",
  mobileimageLoading: true,
  desktopimageLoading: true,
  posterimageLoading: true,
  isLoading: true,
  isfeaturedInWeekLoading: true,
  delfeaturedInWeekLoading: true,
};


export const getFeaturedInWeek = createAsyncThunk(
    "FeaturedInWeek/getFeaturedInWeek",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/featuredinweek/all`;
        const resp = await axios(url);
        return resp.data.featuredInWeek;
      } catch (error) {
        return thunkAPI.rejectWithValue("404 Not Found");
      }
    }
  );
  
  export const FeaturedInWeekPost = createAsyncThunk(
    "FeaturedInWeek/FeaturedInWeekPost",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/featuredinweek/new`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("featuredinweek Not create");
      }
    }
  );
  
  export const DesktopImages = createAsyncThunk(
    "Desktop/DesktopImages",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/featuredinweek/deskimages`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("DesktopImages Not create");
      }
    }
  );
  
  export const MobileImages = createAsyncThunk(
    "Mobile/MobileImages",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/featuredinweek/mobileimages`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("MobileImages Not create");
      }
    }
  );
  
  export const MobilePosterImages = createAsyncThunk(
    "MobilePoster/MobilePosterImages",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/featuredinweek/posterimages`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("MobilePosterImages Not create");
      }
    }
  );
  
  export const FeaturedInWeekDelete = createAsyncThunk(
    "FeaturedInWeek/FeaturedInWeekDelete",
    async (id, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/featuredinweek/${id}`;
        const resp = await axios.delete(url, id, config);
        const myreturn = {
          success: resp.data.success,
          id: id,
        };
        return myreturn;
      } catch (error) {
        return thunkAPI.rejectWithValue("infocustoday Not create");
      }
    }
  );

  const FeaturedInWeekSlice = createSlice({
    name: "FeaturedInWeek",
    initialState,
    reducers: {},
    extraReducers: {
      [getFeaturedInWeek.pending]: (state) => {
        state.isLoading = true;
      },
      [getFeaturedInWeek.fulfilled]: (state, action) => {
        state.featuredInWeektotal = action.payload;
  
        state.isLoading = false;
        state.isfeaturedInWeekLoading = false;
        localStorage.setItem(
          "featuredInWeektotal",
          JSON.stringify(state.featuredInWeektotal)
        );
      },
      [getFeaturedInWeek.rejected]: (state) => {
        state.isLoading = true;
      },
  
      [FeaturedInWeekPost.pending]: (state) => {
        state.isfeaturedInWeekLoading = true;
      },
  
      [FeaturedInWeekPost.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.featuredInWeektotal = [
            ...state.featuredInWeektotal,
            action.payload.featuredInWeek,
          ];
          localStorage.setItem(
            "featuredInWeektotal",
            JSON.stringify(state.featuredInWeektotal)
          );
        }
        state.isfeaturedInWeekLoading = false;
      },
  
      [FeaturedInWeekPost.rejected]: (state) => {
        state.isfeaturedInWeekLoading = true;
      },
  
      [DesktopImages.pending]: (state) => {
        state.desktopimageLoading = true;
      },
      [DesktopImages.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.desktopimage = action.payload.desktopImages;
        }
        state.desktopimageLoading = false;
      },
      [DesktopImages.rejected]: (state) => {
        state.desktopimageLoading = true;
      },
  
      [MobileImages.pending]: (state) => {
        state.mobileimageLoading = true;
      },
      [MobileImages.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.mobileimage = action.payload.mobileImages;
        }
        state.mobileimageLoading = false;
      },
      [MobileImages.rejected]: (state) => {
        state.mobileimageLoading = true;
      },
  
      [MobilePosterImages.pending]: (state) => {
        state.posterimageLoading = true;
      },
      [MobilePosterImages.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.posterimage = action.payload.posterImages;
        }
        state.posterimageLoading = false;
      },
      [MobilePosterImages.rejected]: (state) => {
        state.posterimageLoading = true;
      },
  
      [FeaturedInWeekDelete.pending]: (state) => {
        state.delfeaturedInWeekLoading = true;
      },
  
      [FeaturedInWeekDelete.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.featuredInWeektotal = state.featuredInWeektotal.filter(
            (slider) => slider._id !== action.payload.id
          );
          localStorage.setItem(
            "featuredInWeektotal",
            JSON.stringify(state.featuredInWeektotal)
          );
        }
        state.delfeaturedInWeekLoading = false;
      },
  
      [FeaturedInWeekDelete.rejected]: (state) => {
        state.delfeaturedInWeekLoading = true;
      },
    },
  });
  
  export const {} = FeaturedInWeekSlice.actions;
  export default FeaturedInWeekSlice.reducer;
