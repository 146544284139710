import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import BeatLoader from "react-spinners/BeatLoader";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { CSVLink } from "react-csv";
import { Modal, ModalHeader } from "reactstrap";
import { setsubscriptionmodelStatus } from "../../redux/subscription/subscriptionSlice";

const TodayGeneratedOrders = () => {
  const { modelStatus } = useSelector((store) => store.subscriptions);
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [filterOrder, setFilterOrder] = useState([]);
  const [orders, setOrders] = useState([]);
  const [temploading, setTempLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectOrder, setSelectOrder] = useState("");
  const [model, setModel] = useState(false);
  const [cancelmodel, setCancelModel] = useState(false);
  const [disable, setDisable] = useState(false);

  const [loadData, setLoadData] = useState([]);
  const [limitOrderLoading, setlimitOrderLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/subscriptionorder/today-generated-orders`;
      const fetchOrder = await axios.get(url);
      if (fetchOrder.data.success === true) {
        setTempLoading(false);
        const orderTotal = fetchOrder.data.subscriptionorders;
        setOrders(orderTotal);
      }
    };
    fetchData();
  }, [temploading]);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       const url = `${Baseurl}/api/v1/order/Orderdelivered`;
  //       const fetchOrder = await axios.get(url);
  //       if (fetchOrder.data.success === true) {
  //         const orderTotal = fetchOrder.data.orders;
  //         setOrders(orderTotal.reverse());
  //       }
  //     };
  //     fetchData();
  //   }, []);

  useEffect(() => {
    let formdata = {};
    let packsize = [];
    let exportData = [];

    for (let index = 0; index < filterOrder.length; index++) {
      let singleorder = filterOrder[index];
      let element = filterOrder[index].OrderProducts;
      element = element.map(
        (product) =>
          (product = {
            ...product,
            orderId: singleorder._id,
            number: singleorder.Mobile,
            name: singleorder.ClientName,
            create: moment(singleorder.createdAt).format("YYYY-MM-DD"),
          })
      );
      packsize = [...packsize, ...element];
      packsize = [...packsize];
    }
    for (let index = 0; index < packsize.length; index++) {
      let element = packsize[index];

      formdata = {
        OrderId: element.orderId.slice(18),
        ProductName: element.ProductName,
        PackSize: element.PackSize,
        Brand: element.Brand,
        Price: element.Price,
        Mrp: element.Mrp,
        TotalPrice: element.TotalPrice,
        TotalMrp: element.TotalMrp,
        CatName: element.CatName,
        Number: element.number,
        Name: element.name,
        OrderDate: element.create,
      };
      exportData = [...exportData, formdata];
    }
    setLoadData(exportData);
  }, [filterOrder]);

  useEffect(() => {
    let filtOrder = orders;
    if (search !== "") {
      filtOrder = filtOrder.filter((order) => {
        return order._id.toLowerCase().slice(18).match(search.toLowerCase());
      });
    }

    if (fromDate !== "" && toDate !== "") {
      filtOrder = filtOrder.filter(
        (order) =>
          moment(order.createdAt).format("YYYY-MM-DD") >= fromDate &&
          moment(order.createdAt).format("YYYY-MM-DD") <= toDate
      );
    }

    setFilterOrder(filtOrder);
  }, [search, orders, fromDate, toDate]);

  const nextProcesssClick = (e, order) => {
    dispatch(setsubscriptionmodelStatus(true));
    setSelectOrder(order);
  };
  const cancelClick = (e, order) => {
    setCancelModel(true);
    setSelectOrder(order);
  };
  const deleteClick = (e) => {
  };
  const updateClick = (e) => {
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Order ID",
      width: 100,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto" style={{ textTransform: "uppercase" }}>
            <Link to={`/admin/subscription-order-info/${params.row._id}`}>
              {params.row._id.slice(18)}
            </Link>
          </div>
        );
      },
    },
    {
      field: "WhatsApp",
      width: 150,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            className="d-flex m-auto flex-column"
            onClick={() =>
              window.open(
                `https://api.whatsapp.com/send/?phone=%2B91${params.row.Mobile}&text&type=phone_number&app_absent=0`
              )
            }
            target="_blank"
          >
            <h6 style={{ color: "#007bff", cursor: "pointer" }}>WhatsApp</h6>
          </div>
        );
      },
    },
    {
      field: "Client Id",
      width: 100,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto" style={{ textTransform: "uppercase" }}>
            {params.row.ClientId.slice(18)}
          </div>
        );
      },
    },

    {
      field: "ClientName",
      headerName: "Client Name",
      width: 150,
      headerAlign: "center",
      align: "center",
    },

    // {
    //   field: "Order DateTime",
    //   width: 150,
    //   headerAlign: "center",
    //   renderCell: (params) => {
    //     return (
    //       <div className="d-flex m-auto flex-column">
    //         <h6>{moment(params.row.createdAt).format("MMM/DD/YYYY")}</h6>
    //         <h6>{moment(params.row.createdAt).format("h:mm:ss a")}</h6>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "Address",
      headerName: "Address",
      width: 200,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "TotalAmount",
      headerName: "Amount",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ACTION",
      width: 150,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto flex-column my-2 ">
            <Button
              className="mb-2"
              variant="contained"
              onClick={(e) => cancelClick(e, params.row)}
              color="error"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => nextProcesssClick(e, params.row)}
              color="primary"
            >
              Packed
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div className="card-footer d-flex justify-content-between">
              <h5>SUBDCRIPTION ORDERS</h5>
              <CSVLink
                data={loadData}
                filename={"kw-delivered-data.csv"}
                onClick={() => {}}
              >
                <button className="btn btn-success">export data</button>
              </CSVLink>
            </div>
            <div className="card m-4 d-flex">
              <Row className="g-2  m-2 ">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">Search</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Search by orderId"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ textTransform: "uppercase" }}
                  />
                </Col>
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">
                    From Date (Lower Date)
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </Col>
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">
                    To Date (Higher Date)
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </Col>
              </Row>
            </div>

            {temploading ? (
              <>
                <div className="d-flex justify-content-center loadingMain">
                  <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                    Order is being fetched
                  </h4>
                  <BeatLoader
                    color={"#36d7b7"}
                    loading={temploading}
                    size={10}
                    className="mt-2 mx-2"
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  style={{ height: 650, width: "100%" }}
                  className="d-flex m-auto text-align-center"
                >
                  <DataGrid
                    rows={filterOrder}
                    columns={columns}
                    getRowId={(row) => row._id}
                    rowHeight={100}
                    getRowHeight={() => "auto"}
                    pageSize={20}
                    rowsPerPageOptions={[5]}
                    sx={{
                      ".MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                      },
                    }}
                  />
                </div>
                <Modal
                  size="md"
                  isOpen={modelStatus}
                  toggle={() => dispatch(setsubscriptionmodelStatus(true))}
                >
                  <ModalHeader>
                    <div className=" ">
                      <div className="col-12 ">
                        <div className="col-12">
                          <h3>
                            Do You Want{" "}
                            <span style={{ color: "red" }}>
                              Order Dispatched
                            </span>{" "}
                            this orderId:
                            {selectOrder !== "" ? (
                              <>
                                <span
                                  style={{
                                    color: "#dc3545",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {selectOrder._id.slice(18)}
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </h3>
                          <div className="col-12 mt-3 ">
                            <div className="col-6 d-flex justify-content-between">
                              <Button
                                variant="contained"
                                color="error"
                                className="mx-1 px-5"
                                disabled={disable}
                                onClick={() =>
                                  dispatch(setsubscriptionmodelStatus(false))
                                }
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                className="mx-1 px-5"
                                disabled={disable}
                                onClick={(e) => updateClick(e)}
                              >
                                Process
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ModalHeader>
                </Modal>
                <Modal
                  size="md"
                  isOpen={cancelmodel}
                  toggle={() => setCancelModel(!cancelmodel)}
                >
                  <ModalHeader>
                    <div className=" ">
                      <div className="col-12 ">
                        <div className="col-12">
                          <h3>
                            Do You Want to{" "}
                            <span style={{ color: "red" }}>Cancel</span> this
                            orderId:
                            {selectOrder !== "" ? (
                              <>
                                <span
                                  style={{
                                    color: "#dc3545",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {selectOrder._id.slice(18)}
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </h3>
                          <div className="col-12 mt-3 ">
                            <div className="col-6 d-flex justify-content-between">
                              <Button
                                variant="contained"
                                color="error"
                                className="mx-1 px-5"
                                onClick={() => setCancelModel(false)}
                              >
                                Back
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                className="mx-1 px-5"
                                onClick={(e) => deleteClick(e)}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ModalHeader>
                </Modal>
              </>
            )}
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default TodayGeneratedOrders;
