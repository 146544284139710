import React, { Fragment, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  employeesDelete,
  employeesPost,
} from "../../../redux/emplyees/employeesSlice";
import { Link } from "react-router-dom";

const AddEmplyee = () => {
  const { employeestotal } = useSelector((store) => store.Employees);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [addressproof, setAddressproof] = useState("");
  const [password, setPassword] = useState("");
  const [delivery, setDelivery] = useState(Boolean(0));
  const [storemanager, setStoremanager] = useState(Boolean(0));
  const [errormassage, setErrormassage] = useState("");

  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setErrormassage("");
    setPhone(numbers);
  };


  const dispatch = useDispatch();
  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      Dname: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
      Daddress: address,
      Dmobile: phone,
      Daddressproof: addressproof,
      Dpassword: password,
      DeliveryBoy: delivery,
      StoreManager: storemanager,
    };
    const data = await dispatch(employeesPost(formData));

    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("Employee Added");
        setName("");
        setAddress("");
        setPhone("");
        setAddressproof("");
        setDelivery("");
        setStoremanager("");
      } else {
        alert(data.message);
        console.log(data.error);
      }
    }
  };

  const deleteClick = async (e, id) => {
    dispatch(employeesDelete(id));
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      width: 60,
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Dname",
      headerName: "D NAME",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Daddress",
      headerName: "D ADDRESS",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Dmobile",
      headerName: "D MOBILE",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Daddressproof",
      headerName: "D ADDRESS PROOF",
      width: 280,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Dpassword",
      headerName: "D PASSWORD",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "DeliveryBoy",
      headerName: "DELIVERY",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "StoreManager",
      headerName: "STORE MANAGER",
      width: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ACTION",
      width: 120,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
            <Link to={`/admin/updateemployee/${params.row._id}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 120,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              onClick={(e) => deleteClick(e, cellValues.row._id)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Employee</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Phone Number"
                        value={phone}
                        minLength={10}
                        maxLength={10}
                        pattern="[6789][0-9]{9}"
                        onChange={(e) => handleChange(e)}
                      />
                      <p style={{ color: "tomato" }}>{errormassage}</p>
                    </Col>
                    <Col md>
                      <Form.Label>Address Proof</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Pancard/Adharcard/Driving Licence "
                        value={addressproof}
                        onChange={(e) => setAddressproof(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Col>
                    <Col className="d-flex ">
                      <div className="me-4 mt-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="delivery"
                          checked={delivery === true}
                          onChange={(e) => {
                            setDelivery(e.target.checked);
                          }}
                        />
                        <Form.Label>Delivery</Form.Label>
                      </div>
                    </Col>
                    <Col className="d-flex ">
                      <div className="me-4 mt-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="storemanager"
                          checked={storemanager === true}
                          onChange={(e) => {
                            setStoremanager(e.target.checked);
                          }}
                        />
                        <Form.Label>Store Manager</Form.Label>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Add Employee
                  </button>
                </div>
              </div>
            </form>

            <div className="card me-2 ms-2">
              <div className="card-footer">
                <h5>List</h5>
              </div>
              <div className="card-body">
                <div
                  style={{ height: 650, width: "100%" }}
                  className="d-flex m-auto text-align-center"
                >
                  <DataGrid
                    rows={employeestotal}
                    columns={columns}
                    getRowId={(row) => row._id}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    // checkboxSelection
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddEmplyee;
