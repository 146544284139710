import React, { Fragment } from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {
  postcategorytag,
  catTagMobileImages,
  catTagDeskImages,
} from "../../../../redux/tags/TagSlice";
import { useDispatch, useSelector } from "react-redux";

const AddCategoryTag = () => {
  const {
    catmobileimage,
    catdesktopimage,
    catmobileimageLoading,
    catdesktopimageLoading,
  } = useSelector((store) => store.Tags);
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [name, setName] = useState("");
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");

    const selectSuperCategory = (evt) => {
      const { value } = evt.target;
      const index = evt.nativeEvent.target.selectedIndex;
      const text = evt.nativeEvent.target[index].text;
      setSupercategoryid(value);
      setSupercategory(text);
    };
    const selectCategory = (evt) => {
      const { value } = evt.target;
      const index = evt.nativeEvent.target.selectedIndex;
      const text = evt.nativeEvent.target[index].text;
      setCategoryid(value);
      setCategory(text);
  };
  const dispatch = useDispatch();
  
    const desktopChange = (e) => {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            dispatch(catTagDeskImages({ desktopImage: reader.result }));
          }
        };
        reader.readAsDataURL(file);
      });
    };
    const mobileChange = (e) => {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            dispatch(catTagMobileImages({ mobileImage: reader.result }));
          }
        };
        reader.readAsDataURL(file);
      });
    };

  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: name,
      superCategoryId: supercategoryid,
      superCategory: supercategory,
      Category: category,
      CatId: categoryid,
      DeskImg: catdesktopimage,
      MblImg: catmobileimage,
    };
    console.log(formData);
    const data = await dispatch(postcategorytag(formData));
  };
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Category Tag</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSuperCategory(e);
                        }}
                        value={superCatTotal._id}
                        name={superCatTotal.name}
                      >
                        <option value="0">{"Select Super Category"}</option>
                        {superCatTotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={categorytotal._id}
                        name={categorytotal.name}
                      >
                        <option value="0">{"Select  Category"}</option>
                        {categorytotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control
                        type="file"
                        required
                        onChange={desktopChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 1500px * 470px
                      </p>
                      <div>
                        {catdesktopimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={catdesktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Mobile Image</Form.Label>
                      <Form.Control
                        type="file"
                        required
                        onChange={mobileChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 500px * 226px
                      </p>
                      <div>
                        {catmobileimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={catmobileimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Add Category Tag
                  </button>
                </div>
                {/* <div className="card-footer ">
                  {checkurl !== "" &&
                  categoryid !== "" &&
                  !mobileimageLoading &&
                  !desktopimageLoading ? (
                    <button className="btn btn-primary" type="submit">
                      Add Slider
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Slider
                    </button>
                  )}
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddCategoryTag;
