import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import { useEffect } from "react";
import { vegHomePageUpdate } from "../../redux/vegpage/VegPageSlice";

const VegPage = () => {
  const dispatch = useDispatch();

  const { vegHomePage } = useSelector((store) => store.VegPage);

  const offerComponentstatusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        homepageId: data._id,
        offerComponent: value,
      };
      const updateCat = await dispatch(vegHomePageUpdate(formData));
    }
  };
  const couponsComponentstatusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        homepageId: data._id,
        CouponsComponent: value,
      };
      const updateCat = await dispatch(vegHomePageUpdate(formData));
    }
  };
  const hotDealsComponentstatusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        homepageId: data._id,
        HotDealsComponent: value,
      };
      const updateCat = await dispatch(vegHomePageUpdate(formData));
    }
  };
  const mustHaveItemsComponentstatusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        homepageId: data._id,
        MustHaveItemsComponent: value,
      };
      const updateCat = await dispatch(vegHomePageUpdate(formData));
    }
  };
  const categoryPageComponentstatusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        homepageId: data._id,
        CategoryPageComponent: value,
      };
      const updateCat = await dispatch(vegHomePageUpdate(formData));
    }
  };
  const limitedPeriodComponentstatusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        homepageId: data._id,
        LimitedPeriodComponent: value,
      };
      const updateCat = await dispatch(vegHomePageUpdate(formData));
    }
  };
  const freeDeliveryComponentstatusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        homepageId: data._id,
        FreeDeliveryComponent: value,
      };
      const updateCat = await dispatch(vegHomePageUpdate(formData));
    }
  };
  const todayFeaturedSliderComponentstatusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        homepageId: data._id,
        TodayFeaturedSliderComponent: value,
      };
      const updateCat = await dispatch(vegHomePageUpdate(formData));
    }
  };
  const cantMissComponentstatusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        homepageId: data._id,
        CantMissComponent: value,
      };
      const updateCat = await dispatch(vegHomePageUpdate(formData));
    }
  };
  const freshVegetablesComponentstatusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        homepageId: data._id,
        FreshVegetablesComponent: value,
      };
      const updateCat = await dispatch(vegHomePageUpdate(formData));
    }
  };
  const freshFruitComponentstatusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        homepageId: data._id,
        FreshFruitComponent: value,
      };
      const updateCat = await dispatch(vegHomePageUpdate(formData));
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "offerComponent",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.offerComponent}
                  checked={params.row.offerComponent}
                  onChange={(e) => offerComponentstatusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "CouponsComponent",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.CouponsComponent}
                  checked={params.row.CouponsComponent}
                  onChange={(e) => couponsComponentstatusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "HotDealsComponent",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.HotDealsComponent}
                  checked={params.row.HotDealsComponent}
                  onChange={(e) => hotDealsComponentstatusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "MustHaveItemsComponent",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.MustHaveItemsComponent}
                  checked={params.row.MustHaveItemsComponent}
                  onChange={(e) => mustHaveItemsComponentstatusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "CategoryPageComponent",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.CategoryPageComponent}
                  checked={params.row.CategoryPageComponent}
                  onChange={(e) => categoryPageComponentstatusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "LimitedPeriodComponent",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.LimitedPeriodComponent}
                  checked={params.row.LimitedPeriodComponent}
                  onChange={(e) => limitedPeriodComponentstatusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "FreeDeliveryComponent",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.FreeDeliveryComponent}
                  checked={params.row.FreeDeliveryComponent}
                  onChange={(e) => freeDeliveryComponentstatusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "TodayFeaturedSliderComponent",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.TodayFeaturedSliderComponent}
                  checked={params.row.TodayFeaturedSliderComponent}
                  onChange={(e) => todayFeaturedSliderComponentstatusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "CantMissComponent",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.CantMissComponent}
                  checked={params.row.CantMissComponent}
                  onChange={(e) => cantMissComponentstatusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "FreshVegetablesComponent",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.FreshVegetablesComponent}
                  checked={params.row.FreshVegetablesComponent}
                  onChange={(e) => freshVegetablesComponentstatusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "FreshFruitComponent",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.FreshFruitComponent}
                  checked={params.row.FreshFruitComponent}
                  onChange={(e) => freshFruitComponentstatusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div className="card-body"></div>
            <div
              style={{ height: 650, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={vegHomePage}
                columns={columns}
                getRowId={(row) => row._id}
                pageSize={10}
                rowsPerPageOptions={[10]}
                // checkboxSelection
              />
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default VegPage;
