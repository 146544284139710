

import React, { Fragment } from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import {
  postbannertag,
  bannerTagMobileImages,
  bannerTagDeskImages,
} from "../../../../redux/tags/TagSlice";

const AddBannerTag = () => {
    const {

        banmobileimage,
  bandesktopimage,
  banmobileimageLoading,
  bandesktopimageLoading,
    } = useSelector((store) => store.Tags);
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [name, setName] = useState("");

    const dispatch = useDispatch();

    const desktopChange = (e) => {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            dispatch(bannerTagDeskImages({ desktopImage: reader.result }));
          }
        };
        reader.readAsDataURL(file);
      });
    };
    const mobileChange = (e) => {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            dispatch(bannerTagMobileImages({ mobileImage: reader.result }));
          }
        };
        reader.readAsDataURL(file);
      });
    };

  const handlesubmit = async (e) => {
        e.preventDefault();
    const formData = {
      name: name,
        DeskImg:bandesktopimage,
        MblImg:banmobileimage,
    };
    console.log(formData);
     const data = await dispatch(postbannertag(formData));
  };
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Banner Tag</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 w-50">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control
                        type="file"
                        required
                        onChange={desktopChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 1500px * 470px
                      </p>
                      <div>
                        {bandesktopimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={bandesktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Mobile Image</Form.Label>
                      <Form.Control
                        type="file"
                        required
                        onChange={mobileChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 500px * 226px
                      </p>
                      <div>
                        {banmobileimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={banmobileimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Add Banner Tag
                  </button>
                </div>
                {/* <div className="card-footer ">
                  {checkurl !== "" &&
                  categoryid !== "" &&
                  !mobileimageLoading &&
                  !desktopimageLoading ? (
                    <button className="btn btn-primary" type="submit">
                      Add Slider
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Slider
                    </button>
                  )}
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddBannerTag;
