import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { updateOrder } from "../../redux/order/orderSlice";

const ChangeOrderStatus = () => {
  const { ordertotal } = useSelector((store) => store.orders);
  const dispatch = useDispatch();

  const [paymentmode, setPaymentMode] = useState("");
  const [paymentstatus, setPaymentstatus] = useState("");
  const [status, setStatus] = useState("");
  const [total, setTotal] = useState("");
  const [nettotal, setNettotal] = useState("");
  const [orderId, setorderId] = useState("");
  const [order, setorder] = useState("");

  const getOrderId = (e) => {
    const value = e.target.value;
    setorder(value);
    const findOrderId = ordertotal.find((order) => {
      return order._id.toLowerCase().slice(18).match(value.toLowerCase());
    });
    setorderId(findOrderId._id);
  };

  const selectpaymentMode = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setPaymentMode(text);
  };

  const selectPaymentStatus = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setPaymentstatus(text);
  };
  const selectorderStatus = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setStatus(value);
  };

  const paymentMode = [
    { name: "Others", id: 1 },
    { name: "Cash on Delivery", id: 2 },
    { name: "COD", id: 3 },
    { name: "PayuMoney", id: 4 },
    { name: "Paytm", id: 5 },
    { name: "PayUmoney", id: 6 },
    { name: "Payu money", id: 7 },
    { name: "RazorPay", id: 8 },
    { name: "Razorpay link", id: 9 },
    { name: "Payment link", id: 10 },
    { name: "Wallet", id: 11 },
    { name: "paid", id: 12 },
    { name: "Paid", id: 13 },
    { name: "Google pay", id: 14 },
    { name: "google pay", id: 15 },
    { name: "googlepay", id: 16 },
    { name: "GooglePay", id: 17 },
    { name: "Googlepay", id: 18 },

    { name: "PayNow", id: 19 },
    { name: "PhonePe", id: 20 },
    { name: "Phonepe", id: 21 },
    { name: "phonepe", id: 22 },
    { name: "Other Wallet", id: 23 },
  ];

  const PaymentStatus = [
    { name: "Unpaid", id: 1 },
    { name: "Failed", id: 7 },
    { name: "Success", id: 2 },
    { name: "Pending", id: 3 },
    { name: "success", id: 4 },
    { name: "pending", id: 5 },
    { name: "Partial Payment", id: 6 },
  ];
  const orderStatus = [
    { name: "Order Recieved", id: 1, displayNmae: "Booked Order" },
    { name: "Order Processing", id: 6, displayNmae: "Processing" },
    { name: "Out for Delivery", id: 2, displayNmae: "Deliver" },
    { name: "Order Dispatched", id: 3, displayNmae: "Dispatch" },
    { name: "Order Delivered", id: 4, displayNmae: "Delivered" },
    { name: "Order Cancelled", id: 5, displayNmae: "Cancel" },
  ];

  const handlesubmit = async (e) => {
    e.preventDefault();

    if (
      orderId !== "" &&
      paymentmode !== "" &&
      paymentstatus !== "" &&
      total !== "" &&
      nettotal !== ""
    ) {
      const formData = {
        PaymentMode: paymentmode,
        PaymentStatus: paymentstatus,
        TotalAmount: total,
        GrandTotal: nettotal,
        _id: orderId,
      };
      const data = await dispatch(updateOrder(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          setPaymentMode("");
          setPaymentstatus("");
          setNettotal("");
          setorderId("");
          setorder("");
        } else {
        }
      }
    } else {
      alert("please enter correct values");
    }
  };
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Order Status Update</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Order Id
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Order Id"
                        value={order}
                        required
                        onChange={(e) => getOrderId(e)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Staus</Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectorderStatus(e);
                        }}
                        value={orderStatus.name}
                        name={orderStatus.name}
                      >
                        <option value="0">{"Select  Staus"}</option>
                        {orderStatus.map((data, index) => (
                          <option
                            key={index}
                            value={data.name}
                            name={data.name}
                            required
                          >
                            {data.displayNmae}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Payment Mode
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectpaymentMode(e);
                        }}
                        value={paymentMode.id}
                        name={paymentMode.name}
                      >
                        <option value="0">{"Select  Payment Mode"}</option>
                        {paymentMode.map((data, index) => (
                          <option
                            key={index}
                            value={data.id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Payment Status
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectPaymentStatus(e);
                        }}
                        value={PaymentStatus.id}
                        name={PaymentStatus.name}
                      >
                        <option value="0">{"Select Payment Status"}</option>
                        {PaymentStatus.map((data, index) => (
                          <option
                            key={index}
                            value={data.id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Total</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Total"
                        value={total}
                        required
                        onChange={(e) => setTotal(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Net Payable
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Net Payable"
                        value={nettotal}
                        required
                        onChange={(e) => setNettotal(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="card-footer text-center">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ChangeOrderStatus;
