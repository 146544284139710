import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import {
  validateSubscriptionSlugUrl,
  subscriptionImages,
  subscriptionPost,
} from "../../../redux/subscription/subscriptionSlice";
import { MdOutlineRemove } from "react-icons/md";

const AddSubscription = () => {
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const { brandtotal } = useSelector((store) => store.brand);
  const {
    mobileimage,
    desktopimage,
    issubscriptionmobileimageLoading,
    issubscriptiondeskimageLoading,
  } = useSelector((store) => store.subscriptions);

  const [name, setName] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [subcategory, setSubCategory] = useState("");
  const [catfilter, setcatfilter] = useState([]);
  const [subcatfilter, setSubSubcatfilter] = useState([]);
  const [subcategoryid, setSubCategoryid] = useState("");
  const [brand, setBrand] = useState("");
  const [about, setAbout] = useState("");
  const [ingradient, setIngradient] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [productinfo, setProductinfo] = useState("");
  const [type, setType] = useState("");
  const [vipPrice, setvipPrice] = useState("");
  const [gstcost, setGstcost] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [CostPrc, setCostPrc] = useState("");
  const [SellingPrice, setSellingPrice] = useState("");
  const [GstSellPrc, setGstSellPrc] = useState("");
  const [Mrp, setMrp] = useState("");

  const dispatch = useDispatch();

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setErrorcolor("green");
    setCheckSlugUrl("");
    setError("Subscription available");
    const subscSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(subscSlug);

    if (subscSlug !== "") {
      let responce = await dispatch(validateSubscriptionSlugUrl(subscSlug));
      if (responce.payload.success) {
        setError("Subscription already exist");
        setErrorcolor("red");
      } else if (subscSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (subscSlug.length >= 3) {
        setErrorcolor("green");
        setError("Subscription available");
        setCheckSlugUrl(subscSlug);
      }
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    if (checkslugUrl !== "") {
      const discount = Math.round(((Mrp - SellingPrice) * 100) / Mrp);
      const vipdiscount = Math.round(((Mrp - vipPrice) * 100) / Mrp);
      const formData = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        ItemName: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        slugUrl: checkslugUrl,
        Category: category,
        CatId: categoryid,
        SubCat: "abcd",
        SubCatId: "658c09fe4801f5ae5320886b",
        Brand: brand,

        About: about,
        ProductInfo: productinfo,
        Ingredient: ingradient,

        KeyWords: metakeyword,
        Description: metadesc,
        Title: metatitle,

        Type: type,

        Pcb1: 1,
        Pcb2: 2,
        Pcb3: 3,
        ImgUrlMbl: mobileimage,
        ImgUrlDesk: desktopimage,
        CostPrc: CostPrc,
        GstCost: gstcost,
        SellingPrice: SellingPrice,
        GstSellPrc: GstSellPrc,
        Mrp: Mrp,
        VipSellingPrice: vipPrice,
        Discount: discount,
        VipDiscount: vipdiscount,
      };
      const data = await dispatch(subscriptionPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Subscription Added");
          setName("");
          setCategory(null);
          setCategoryid(null);
          setBrand(null);
          setSlugUrl("");
          setAbout("");
          setType("");
          setProductinfo("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setCostPrc("");
          setSellingPrice("");
          setGstSellPrc("");
          setMrp("");
          setGstcost("");
          setSellingPrice("");
        } else {
          alert(data.message);
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const catbySupcat = categorytotal.filter(
      (cat) => cat.superCategoryId === "63d8daa4d405398b770b50da"
    );
    console.log(catbySupcat, "catbySupcat");
    setcatfilter(catbySupcat);
  }, [categorytotal]);

  useEffect(() => {
    const subcatbycat = subcategorytotal.filter(
      (cat) => cat.categoryId === categoryid
    );
    setSubSubcatfilter(subcatbycat);
  }, [subcategorytotal, categoryid]);

  const selectBrand = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    // setSubCategoryid(value);
    setBrand(text);
  };

  const SubscriptionImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(subscriptionImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Subscription</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>

                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={catfilter._id}
                        name={catfilter.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {catfilter.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    {/* <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub Category
                      </Form.Label>

                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={subcatfilter._id}
                        name={subcatfilter.name}
                      >
                        <option value="0">{"Select Sub Category"}</option>
                        {subcatfilter.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col> */}
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Brand</Form.Label>

                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectBrand(e);
                        }}
                        value={brandtotal.name}
                        name={brandtotal.name}
                      >
                        <option value="0">{"Select Brand"}</option>
                        {brandtotal.map((data) => (
                          <option
                            key={data.name}
                            value={data.name}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label>Name</Form.Label>

                      <Form.Control
                        type="text"
                        placeholder=" Name"
                        required
                        value={name}
                        onChange={(e) => verifyslugurl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label>About</Form.Label>

                      <Form.Control
                        type="text"
                        placeholder=" About"
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Ingredient</Form.Label>

                      <Form.Control
                        type="text"
                        placeholder=" Ingredient"
                        value={ingradient}
                        onChange={(e) => setIngradient(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Meta Descriptrion </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Title </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Keyword </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                  </Row>
                  {/* <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Product Info</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=" Product Info"
                        value={productinfo}
                        onChange={(e) => setProductinfo(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=" Type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      />
                    </Col>
                  </Row> */}

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={SubscriptionImageChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 450px * 450px
                      </p>
                      <div>
                        {issubscriptiondeskimageLoading ? (
                          <div></div>
                        ) : (
                          <img src={desktopimage} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Product Info</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=" Product Info"
                        value={productinfo}
                        onChange={(e) => setProductinfo(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>GST Cost</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=" Type"
                        value={gstcost}
                        onChange={(e) => setGstcost(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Cost Prcice
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={CostPrc}
                        required
                        onChange={(e) =>
                          setCostPrc(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sell Price
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={SellingPrice}
                        required
                        onChange={(e) =>
                          setSellingPrice(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        VIP Sell Price
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={vipPrice}
                        required
                        onChange={(e) =>
                          setvipPrice(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        GST Sell Price
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={GstSellPrc}
                        required
                        onChange={(e) =>
                          setGstSellPrc(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">MRP</Form.Label>
                      <Form.Control
                        type="text"
                        value={Mrp}
                        required
                        onChange={(e) =>
                          setMrp(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </Col>
                  </Row>
                </div>
                {/* <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Add Subscription
                  </button>
                </div> */}
                <div className="card-footer ">
                  {checkslugUrl !== "" &&
                  name !== "" &&
                  categoryid !== "" &&
                  !issubscriptiondeskimageLoading ? (
                    <button className="btn btn-primary" type="submit">
                      Add Subscription
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Subscription
                    </button>
                  )}
                </div>
              </div>
            </form>
            {/* <div className="card-footer "></div> */}
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddSubscription;
