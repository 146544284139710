import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import {
  clientPost,
  getClientByMob,
  validatePhoneNumber,
} from "../../redux/client/clientSlice";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";
import { postOrder } from "../../redux/order/orderSlice";

const NewOrder = () => {
  const { grocerytotal, groceryLoading } = useSelector(
    (store) => store.groceries
  );
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategories);

  const dispatch = useDispatch();

  const [temploading, setTempLoading] = useState(true);
  const [productall, setProductall] = useState([]);

  const [search, setSearch] = useState("");
  const [updateBoxShow, setUpdateBoxShow] = useState(false);
  const [userRegisterBoxShow, setUserRegisterBoxShow] = useState(false);
  const [number, setNumber] = useState("");
  const [errormassage, setErrormassage] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [userAddress, setUserAddress] = useState("");

  const [products, setProducts] = useState([]);
  const [orderinfo, setOrderinfo] = useState("");
  const [cartamountDetails, setCartamountDetails] = useState("");
  const [confnewQuentity, setConfnewQuentity] = useState(1);

  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [category, setCategory] = useState("");

  const [categoryid, setCategoryid] = useState("");
  const [catbysuper, setCatbysuper] = useState([]);

  const [subcatByProducts, setSubcatByProducts] = useState([]);
  const [productName, setProductName] = useState("");
  const [productId, setProductId] = useState("");
  const [selectproduct, setSelectProduct] = useState("");
  const [selectPackSize, setSelectPacksize] = useState("");

  const [productsByPack, setProductsByPack] = useState([]);
  const [packName, setPackName] = useState("");
  const [packId, setPackId] = useState("");
  const [packIndexs, setPackIndexs] = useState("");

  const [btnLoading, setbtnLoading] = useState(false);

  const [clientName, setclientName] = useState("");
  const [email, setEmail] = useState("");
  const [errormassageEmail, setErrormassageEmail] = useState("");
  const [uniqueemail, setUniqueEmail] = useState("");

  //   console.log("packindex", packIndexs);
  const supercat = [
    { id: "63d767855658519287238b63", name: "Supermart" },
    { id: "63d8da2bd405398b770b508f", name: "Non-veg" },
    { id: "63d8da4ad405398b770b50aa", name: "Vegetables & fruits" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/grocery/all`;
      const fetchOrder = await axios.get(url);
      if (fetchOrder.data.success === true) {
        setTempLoading(false);
        const grocTotal = fetchOrder.data.grocery;
        const product = [...grocTotal].sort((a, b) =>
          a.ItemName > b.ItemName ? 1 : -1
        );
        setProductall(product);
      }
    };
    fetchData();
  }, []);

  const emailchange = (e) => {
    const value = e.target.value;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setEmail(value);
    setErrormassageEmail("");
    if (value.length > 0) {
      if (value.match(mailformat)) {
        if (value != "") {
          setUniqueEmail(value);
        }
      } else {
        setErrormassageEmail("please enter correct Email format");
      }
    } else {
      setErrormassageEmail("please enter correct Email format");
    }
  };

  useEffect(() => {
    let cartTotalAmountN = 0;
    let totalAmount = 0;
    let netPayableN = 0;
    let cartTotalMrpN = 0;
    let totalMrp = 0;
    let totalprice = 0;
    let cartTotalPriceN = 0;
    let logisticsAmountN = 40;
    const totalitem = products;

    for (let i = 0; i < totalitem.length; i++) {
      cartTotalAmountN = cartTotalAmountN + products[i].TotalAmount;
      cartTotalMrpN = cartTotalMrpN + products[i].TotalMrp * products[i].Qty;
      totalMrp = totalMrp + products[i].Mrp * products[i].Qty;
      totalprice = totalprice + products[i].Price * products[i].Qty;
      cartTotalPriceN =
        cartTotalPriceN + products[i].TotalPrice * products[i].Qty;
    }

    if (cartTotalAmountN >= 500) {
      logisticsAmountN = 0;
    } else {
      logisticsAmountN = 40;
    }
    cartTotalAmountN = cartTotalAmountN + logisticsAmountN;
    totalAmount = cartTotalAmountN + logisticsAmountN;
    netPayableN = totalAmount;

    const cartAmountsForm = {
      cartTotalAmount: cartTotalAmountN,
      netPayable: netPayableN,
      cartTotalMrp: cartTotalMrpN,
      cartTotalPrice: cartTotalPriceN,
      logisticsAmount: logisticsAmountN,
      totalAmount: totalAmount,
      totalMrp: totalMrp,
      totalprice: totalprice,
    };

    setCartamountDetails(cartAmountsForm);
  }, [products]);

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercategoryid(value);
    setSupercategory(text);
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
  }, [supercategoryid, categorytotal]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

  const selectProduct = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setProductId(value);
    setProductName(text);
  };
  const selectPacksize = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    const currindex = Number(-1) + Number(index);
    setPackId(value);
    setPackName(text);
    setPackIndexs(currindex);
  };
  useEffect(() => {
    const subcatbycat = subcategorytotal.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategorytotal]);

  useEffect(() => {
    if (subcategoryid !== "") {
      const productbysubcat = productall.filter(
        (product) => product.SubCatId === subcategoryid
      );
      setSubcatByProducts(productbysubcat);
    }
  }, [subcategoryid, productall]);

  useEffect(() => {
    if (productId !== "") {
      const productPack = productall.find(
        (product) => product._id === productId
      );
      setSelectProduct(productPack);
      setProductsByPack(productPack.PackSizes);
    }
  }, [productId, productall]);

  useEffect(() => {
    if (packId !== "") {
      const productPack = productsByPack.find((pack) => pack._id === packId);
      setSelectPacksize(productPack);
    }
  }, [packId, productsByPack]);

  const addCartQuentityClick = (e, cartProduct, cartindex) => {
    e.preventDefault();
    let product = [...products];
    if (product[cartindex].Qty < 10) {
      product[cartindex].Qty = product[cartindex].Qty + 1;

      product[cartindex].TotalAmount =
        product[cartindex].Qty * product[cartindex].Price;

      setProducts([...product]);
    }
  };
  const removeCartQuentityClick = (e, cartProduct, cartindex) => {
    e.preventDefault();
    let product = [...products];
    if (product[cartindex].Qty > 1) {
      product[cartindex].Qty = product[cartindex].Qty - 1;

      product[cartindex].TotalAmount =
        product[cartindex].Qty * product[cartindex].Price;

      setProducts([...product]);
    }
  };
  const removecartProductClick = (e, cartProduct, cartindex) => {
    e.preventDefault();
    let product = [...products];
    if (product.length > 1) {
      product = products.filter((cart) => cart._id !== cartProduct._id);
      setProducts([...product]);
    }
  };

  const addnewitem = async () => {
    if (productId !== "" && packName !== "") {
      const newProd = productall.find((product) => product._id === productId);

      let itemIndex = -1;
      itemIndex = products.findIndex(
        (item) => item.packsizeId === newProd.PackSizes[packIndexs]._id
      );

      if (itemIndex >= 0) {
        let product = [...products];
        product = product.map((order) => {
          if (order.ProductId === newProd._id) {
            order.Qty = order.Qty + 1;
            order.Status = true;
          }

          return order;
        });
        product = product;
        setProducts([...product]);
        setPackName("");
        setPackId("");
        setPackIndexs("");
        setProductId("");
      } else {
        let newOrderform = {};
        newOrderform = {
          ProductId: newProd._id,
          ProductName: newProd.ItemName,
          CatName: newProd.Category,
          CatId: newProd.CatId,
          SubCat: newProd.SubCat,
          SubCatId: newProd.SubCatId,
          Brand: newProd.Brand,
          ItemName: newProd.ItemName,
          PackSize: newProd.PackSizes[packIndexs].PackSize,
          Description: newProd.Description,
          ImgUrl: newProd.PackSizes[packIndexs].ImgUrlMbl,
          Price: newProd.PackSizes[packIndexs].SellingPrice,
          Qty: 1,
          TotalAmount: newProd.PackSizes[packIndexs].SellingPrice * 1,
          Mrp: newProd.PackSizes[packIndexs].Mrp,
          TotalPrice: newProd.PackSizes[packIndexs].SellingPrice * 1,
          TotalMrp: newProd.PackSizes[packIndexs].Mrp * 1,
          Discount:
            newProd.PackSizes[packIndexs].Mrp * 1 -
            newProd.PackSizes[packIndexs].SellingPrice * 1,
          Cashback: newProd.Cashback,
          SellingPrice: newProd.PackSizes[packIndexs].SellingPrice,
          GstSellPrc: newProd.PackSizes[packIndexs].GstSellPrc,
          CostPrc: newProd.PackSizes[packIndexs].CostPrc,
          GstCost: newProd.PackSizes[packIndexs].GstCost,
          packsizeId: newProd.PackSizes[packIndexs]._id,
          maximumQuantity: newProd.PackSizes[packIndexs].maximumQuantity,
          Status: true,
        };
        let product = [...products];
        product = [...product, newOrderform];
        setProducts([...product]);
        setPackName("");
        setPackId("");
        setPackIndexs("");
        setProductId("");
        newOrderform = {};
      }
    }
  };

  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setSearch(numbers);
    setErrormassage("");
    if (numbers.length === 10) {
      setNumber(numbers);
      setErrormassage("");
    } else {
      setErrormassage("Please enter Currect Number");
    }
  };

  const getUserDetails = async (e) => {
    e.preventDefault();
    try {
      const getClientForm = {
        Mobile: number,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/loginclient`;
      let getClient = await axios.post(url, getClientForm, config);

      try {
        if (getClient.data.success) {
          const userFind = getClient.data.client;
          const address = userFind.Addresses;
          if (address.length > 0) {
            setUserAddress(address[0].Address);
          }
          setUserDetails(userFind);
          setUpdateBoxShow(true);
          setUserRegisterBoxShow(false);
        } else {
          setUserRegisterBoxShow(true);
          setUpdateBoxShow(true);
        }
      } catch (error) {}
    } catch (error) {}

    // setUpdateBoxShow(true);
  };

  const handleRegistersubmit = async (e) => {
    e.preventDefault();
    if (clientName !== "" && uniqueemail !== "") {
      const formData = {
        Name:
          clientName.charAt(0).toUpperCase() +
          clientName.slice(1).toLowerCase(),
        Email: uniqueemail,
        Mobile: number,
      };

      const ClientDetails = await dispatch(clientPost(formData));
      if (ClientDetails.payload.success) {
        const client = ClientDetails.payload.client;
        setUserDetails(client);
        setUpdateBoxShow(true);
        setUserRegisterBoxShow(false);
      }
    }
  };
  const orderClick = async (e) => {
    e.preventDefault();

    if (userAddress !== "") {
      if (!btnLoading) {
        setbtnLoading(true);
        var date = new Date();
        date.setDate(date.getDate() + 1);
        const orderData = {
          ClientId: userDetails._id,
          ClientName: userDetails.Name,
          TotalAmount: Number(cartamountDetails.cartTotalAmount),
          Email: userDetails.Email,
          DeliveryCharge: cartamountDetails.logisticsAmount,
          wallet: 0,
          GrandTotal: Number(cartamountDetails.cartTotalAmount),
          Address: userAddress,
          AreaName: userDetails.Name,
          Mobile: userDetails.Mobile,
          PaymentStatus: "COD",
          PaymentMode: "COD",
          TxnId: "NA",
          ExpectedDelDate: date,
          ProductCount: products.length,
          Saving:
            cartamountDetails.cartTotalMrp - cartamountDetails.cartTotalPrice,
          Cashback: "0",
          CurrentStatus: "Not Paid",
          couponDetails: {
            // coupon: cartCoupon.CouponName,
            // couponCode: cartCoupon.Title,
            // couponDis: CouponDiscountAmount,
            // CouponTitle: cartCoupon.Title,
            // CouponDescription: cartCoupon.Description,
          },
          OrderProducts: products,
        };

        const neworder = await dispatch(postOrder(orderData));

        setUpdateBoxShow(false);
        setUserRegisterBoxShow(false);
        setSearch("");
      }
    } else {
      alert("please add address...");
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          {updateBoxShow ? (
            <>
              {userRegisterBoxShow === true ? (
                <>
                  <div className="employeesContainer">
                    <form onSubmit={handleRegistersubmit}>
                      <div className="card m-4">
                        <div className="card-footer d-flex justify-content-between">
                          <h5>Create New User</h5>
                        </div>
                        <div className="card-body">
                          <Row className="g-2  m-2 ">
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Client Number
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={number}
                                readOnly="readOnly"
                                // onChange={(e) => getOrderId(e)}
                              />
                            </Col>
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Client Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={clientName}
                                onChange={(e) => setclientName(e.target.value)}
                              />
                            </Col>
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Client Email
                              </Form.Label>
                              <Form.Control
                                type="email"
                                value={email}
                                onChange={(e) => emailchange(e)}
                              />
                              <p style={{ color: "red" }} className="mt-2">
                                {errormassageEmail}
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="card-footer text-center">
                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="employeesContainer">
                    <form
                    // onSubmit={handleRegistersubmit}
                    >
                      <div className="card m-4">
                        <div className="card-footer d-flex justify-content-between">
                          <h5>New Order Creation</h5>
                        </div>
                        <div className="card-body">
                          <Row className="g-2  m-2 ">
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                User Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={userDetails.Name}
                                readOnly="readOnly"
                                // onChange={(e) => getOrderId(e)}
                              />
                            </Col>
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Client Mobile
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={userDetails.Mobile}
                                readOnly="readOnly"
                                // onChange={(e) => getOrderId(e)}
                              />
                            </Col>
                          </Row>
                          <Row className="g-2  m-2 w-50">
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                User Address
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                placeholder="Your address"
                                style={{ height: "100px" }}
                                value={userAddress}
                                // readOnly="readOnly" setUserDetails
                                onChange={(e) => setUserAddress(e.target.value)}
                              />
                            </Col>
                          </Row>
                        </div>

                        {temploading ? (
                          <>
                            {" "}
                            <div className="d-flex justify-content-center loadingMain">
                              <h4
                                style={{ fontWeight: "bold", color: "#36d7b7" }}
                              >
                                Product is being fetched
                              </h4>
                              <BeatLoader
                                color={"#36d7b7"}
                                loading={temploading}
                                size={10}
                                className="mt-2 mx-2"
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="card-body">
                              <Row className="g-2  m-2">
                                <Col md>
                                  <Form.Label htmlFor="disabledTextInput">
                                    Super Category
                                  </Form.Label>
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    onChange={(e) => {
                                      selectsupCategory(e);
                                    }}
                                    value={supercat.id}
                                    name={supercat.name}
                                  >
                                    <option value="0">
                                      {"Select Super Category"}
                                    </option>
                                    {supercat.map((data) => (
                                      <option
                                        key={data.id}
                                        value={data.id}
                                        name={data.name}
                                        required
                                      >
                                        {data.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                                <Col md>
                                  <Form.Label htmlFor="disabledTextInput">
                                    Category
                                  </Form.Label>
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    onChange={(e) => {
                                      selectCategory(e);
                                    }}
                                    value={catbysuper._id}
                                    name={catbysuper.name}
                                  >
                                    <option value="0">
                                      {"Select Category"}
                                    </option>
                                    {catbysuper.map((data) => (
                                      <option
                                        key={data._id}
                                        value={data._id}
                                        name={data.name}
                                        required
                                      >
                                        {data.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                                <Col md>
                                  <Form.Label htmlFor="disabledTextInput">
                                    Sub Category
                                  </Form.Label>
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    onChange={(e) => {
                                      selectSubCategory(e);
                                    }}
                                    value={subCat._id}
                                    name={subCat.name}
                                  >
                                    <option value="0">
                                      {"Select Sub Category"}
                                    </option>
                                    {subCat.map((data) => (
                                      <option
                                        key={data._id}
                                        value={data._id}
                                        name={data.name}
                                        required
                                      >
                                        {data.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                              </Row>
                              <Row className="g-2  m-2">
                                <Col md>
                                  <Form.Label htmlFor="disabledTextInput">
                                    Items
                                  </Form.Label>
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    onChange={(e) => {
                                      selectProduct(e);
                                    }}
                                    value={subcatByProducts._id}
                                    name={subcatByProducts.ItemName}
                                  >
                                    <option value="0">{"Select Item"}</option>
                                    {subcatByProducts.map((data) => (
                                      <option
                                        key={data._id}
                                        value={data._id}
                                        name={data.ItemName}
                                        required
                                      >
                                        {data.ItemName}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                                <Col md>
                                  <Form.Label htmlFor="disabledTextInput">
                                    Packsize
                                  </Form.Label>
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    onChange={(e) => {
                                      selectPacksize(e);
                                    }}
                                    value={productsByPack._id}
                                    name={productsByPack.PackSize}
                                  >
                                    <option value="0">
                                      {"Select Packsize"}
                                    </option>
                                    {productsByPack.map((data) => (
                                      <option
                                        key={data._id}
                                        value={data._id}
                                        name={data.PackSize}
                                        required
                                      >
                                        {data.PackSize}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                              </Row>
                              <Row className="g-2 w-25  m-2">
                                <Col md>
                                  {confnewQuentity > 0 ? (
                                    <>
                                      {" "}
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(e) => addnewitem(e)}
                                        style={{
                                          background: "#556ee6",
                                          color: "#fff",
                                        }}
                                        className="mt-4"
                                      >
                                        ADD ITEMS
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        // onClick={() => addnewitem()}
                                        style={{
                                          background: "#556ee6",
                                          color: "#fff",
                                        }}
                                        className="mt-4"
                                        disabled
                                      >
                                        ADD ITEMS
                                      </Button>
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </div>
                            {products.length > 0 ? (
                              <>
                                <section
                                  className="h-100"
                                  style={{ backgroundColor: "#eee" }}
                                >
                                  <div className="container h-100 py-2">
                                    <div className="row d-flex justify-content-center align-items-center h-100">
                                      <div className="col-12 m-0 p-0 h-75">
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                          <h3 className="fw-normal mb-0 text-black ms-2">
                                            Order Cart
                                          </h3>
                                          <div></div>
                                        </div>
                                        {products &&
                                          products.map((cartPro, cartindex) => (
                                            <div
                                              className="card rounded-3 mb-1  "
                                              key={cartPro._id}
                                            >
                                              <div className="container p-4 mt-0 ">
                                                <div className="row  total-div">
                                                  <div className=" col-md-2 col-lg-3 col-xl-2 sub_child ">
                                                    <img
                                                      src={cartPro.ImgUrl}
                                                      className="img-fluid blur-up  "
                                                    />
                                                  </div>
                                                  <div className="col-md-3 col-lg-3 col-xl-3  Product-info">
                                                    <p className="lead fw-normal m-0 product-font">
                                                      <span
                                                        style={{
                                                          fontSize: "16px",
                                                        }}
                                                      >
                                                        {cartPro.ItemName}
                                                      </span>
                                                      <span
                                                        style={{
                                                          fontSize: "16px",
                                                        }}
                                                        className="mt-2"
                                                      >
                                                        Pack Size :{" "}
                                                        {cartPro.PackSize}
                                                      </span>
                                                    </p>
                                                  </div>
                                                  <div className="col-md-3 col-lg-2 col-xl-3  price-ifo">
                                                    <h5 className="price-margin">
                                                      Price : ₹ {cartPro.Price}
                                                    </h5>
                                                    <h5 className="price-margin">
                                                      MRP : ₹ {cartPro.Mrp}
                                                    </h5>
                                                    <h5
                                                      style={{
                                                        color: "#0e947a",
                                                      }}
                                                    >
                                                      Total Amount : ₹{" "}
                                                      {cartPro.TotalAmount}
                                                    </h5>
                                                  </div>
                                                  <div className="col-md-3 col-lg-3 col-xl-3   Add-to-cart">
                                                    <button
                                                      className="btn btn-link  button1"
                                                      onClick={(e) =>
                                                        removeCartQuentityClick(
                                                          e,
                                                          cartPro,
                                                          cartindex
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <i className="fas fa-minus b1" />
                                                    </button>
                                                    <input
                                                      // id="form1"
                                                      type="text"
                                                      name="quantity"
                                                      value={cartPro.Qty}
                                                      contentEditable="false"
                                                      readOnly="readOnly"
                                                      className="form-control forminputs form-control-sm"
                                                      style={{
                                                        backgroundColor:
                                                          "transparent",
                                                      }}
                                                    />
                                                    <button
                                                      className="btn btn-link  button1"
                                                      onClick={(e) =>
                                                        addCartQuentityClick(
                                                          e,
                                                          cartPro,
                                                          cartindex
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <i className="fas fa-plus b1 " />
                                                    </button>
                                                  </div>

                                                  <div
                                                    className="col-md-1 col-lg-1 col-xl-1 text-end  Remove"
                                                    onClick={(e) =>
                                                      removecartProductClick(
                                                        e,
                                                        cartPro,
                                                        cartindex
                                                      )
                                                    }
                                                  >
                                                    <a
                                                      className="text-danger "
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <i className="fas fa-trash fa-lg" />{" "}
                                                      <button
                                                        type="button"
                                                        className="mobileView text-danger"
                                                      >
                                                        Remove
                                                      </button>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}

                                        <div className="card rounded-3 mb-1 ">
                                          <div className="d-flex justify-content-end">
                                            <ul className="">
                                              <li
                                                className="mb-0"
                                                style={{
                                                  listStyle: "none",
                                                  fontSize: "18px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                Total Price :
                                              </li>
                                              <li
                                                className="mb-0"
                                                style={{
                                                  listStyle: "none",
                                                  fontSize: "18px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                Sub-Total :
                                              </li>
                                              <li
                                                className="mb-0"
                                                style={{
                                                  listStyle: "none",
                                                  fontSize: "18px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                Shipping :
                                              </li>

                                              <li
                                                className="mb-0"
                                                style={{
                                                  listStyle: "none",
                                                  fontSize: "18px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                Grand Total :
                                              </li>
                                            </ul>
                                            <ul className="mr-3">
                                              <li
                                                className="mb-0"
                                                style={{
                                                  listStyle: "none",
                                                  fontSize: "18px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                ₹ {cartamountDetails.totalMrp}
                                              </li>
                                              <li
                                                className="mb-0"
                                                style={{
                                                  listStyle: "none",
                                                  fontSize: "18px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                ₹ {cartamountDetails.totalprice}
                                              </li>
                                              <li
                                                className="mb-0"
                                                style={{
                                                  listStyle: "none",
                                                  fontSize: "18px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                ₹
                                                {
                                                  cartamountDetails.logisticsAmount
                                                }
                                              </li>
                                              <li
                                                className="mb-0"
                                                style={{
                                                  listStyle: "none",
                                                  fontSize: "18px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                ₹{" "}
                                                {
                                                  cartamountDetails.cartTotalAmount
                                                }
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div
                                          className="card "
                                          style={{ height: "80px" }}
                                        >
                                          {btnLoading === true ? (
                                            <>
                                              <div className="card-body mb-4">
                                                <button
                                                  type="button"
                                                  className="btn btn-warning btn-block btn-lg"
                                                  style={{
                                                    fontWeight: "bold",
                                                    // color: "#36d7b7",
                                                  }}
                                                >
                                                  Order is processing
                                                  <BeatLoader
                                                    // color={"#36d7b7"}
                                                    loading={btnLoading}
                                                    size={10}
                                                    className="mt-2 mx-2"
                                                  />
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              <div className="card-body mb-4">
                                                <button
                                                  type="button"
                                                  className="btn btn-warning btn-block btn-lg"
                                                  onClick={(e) => orderClick(e)}
                                                >
                                                  Proceed to Order
                                                </button>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}

                        {/* <div className="card-footer text-center">
                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="employeesContainer">
                <form onSubmit={getUserDetails}>
                  <div className="card m-4">
                    <div className="card-footer">
                      <h5>Create New Order</h5>
                    </div>
                    <div className="card-body">
                      <Row className="g-2  m-2 ">
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            User Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter User Number"
                            value={search}
                            maxLength={10}
                            minLength={10}
                            required
                            onChange={(e) => handleChange(e)}
                          />
                          <p style={{ color: "red" }}>{errormassage}</p>
                        </Col>
                        <Col md>
                          <button
                            className="btn btn-primary mt-4 ml-5"
                            type="submit"
                          >
                            Find User
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </Fragment>
    </>
  );
};

export default NewOrder;
