import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  bestofferstotal: localStorage.getItem("bestofferstotal")
    ? JSON.parse(localStorage.getItem("bestofferstotal"))
    : [],
  mobileimage: "",
  desktopimage: "",
  posterimage: "",
  mobileimageLoading: true,
  desktopimageLoading: true,
  posterimageLoading: true,
  isLoading: true,
  isbestoffersLoading: true,
  delbestoffersLoading: true,
};

export const getBestOffers = createAsyncThunk(
  "BestOffers/getBestOffers",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/bestofferbanner/all`;
      const resp = await axios(url);
      return resp.data.bestOfferBanner;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const BestOffersPost = createAsyncThunk(
  "BestOffers/BestOffersPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/bestofferbanner/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("BestOffers Not create");
    }
  }
);

export const DesktopImages = createAsyncThunk(
  "Desktop/DesktopImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/bestofferbanner/deskimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("DesktopImages Not create");
    }
  }
);

export const MobileImages = createAsyncThunk(
  "Mobile/MobileImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/bestofferbanner/mobileimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("MobileImages Not create");
    }
  }
);

export const MobilePosterImages = createAsyncThunk(
  "MobilePoster/MobilePosterImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/bestofferbanner/posterimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("MobilePosterImages Not create");
    }
  }
);

export const BestOffersDelete = createAsyncThunk(
  "BestOffers/BestOffersDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/bestofferbanner/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("slider Not create");
    }
  }
);

const BestOffersSlice = createSlice({
  name: "BestOffers",
  initialState,
  reducers: {},
  extraReducers: {
    [getBestOffers.pending]: (state) => {
      state.isLoading = true;
    },
    [getBestOffers.fulfilled]: (state, action) => {
      state.bestofferstotal = action.payload;

      state.isLoading = false;
      state.isbestoffersLoading = false;
      localStorage.setItem(
        "bestofferstotal",
        JSON.stringify(state.bestofferstotal)
      );
    },
    [getBestOffers.rejected]: (state) => {
      state.isLoading = true;
    },

    [BestOffersPost.pending]: (state) => {
      state.isbestoffersLoading = true;
    },

    [BestOffersPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.bestofferstotal = [
          ...state.bestofferstotal,
          action.payload.bestOfferBanner,
        ];
        localStorage.setItem(
          "bestofferstotal",
          JSON.stringify(state.bestofferstotal)
        );
      }
      state.isbestoffersLoading = false;
    },

    [BestOffersPost.rejected]: (state) => {
      state.isbestoffersLoading = true;
    },

    [DesktopImages.pending]: (state) => {
      state.desktopimageLoading = true;
    },
    [DesktopImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.desktopimage = action.payload.desktopImages;
      }
      state.desktopimageLoading = false;
    },
    [DesktopImages.rejected]: (state) => {
      state.desktopimageLoading = true;
    },

    [MobileImages.pending]: (state) => {
      state.mobileimageLoading = true;
    },
    [MobileImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.mobileimage = action.payload.mobileImages;
      }
      state.mobileimageLoading = false;
    },
    [MobileImages.rejected]: (state) => {
      state.mobileimageLoading = true;
    },

    [MobilePosterImages.pending]: (state) => {
      state.posterimageLoading = true;
    },
    [MobilePosterImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.posterimage = action.payload.posterImages;
      }
      state.posterimageLoading = false;
    },
    [MobilePosterImages.rejected]: (state) => {
      state.posterimageLoading = true;
    },

    [BestOffersDelete.pending]: (state) => {
      state.delbestoffersLoading = true;
    },

    [BestOffersDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.bestofferstotal = state.bestofferstotal.filter(
          (slider) => slider._id !== action.payload.id
        );
        localStorage.setItem(
          "bestofferstotal",
          JSON.stringify(state.bestofferstotal)
        );
      }
      state.delbestoffersLoading = false;
    },

    [BestOffersDelete.rejected]: (state) => {
      state.delbestoffersLoading = true;
    },
  },
});

export const {} = BestOffersSlice.actions;
export default BestOffersSlice.reducer;
