import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { CSVLink } from "react-csv";

const ProcessingItems = () => {
  const [orderItem, setOrderItem] = useState([]);
  const [orders, setOrders] = useState([]);
  const [temploading, setTempLoading] = useState(true);
  const [loadData, setLoadData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/order/orderprocessing`;
      const fetchOrder = await axios.get(url);
      if (fetchOrder.data.success === true) {
        const orderTotal = fetchOrder.data.orders;
        setOrders(orderTotal.reverse());
        setTempLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let itemArray = [];
    for (let index = 0; index < orders.length; index++) {
      let orderid = orders[index]._id;
      let element = orders[index].OrderProducts;
      element = element.map(
        (product) => (product = { ...product, orderId: orderid })
      );
      const itmendata = { ...element, orderid };
      itemArray.push(element);
    }
    itemArray = itemArray.flat(1);
    setOrderItem(itemArray);
  }, [orders, orderItem]);

  useEffect(() => {
    let formdata = {};
    let packsize = [];
    let exportData = [];

    for (let index = 0; index < orders.length; index++) {
      let ordeerId = orders[index]._id;
      let element = orders[index].OrderProducts;
      element = element.map(
        (product) => (product = { ...product, orderId: ordeerId })
      );
      packsize = [...packsize, ...element];
      packsize = [...packsize];
    }
    for (let index = 0; index < packsize.length; index++) {
      let element = packsize[index];

      formdata = {
        OrderId: element.orderId.slice(18),
        ProductName: element.ProductName,
        PackSize: element.PackSize,
        Brand: element.Brand,
        Price: element.Price,
        Mrp: element.Mrp,
        TotalPrice: element.TotalPrice,
        TotalMrp: element.TotalMrp,
        CatName: element.CatName,
      };

      exportData = [...exportData, formdata];
    }
    setLoadData(exportData);
  }, [orders]);

  const dispatch = useDispatch();

  const columns = [
    {
      field: "Order ID",
      width: 100,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex my-3" style={{ textTransform: "uppercase" }}>
            {params.row.orderId.slice(18)}
          </div>
        );
      },
    },

    {
      field: "ItemName",
      headerName: "ItemName",
      width: 250,
      headerAlign: "center",
      // align: "center",
    },
    {
      field: "Brand",
      headerName: "Brand",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "PackSize",
      headerName: "PackSize",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Qty",
      headerName: "Qty",
      width: 120,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "Price",
      headerName: "Price",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "TotalPrice",
      headerName: "TotalPrice",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
  ];
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <div className="card m-4">
              <div className="card-footer">
                <h5>Processing Items</h5>
              </div>
              <Row className="g-2  m-2 ">
                <Col md>
                  <div className="card-body m-4">
                    <CSVLink
                      data={loadData}
                      filename={"kw-processing-OrderId-data.csv"}
                      onClick={() => {}}
                    >
                      <button className="btn btn-primary">
                        {" "}
                        Export By OrderId
                      </button>
                    </CSVLink>
                  </div>
                </Col>
                <Col md>
                  <div className="card-body m-4">
                    <CSVLink
                      data={loadData}
                      filename={"kw-processing-Itemname-data.csv"}
                      onClick={() => {}}
                    >
                      <button className="btn btn-primary">
                        {" "}
                        Export By ItemName
                      </button>
                    </CSVLink>
                  </div>
                </Col>
                <Col md>
                  <div className="card-body m-4">
                    <CSVLink
                      data={loadData}
                      filename={"kw-processing-Brand-data.csv"}
                      onClick={() => {}}
                    >
                      <button className="btn btn-primary">
                        {" "}
                        Export By Brand
                      </button>
                    </CSVLink>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="card me-2 ms-2">
            <div
              style={{ height: 650, width: "100%" }}
              className="d-flex m-auto text-align-center"
            >
              <DataGrid
                rows={orderItem}
                columns={columns}
                getRowId={(row) => row._id}
                rowHeight={100}
                getRowHeight={() => "auto"}
                pageSize={20}
                rowsPerPageOptions={[5]}
                slots={{
                  toolbar: GridToolbar,
                }}
                sx={{
                  ".MuiDataGrid-columnSeparator": {
                    display: "none",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
              />
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ProcessingItems;
