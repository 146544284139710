import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { updateOrder } from "../../redux/order/orderSlice";
import BeatLoader from "react-spinners/BeatLoader";
import {
  clientUpdate,
  getClientByMob,
  walletTransitionPost,
} from "../../redux/client/clientSlice";

const SubstactWalletUpdate = () => {
  const { ordertotal, isOrderLoading } = useSelector((store) => store.orders);
  const dispatch = useDispatch();

  const [updateBoxShow, setUpdateBoxShow] = useState(false);
  const [search, setSearch] = useState("");
  const [cnfNumber, setCnfNumber] = useState("");
  const [numberError, setnumberError] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [walletamount, setWalletamount] = useState("");
  const [walletamountCnf, setWalletamountCnf] = useState("");
  const [errorColour, setErrorColour] = useState("red");

  const numberchange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setSearch(value);
    setnumberError("");
    setErrorColour("");
    if (value.length === 10) {
      setCnfNumber(value);
    } else {
      setnumberError("Please Enter Correct Number");
      setErrorColour("red");
    }
  };

  const getUserDetails = async (e) => {
    e.preventDefault();
    if (cnfNumber !== "") {
      const getClientForm = {
        Mobile: cnfNumber,
      };

      const getOrderClient = await dispatch(getClientByMob(getClientForm));
      let getclient = getOrderClient.payload.client;
      if (getclient) {
        setUserDetails(getclient);
        setUpdateBoxShow(true);
        setWalletamount("");
      }
    }
  };

  const walletamountchange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setWalletamount(value);
    if (value !== "") {
      setWalletamountCnf(value);
    }
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    const formDta = {
      WalletBalance:
      Number(userDetails.WalletBalance) - Number(walletamountCnf),
      claintid: userDetails._id,
    };
    setnumberError("");
    const data = await dispatch(clientUpdate(formDta));
    if (data.payload.success) {
      const walletForm = {
        ClientId: userDetails._id,
        previousAmount: userDetails.WalletBalance,
        availableAmount:
          Number(userDetails.WalletBalance) - Number(walletamountCnf),
        transactionAmount: Number(walletamountCnf) * -1,
        orderId: "64e318ffeb1965fb10a8de1c",
        Type: "Manual Wallet Debite",
        ClientName: userDetails.Name,
        Mobile: userDetails.Mobile,
      };
      await dispatch(walletTransitionPost(walletForm));
      setUpdateBoxShow(false);
      setErrorColour("green");
      setSearch("");
      setnumberError("user update successfully");
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          {updateBoxShow ? (
            <>
              <div className="employeesContainer">
                <form onSubmit={handlesubmit}>
                  <div className="card m-4">
                    <div className="card-footer d-flex justify-content-between">
                      <h5>Payment Status Update</h5>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => {
                          setUpdateBoxShow(false);
                          setSearch("");
                        }}
                      >
                        Search New User
                      </button>
                    </div>
                    <div className="card-body">
                      <Row className="g-2  m-2 ">
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={userDetails.Name}
                            readOnly="readOnly"
                          />
                        </Col>
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            Mobile
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={userDetails.Mobile}
                            readOnly="readOnly"
                            // onChange={(e) => getOrderId(e)}
                          />
                        </Col>
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            Available Balance
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={userDetails.WalletBalance}
                            readOnly="readOnly"
                            // onChange={(e) => getOrderId(e)}
                          />
                        </Col>
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            Wallet
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={walletamount}
                            placeholder="0"
                            onChange={(e) => walletamountchange(e)}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="card-footer text-center">
                      <button className="btn btn-primary" type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className="employeesContainer">
                <form onSubmit={getUserDetails}>
                  <div className="card m-4">
                    <div className="card-footer">
                      <h5>Substract Amount To User Wallet</h5>
                    </div>
                    <div className="card-body">
                      <Row className="g-2  m-2 ">
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            User Mobile
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter User Mobile"
                            value={search}
                            required
                            maxLength={10}
                            minLength={10}
                            onChange={(e) => numberchange(e)}
                          />
                          <p style={{ color: errorColour }}>{numberError}</p>
                        </Col>
                        <Col md>
                          <button
                            className="btn btn-primary mt-4 ml-5"
                            type="submit"
                          >
                            Search User
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </Fragment>
    </>
  );
};

export default SubstactWalletUpdate;
