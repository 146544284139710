import React, { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { MdOutlineRemove } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import {
  validateProductSlugUrl,
  productImages,
  productPost,
} from "../../../../redux/product/productSlice";

const AddClothing = () => {
  const { clothingcategory } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const { brandtotal } = useSelector((store) => store.brand);
  const { clothingsuperCatTotal } = useSelector((store) => store.superCategory);

  const {
    mobileimage,
    desktopimage,
    isproductdeskimageLoading,
    isproductmobileimageLoading,
  } = useSelector((store) => store.products);

  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [cloth, setCloth] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [brand, setBrand] = useState("");
  const [brandid, setBrandid] = useState("");
  const [catbrand, setCatbrand] = useState([]);
  const [details, setDetails] = useState();
  const [design, setDesign] = useState();
  const [material, setMaterial] = useState();
  const [metaTitle, setMetaTitle] = useState();
  const [metaKeyword, setMetaKeyword] = useState();
  const [metadesc, setMetadesc] = useState();
  const [optionname, setOptionname] = useState();
  const [color, setColor] = useState();
  const [specifications, setSpecifications] = useState();
  const [costPrice, setCostPrice] = useState();
  const [gstcost, setGstCost] = useState();
  const [sellingprice, setSellingprice] = useState();
  const [gstsellprice, setGstsellprice] = useState();
  const [mrp, setMrp] = useState();
  const [dealoftheday, setDealoftheday] = useState(Boolean(0));
  const [trending, setTrending] = useState(Boolean(0));
  const [outofstock, setOutofstock] = useState(Boolean(0));
  const [offers, setOffers] = useState(Boolean(0));
  const [recommended, setRecommended] = useState(Boolean(0));
  const [multi, setMulti] = useState(Boolean(0));
  const [cashback, setCashback] = useState(Boolean(0));
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [size, setSize] = useState("");

  const dispatch = useDispatch();

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setCloth(value);
    setErrorcolor("green");
    setCheckSlugUrl("");
    setError("Clothing available");
    const productSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(productSlug);

    if (productSlug !== "") {
      let responce = await dispatch(validateProductSlugUrl(productSlug));
      if (responce.payload.success) {
        setError("Clothing name already exist");
        setErrorcolor("red");
      } else if (productSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (productSlug.length >= 3) {
        setErrorcolor("green");
        setError("Clothing available");
        setCheckSlugUrl(productSlug);
      }
    }
  };
  const discount = Math.round(((mrp - costPrice) * 100) / mrp);

  const handlesubmit = async (e) => {
    if (checkslugUrl !== "") {
      e.preventDefault();
      const formData = {
        name: cloth.charAt(0).toUpperCase() + cloth.slice(1).toLowerCase(),
        slugUrl: checkslugUrl,
        superCategory: clothingsuperCatTotal.name,
        superCategoryId: clothingsuperCatTotal._id,
        category: category,
        categoryId: categoryid,
        subCategory: subcategory,
        subCategoryId: subcategoryid,
        brand: brand,
        // brandId: brandid,
        details: details,
        design: design,
        material: material,
        metaTitle: metaTitle,
        metaKeyword: metaKeyword,
        metaDesc: metadesc,
        // mrp: mrp,
        dealoftheday: dealoftheday,
        trending: trending,
        offers: offers,
        outOfStock: outofstock,
        recommended: recommended,
        multi: multi,
        caskBack: cashback,
        options: options,
      };
      console.log(formData);
      const data = await dispatch(productPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Clothing Added");

          setCloth("");
          setSlugUrl("");
          setDetails("");
          setDesign("");
          setCategory("");
          setMaterial("");
          setMetaTitle("");
          setMetaKeyword("");
          setMetadesc("");
          setOptionname("");
          setColor("");
          setSpecifications("");
          setCostPrice("");
          setGstCost("");
          setSellingprice("");
          setGstsellprice("");
          setTrending("");
          setOutofstock("");
          setOffers("");
          setCheckSlugUrl("");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    } else {
      alert("please enter correct values");
    }
  };
  const sizes = [
    { name: "S" },
    { name: "M" },
    { name: "L" },
    { name: "XL" },
    { name: "XXL" },
    { name: "XXXL" },
    { name: "XXXXL" },
  ];
  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };


  const selectsubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

    useEffect(() => {
      const subcatbycat = subcategorytotal.filter(
        (cat) => cat.categoryId === categoryid
      );
      setSubcat(subcatbycat);
    }, [categoryid, subcategorytotal]);

  const selectBrand = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setBrand(text);
  };
  useEffect(() => {
    const brandbycat = brandtotal.filter(
      (brand) => brand.superCategoryId === clothingsuperCatTotal._id
    );
    setCatbrand(brandbycat);
  }, [clothingsuperCatTotal._id,brandtotal]);

  const selectSizes = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    // setAddressproofid(value);
    setSize(value);
  };

  const imageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(productImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["mobileImage"] = mobileimage;
      temp[index]["desktopImage"] = desktopimage;
    }
    setOptions(temp);
    console.log(options);
  }, [mobileimage, desktopimage]);

  const [options, setOptions] = useState([
    {
      mobileImage: mobileimage,
      desktopImage: desktopimage,
      skuCode: "",
      barcode: "",
      color: "",
      sizes: "",
      optionName: "",
      costPrice: 0,
      gstCost: 0,
      sellingPrice: 0,
      gstSellPrice: 0,
      mrp: 0,
      discounts: 0,
    },
  ]);
  console.log(options);

  const handleCheck = (index, e, selected) => {
    console.log(index, e.target.value, selected);
    let temp = [...options];
    temp[index][selected] = e.target.value;
    setOptions(temp);
    if (
      (selected === "mrp" || selected === "sellprice") &&
      temp[index]["mrp"] > 0 &&
      temp[index]["sellprice"] > 0 &&
      temp[index]["mrp"] > temp[index]["sellprice"]
    ) {
      handleDiscount(index, e, selected);
    }
  };
  const handleDiscount = (index, e, selected) => {
    let temp = [...options];
    const discounts = Math.round(
      ((temp[index]["mrp"] - temp[index]["sellprice"]) * 100) /
        temp[index]["mrp"]
    );
    console.log(discounts);
    console.log(index);
    temp[index]["discounts"] = discounts;

    setOptions(temp);
  };

  const handleNewRow = () => {
    setOptions([
      ...options,
      {
        mobileImage: mobileimage,
        desktopImage: desktopimage,
        skuCode: "",
        barcode: "",
        color: "",
        sizes: "",
        optionName: "",
        costPrice: 0,
        gstCost: 0,
        sellingPrice: 0,
        gstSellPrice: 0,
        mrp: 0,
        discounts: 0,
      },
    ]);
  };

  const removeRowClick = (index) => {
    const optionList = [...options];
    optionList.splice(index, 1);
    setOptions(optionList);
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Add Clothing</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Clothing</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={clothingcategory._id}
                        name={clothingcategory.name}
                      >
                        <option value="0">{"Select  Category"}</option>
                        {clothingcategory.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectsubCategory(e);
                        }}
                        value={subCat._id}
                        name={subCat.name}
                      >
                        <option value="0">{"Select Sub-Category"}</option>
                        {subCat.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Brand *
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectBrand(e);
                        }}
                        value={catbrand.name}
                        name={catbrand.name}
                      >
                        <option value="0">{"Select Brand"}</option>
                        {catbrand.map((data) => (
                          <option
                            key={data.name}
                            value={data.name}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter name....."
                        value={cloth}
                        required
                        onChange={(e) => verifyslugurl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                  </Row>
                  {/* <Row className="g-2  m-2 w-50">
                      <Col md>
                        <Form.Label htmlFor="disabledTextInput">
                          slugUrl*
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="enter slugUrl....."
                          value={slugUrl}
                          onChange={(e) => setSlugUrl(e.target.value)}
                        />
                      </Col>
                      
                    </Row> */}
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Details*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="enter Details....."
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Design *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Design....."
                        value={design}
                        required
                        onChange={(e) => setDesign(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="dealoftheday"
                        checked={dealoftheday === true}
                        onChange={(e) => {
                          setDealoftheday(e.target.checked);
                        }}
                      />
                      <Form.Label>Deal of the day</Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        value="trending"
                        className="me-1"
                        checked={trending === true}
                        onChange={(e) => {
                          setTrending(e.target.checked);
                        }}
                      />
                      <Form.Label>Trending </Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        value="outofstock"
                        className="me-1"
                        checked={outofstock === true}
                        onChange={(e) => {
                          setOutofstock(e.target.checked);
                        }}
                      />
                      <Form.Label>Out Of Stock </Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="offers"
                        checked={offers === true}
                        onChange={(e) => {
                          setOffers(e.target.checked);
                        }}
                      />
                      <Form.Label>Offers</Form.Label>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        value="recommended"
                        className="me-1"
                        checked={recommended === true}
                        onChange={(e) => {
                          setRecommended(e.target.checked);
                        }}
                      />
                      <Form.Label>recommended </Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        value="multi"
                        className="me-1"
                        checked={multi === true}
                        onChange={(e) => {
                          setMulti(e.target.checked);
                        }}
                      />
                      <Form.Label>Multi</Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="cashback"
                        checked={cashback === true}
                        onChange={(e) => {
                          setCashback(e.target.checked);
                        }}
                      />
                      <Form.Label>CaskBack</Form.Label>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Material*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Material....."
                        value={material}
                        onChange={(e) => setMaterial(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Title *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metaTitle}
                        onChange={(e) => setMetaTitle(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Keyword *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metaKeyword}
                        onChange={(e) => setMetaKeyword(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Descriptrion *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 w-50">
                    <Col md>
                      <Form.Label>Clothing Image</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={imageChange}
                      />
                      <p>
                        * The image width and height should be 500px * 500px
                      </p>
                      <div>
                        {isproductdeskimageLoading ? (
                          <div></div>
                        ) : (
                          <img src={desktopimage} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>

                  <div className="card-footer mt-4">
                    <h3 className="text-center">Varients</h3>
                  </div>

                  <div>
                    {options.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              SkuCode
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.skuCode}
                              required
                              onChange={(e) => handleCheck(index, e, "skuCode")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Barcode
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.barcode}
                              required
                              onChange={(e) => handleCheck(index, e, "barcode")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Color
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.color}
                              required
                              onChange={(e) => handleCheck(index, e, "color")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Sizes
                            </Form.Label>
                            <Form.Select
                              onChange={(e) => {
                                selectSizes(e);
                              }}
                              name={sizes.name}
                            >
                              <option value="0">{"Select Sizes"}</option>
                              {sizes.map((size, index) => (
                                <option key={index} value={size.name}>
                                  {size.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>

                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Varient
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.optionName}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "optionName")
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Purches P
                            </Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.costPrice}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "costPrice")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="gstcost">GST Cost</Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.gstCost}
                              required
                              onChange={(e) => handleCheck(index, e, "gstCost")}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Sell Price
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.sellingPrice}
                              onChange={(e) =>
                                handleCheck(index, e, "sellingPrice")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              GST Sell P
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.gstSellPrice}
                              onChange={(e) =>
                                handleCheck(index, e, "gstSellPrice")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              MRP
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.mrp}
                              onChange={(e) => handleCheck(index, e, "mrp")}
                            />
                          </Col>
                          {options.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) => removeRowClick(e, index)}
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={handleNewRow}
                    >
                      Add More
                    </Button>
                  </div>
                </div>
                {/* <div className="card-footer text-center ">
                    <button className="btn btn-primary me-2 " type="submit">
                          Add Clothing
                        </button>
                    </div> */}
                <div className="card-footer text-center ">
                  {checkslugUrl !== "" &&
                  categoryid !== "" &&
                  subcategoryid !== "" &&
                  !isproductdeskimageLoading ? (
                    <button className="btn btn-primary me-2 " type="submit">
                      Add Clothing
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary me-2 "
                      disabled
                      type="submit"
                    >
                      Add Clothing
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddClothing;
