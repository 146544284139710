import React, { Fragment, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { couponDelete, couponPost } from "../../../redux/coupon/couponSlice";

const AddCoupon = () => {
  const { coupontotal } = useSelector((store) => store.Coupons);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [couponname, setCouponname] = useState("");
  const [type, setType] = useState("");
  const [value, setValue] = useState(0);
  const [times, setTimes] = useState(0);
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [maxdiscount, setMaxdiscount] = useState(0);
  const [minamount, setMinamount] = useState(0);
  const [paymenttype, setPaymenttype] = useState("");

  const types = [
    { name: "Percentege Discount" },
    { name: "Fixed Discount" },
    { name: "Free Delivery" },
  ];
  const paymenttypes = [{ name: "COD" }, { name: "Payment Getway" }];

  const selectpayment = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    // setCategoryid(value);
    setPaymenttype(text);
  };

  const selecttype = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    // setCategoryid(value);
    setType(text);
  };
  const dispatch = useDispatch();
  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      Title: title,
      CouponName:
        couponname.charAt(0).toUpperCase() + couponname.slice(1).toLowerCase(),
      Value: value,
      Type: type,
      Times: times,
      FDate: fromdate,
      TDate: todate,
      MaxDisc: maxdiscount,
      MinAmount: minamount,
      PaymentType: paymenttype,
      Description: description,
    };
    console.log(formData);
    const data = await dispatch(couponPost(formData));

    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("Coupon Added");
        setTitle("");
        setDescription("");
        setCouponname("");
        setType("");
        setValue("");
        setTimes("");
        setFromdate("");
        setTodate("");
        setMaxdiscount("");
        setMinamount("");
        setPaymenttype("");
      } else {
        alert(data.message);
        console.log(data.error);
      }
    }
  };

  const deleteClick = async (e, id) => {
    dispatch(couponDelete(id));
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "CouponName",
      headerName: "COUPON NAME",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Value",
      headerName: "VALUE",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Type",
      headerName: "TYPE",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Times",
      headerName: "TIMES",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "MaxDisc",
      headerName: "MAX DISCOUNT",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "MinAmount",
      headerName: "MIN AMOUNT",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ACTION",
      width: 170,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              onClick={(e) => deleteClick(e, cellValues.row._id)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Coupon</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Coupon Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Coupon Code"
                        value={couponname}
                        onChange={(e) => setCouponname(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Type</Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selecttype(e);
                        }}
                        value={types.name}
                        name={types.name}
                      >
                        <option value="0">{"Select Type"}</option>
                        {types.map((data) => (
                          <option
                            key={data.name}
                            value={data.name}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label>Value</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Value"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Times</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Times"
                        value={times}
                        onChange={(e) => setTimes(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>From Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={fromdate}
                        onChange={(e) => setFromdate(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>To Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={todate}
                        onChange={(e) => setTodate(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Maximun Discount</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Maximun Discount"
                        value={maxdiscount}
                        onChange={(e) => setMaxdiscount(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Minimum Amount</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Minimum Amount"
                        value={minamount}
                        onChange={(e) => setMinamount(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Payment Type</Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectpayment(e);
                        }}
                        value={paymenttypes.name}
                        name={paymenttypes.name}
                      >
                        <option value="0">{"Select Payment Type"}</option>
                        {paymenttypes.map((data) => (
                          <option
                            key={data.name}
                            value={data.name}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Add Coupon
                  </button>
                </div>
              </div>
            </form>

            <div className="card me-2 ms-2">
              <div className="card-footer">
                <h5>List</h5>
              </div>
              <div className="card-body">
                <div
                  style={{ height: 650, width: "100%" }}
                  className="d-flex m-auto text-align-center"
                >
                  <DataGrid
                    rows={coupontotal}
                    columns={columns}
                    getRowId={(row) => row._id}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddCoupon;
