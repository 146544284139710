import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
    superCatTotal: localStorage.getItem("superCatTotal")
      ? JSON.parse(localStorage.getItem("superCatTotal"))
      : [],
    electronicssuperCatTotal: localStorage.getItem("superCatTotal")
      ? JSON.parse(localStorage.getItem("superCatTotal"))
      : [],
    clothingsuperCatTotal: localStorage.getItem("superCatTotal")
      ? JSON.parse(localStorage.getItem("superCatTotal"))
      : [],
    softtoyssuperCatTotal: localStorage.getItem("superCatTotal")
      ? JSON.parse(localStorage.getItem("superCatTotal"))
      : [],
    isLoading: localStorage.getItem("superCatTotal") ? false : true,
    supercatLoading: true,
    delSupercatLoading: true,
    checkSlugurl: true,
  };

  export const getSuperCategory = createAsyncThunk(
    "SuperCategory/getSuperCategory",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/supercategory/all`;
        const resp = await axios(url);
        return resp.data.supercategories;
      } catch (error) {
        return thunkAPI.rejectWithValue("supercategories Not Found");
      }
    }
  );
  export const postSuperCategory = createAsyncThunk(
    "superCategory/postSuperCategory",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/supercategory/new`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("SuperCategory Not create");
      }
    }
  );
  export const updateSuperCategory = createAsyncThunk(
    "SuperCategory/updateSuperCategory",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/supercategory/${formData.supercatid}`;
        const resp = await axios.put(url, formData, config);
        
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("supercategory Not create");
      }
    }
  );
  export const superCategoryDelete = createAsyncThunk(
    "superCategory/superCategoryDelete",
    async (id, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/supercategory/${id}`;
        const resp = await axios.delete(url,id , config);
        console.log(resp.data);
    const myreturn ={
          success:resp.data.success,
          id:id,
        }
        return myreturn;
      } catch (error) {
        return thunkAPI.rejectWithValue("supercategory Not create");
      }
    }
  );

  export const validateSuperCatSlugUrl = createAsyncThunk(
    "supercat/validateSuperCatSlugUrl",
    async (slugurl , thunkAPI) => {
      let resp = {
        success: false,
        message: "new email",
      }
      try {
        const url = `${Baseurl}/api/v1/supercategory/slugurl/${slugurl}`;
        const resp = await axios.get(url);
        return resp.data;
      } catch (error) {
        return error;
      }
    }
  );

  const SuperCategorySlice = createSlice({
    name: "superCategory",
    initialState,
    extraReducers: {
      [getSuperCategory.pending]: (state) => {
        state.isLoading = true;
      },
      [getSuperCategory.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.superCatTotal = action.payload;

        state.electronicssuperCatTotal = action.payload.find(
          (supercategory) => supercategory.name === "Electronics"
        );
        state.softtoyssuperCatTotal = action.payload.find(
          (supercategory) => supercategory.name === "Soft-toys"
        );
        state.clothingsuperCatTotal = action.payload.find(
          (supercategory) => supercategory.name === "Clothing"
        );

        localStorage.setItem("superCatTotal", JSON.stringify(state.superCatTotal));
      },
      [getSuperCategory.rejected]: (state, action) => {
        state.isLoading = true;
      },
  

      [postSuperCategory.pending]: (state) => {
        state.supercatLoading = true;
      },
  
      [postSuperCategory.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.superCatTotal = [...state.superCatTotal, action.payload.supercategory];
          localStorage.setItem("superCatTotal", JSON.stringify(state.superCatTotal));
        }
        state.supercatLoading = false;
        state.checkSlugurl = false;
      },
  
      [postSuperCategory.rejected]: (state, action) => {
        state.supercatLoading = true;
      },
  
  
      [updateSuperCategory.pending]: (state) => {
        state.supercatLoading = true;
      },
  
      [updateSuperCategory.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.superCatTotal = state.superCatTotal.filter((supercategory) => supercategory._id !== action.payload.supercategory._id);
          state.superCatTotal = [...state.superCatTotal, action.payload.supercategory];
          localStorage.setItem("superCatTotal", JSON.stringify(state.superCatTotal));
        }
        state.supercatLoading = false;
      },
  
      [updateSuperCategory.rejected]: (state, action) => {
        state.supercatLoading = true;
      },
  
      [superCategoryDelete.pending]: (state) => {
        state.delSupercatLoading = true;
      },
  
      [superCategoryDelete.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.superCatTotal = state.superCatTotal.filter((supercategory) => supercategory._id !== action.payload.id);
        }
        state.delSupercatLoading = false;
      },
  
      [superCategoryDelete.rejected]: (state, action) => {
        state.delSupercatLoading = true;
      },
  
    },
  });
  
  export default SuperCategorySlice.reducer;