import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { updateOrder } from "../../redux/order/orderSlice";
import BeatLoader from "react-spinners/BeatLoader";

const UpdatePayment = () => {
  const { ordertotal, isOrderLoading } = useSelector((store) => store.orders);
  const dispatch = useDispatch();

  const [paymentmode, setPaymentMode] = useState("");
  const [paymentstatus, setPaymentstatus] = useState("");
  const [currentpaymentstatus, setCurrentPaymentstatus] = useState("");
  const [nettotal, setNettotal] = useState("");
  const [orderId, setorderId] = useState("");
  const [orderDetails, setorderDetails] = useState("");
  const [order, setorder] = useState("");
  const [updateBoxShow, setUpdateBoxShow] = useState(false);
  const [search, setSearch] = useState("");
  const [netPayble, setNetPayble] = useState(0);
  const [orderStatus, setOrderStatus] = useState("");
  const [orderText, setOrderText] = useState("");

  const selectpaymentMode = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setPaymentMode(text);
  };
  const selectPaymentStatus = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setPaymentstatus(text);
  };
  const selectcurrentPayment = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCurrentPaymentstatus(text);
  };
  const selectcurrentOrderStatus = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setOrderStatus(index);
    setOrderText(value);
  };
  const paymentMode = [
    { name: "COD", id: 1 },
    { name: "PayTM", id: 3 },
    { name: "PayuMoney", id: 10 },
    { name: "RazorPay", id: 2 },
    { name: "Razorpay link", id: 4 },
    { name: "Payment link", id: 5 },
    { name: "PhonePe", id: 6 },
    { name: "Google Pay", id: 7 },
    { name: "Other Wallet", id: 8 },
    { name: "Other Payment Mode", id: 9 },
  ];

  const currentPayment = [
    { name: "Not Paid", id: 1 },
    { name: "Partial Payment", id: 2 },
    { name: "Paid", id: 3 },
    { name: "Failed", id: 4 },
    { name: "Pending", id: 5 },
  ];

  const PaymentStatus = [
    { name: "TXN_SUCCESS", id: 1 },
    { name: "TXN_FAILURE", id: 3 },
    { name: "PENDING", id: 2 },
    { name: "COD", id: 4 },
  ];
  const OrderStatus = [
    { name: "Order Cancelled", status: 0, id: 1 },
    { name: "Order Recieved", status: 1, id: 3 },
    { name: "Order Processing", status: 2, id: 2 },
    { name: "Order Dispatched", status: 3, id: 4 },
    { name: "Out for Delivery", status: 4, id: 5 },
    { name: "Order Delivered", status: 5, id: 6 },
  ];

  const getOrderDetails = (e) => {
    e.preventDefault();

    const findOrderId = ordertotal.find((order) => {
      return order._id.toLowerCase().slice(18).match(search.toLowerCase());
    });
    const orderIdSl = findOrderId._id.toUpperCase().slice(18);
    setorderDetails(findOrderId);
    setorderId(orderIdSl);
    setPaymentMode(findOrderId.PaymentMode);
    setPaymentstatus(findOrderId.PaymentStatus);
    setCurrentPaymentstatus(findOrderId.CurrentStatus);
    setNetPayble(findOrderId.GrandTotal);
    setOrderStatus(findOrderId.Status);
    setOrderText(findOrderId.StatusText);
    setUpdateBoxShow(true);
  };

  const netPayblechange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNetPayble(value);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    if (
      orderDetails !== "" &&
      paymentmode !== "" &&
      paymentstatus !== "" &&
      orderStatus !== "" &&
      orderText !== "" &&
      netPayble >= 0 &&
      currentpaymentstatus !== ""
    ) {
      const formData = {
        PaymentMode: paymentmode,
        PaymentStatus: paymentstatus,
        CurrentStatus: currentpaymentstatus,
        GrandTotal: netPayble,
        Status: orderStatus,
        StatusText: orderText,
        orderid: orderDetails._id,
      };
      const data = await dispatch(updateOrder(formData));
      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          setPaymentMode("");
          setPaymentstatus("");
          setNettotal("");
          setorderId("");
          setorder("");
          setCurrentPaymentstatus("");
          setorderDetails("");
          setSearch("");
          setNetPayble("");
          setOrderStatus("");
          setOrderText("");
          setUpdateBoxShow(false);
        } else {
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          {isOrderLoading ? (
            <>
              <div className="d-flex justify-content-center loadingMain">
                <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                  Order is being fetched
                </h4>
                <BeatLoader
                  color={"#36d7b7"}
                  loading={isOrderLoading}
                  size={10}
                  className="mt-2 mx-2"
                />
              </div>
            </>
          ) : (
            <>
              {updateBoxShow ? (
                <>
                  <div className="employeesContainer">
                    <form onSubmit={handlesubmit}>
                      <div className="card m-4">
                        <div className="card-footer d-flex justify-content-between">
                          <h5>Payment Status Update</h5>
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => {
                              setUpdateBoxShow(false);
                              setSearch("");
                            }}
                          >
                            Search New OrderID
                          </button>
                        </div>
                        <div className="card-body">
                          <Row className="g-2  m-2 ">
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Order Id
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orderId}
                                readOnly="readOnly"
                                // onChange={(e) => getOrderId(e)}
                              />
                            </Col>
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Client Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orderDetails.ClientName}
                                readOnly="readOnly"
                                // onChange={(e) => getOrderId(e)}
                              />
                            </Col>
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Client Mobile
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orderDetails.Mobile}
                                readOnly="readOnly"
                                // onChange={(e) => getOrderId(e)}
                              />
                            </Col>
                          </Row>
                          <Row className="g-2  m-2 ">
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Total Amount
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orderDetails.TotalAmount}
                                readOnly="readOnly"
                                // onChange={(e) =>GrandTotal getOrderId(e)}
                              />
                            </Col>
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Net Payble
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={netPayble}
                                onChange={(e) => netPayblechange(e)}
                              />
                            </Col>
                          </Row>
                          <Row className="g-2  m-2">
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Payment Status
                              </Form.Label>
                              <Form.Select
                                aria-label="Floating label select example"
                                onChange={(e) => {
                                  selectPaymentStatus(e);
                                }}
                                value={PaymentStatus.id}
                                name={PaymentStatus.name}
                              >
                                {PaymentStatus.map((data, index) => (
                                  <option
                                    key={index}
                                    selected={data.name === paymentstatus}
                                    value={data.id}
                                    name={data.name}
                                    required
                                  >
                                    {data.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Payment Mode
                              </Form.Label>
                              <Form.Select
                                aria-label="Floating label select example"
                                onChange={(e) => {
                                  selectpaymentMode(e);
                                }}
                                value={paymentMode.id}
                                name={paymentMode.name}
                              >
                                {paymentMode.map((data, index) => (
                                  <option
                                    key={index}
                                    selected={data.name === paymentmode}
                                    value={data.id}
                                    name={data.name}
                                    required
                                  >
                                    {data.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Currect Payment Status
                              </Form.Label>
                              <Form.Select
                                aria-label="Floating label select example"
                                onChange={(e) => {
                                  selectcurrentPayment(e);
                                }}
                                value={currentPayment.id}
                                name={currentPayment.name}
                              >
                                {currentPayment.map((data, index) => (
                                  <option
                                    key={index}
                                    selected={
                                      data.name === currentpaymentstatus
                                    }
                                    value={data.id}
                                    name={data.name}
                                    required
                                  >
                                    {data.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Order Status
                              </Form.Label>
                              <Form.Select
                                aria-label="Floating label select example"
                                onChange={(e) => {
                                  selectcurrentOrderStatus(e);
                                }}
                                value={OrderStatus.name}
                                name={OrderStatus.name}
                              >
                                {OrderStatus.map((data, index) => (
                                  <option
                                    key={index}
                                    selected={data.status === orderStatus}
                                    value={data.name}
                                    name={data.name}
                                    required
                                  >
                                    {data.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                          </Row>
                        </div>
                        <div className="card-footer text-center">
                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="employeesContainer">
                    <form onSubmit={getOrderDetails}>
                      <div className="card m-4">
                        <div className="card-footer">
                          <h5>Payment Status Update</h5>
                        </div>
                        <div className="card-body">
                          <Row className="g-2  m-2 ">
                            <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                Order Id
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Order Id"
                                value={search}
                                required
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </Col>
                            <Col md>
                              <button
                                className="btn btn-primary mt-4 ml-5"
                                type="submit"
                              >
                                Search Order
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Fragment>
    </>
  );
};

export default UpdatePayment;
