import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
    brandUpdate,
  brandImages,
  updateBrandMobileImage,
  updateBrandDeskImage
} from "../../../redux/brand/brandSlice";

const UpdateBrand = () => {
  const { desktopimage, imageLoading, mobileimage } = useSelector(
    (store) => store.brand
  );
  const { superCatTotal } = useSelector((store) => store.superCategory);
     
  const { brandtotal} = useSelector(
    (store) => store.brand
  );
  const [brand, setBrand] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [show, setShow] = useState(Boolean(0));
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [brandId, setBrandId] = useState("");
const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    const brandUrl = params.slugurl;
    const updatebrand = brandtotal.find((brand) => brand.slugUrl === brandUrl);
    setBrandId(updatebrand._id);
    setSlugUrl(updatebrand.slugUrl);
    setBrand(updatebrand.name);
    setSupercat(updatebrand.superCategory);
    setSupercatid(updatebrand.superCategoryId);
    setShow(updatebrand.show);
    dispatch(updateBrandMobileImage(updatebrand.desktopImage));
    dispatch(updateBrandDeskImage(updatebrand.mobileImage));
  }, [params.slugurl, brandtotal]);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setBrand(value);
    const brandSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(brandSlug);
  };
  



  const selectsuperCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercatid(value);
    setSupercat(text);
  };
  //   const selectCategory = (evt) => {
  //     const { value } = evt.target;
  //     const index = evt.nativeEvent.target.selectedIndex;
  //     const text = evt.nativeEvent.target[index].text;
  //     setCategoryid(value);
  //     setCategory(text);
  //   };

  //   useEffect(() => {
  //     const catbysupcat = categorytotal.filter(
  //       (cat) => cat.superCategoryId === supercatid
  //     );
  //     setSelectcat(catbysupcat);
  //   }, [supercatid]);
  
  // const selectSubCategory = (evt) => {
  //   const { value } = evt.target;
  //   const index = evt.nativeEvent.target.selectedIndex;
  //   const text = evt.nativeEvent.target[index].text;
  //   setSubcategoryid(value);
  //   setSubcategory(text);
  // };

  // useEffect(() => {
  //   const subcatbycat = subcategorytotal.filter(
  //     (cat) => cat.categoryId === categoryid
  //   );
  //   setSelectsubcat(subcatbycat);
  // }, [categoryid]);

  const handlesubmit = async (e) => {
    e.preventDefault();  
    const formData = {
      name: brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase(),
      slugUrl: slugUrl,
      desktopImage: desktopimage,
      mobileImage: mobileimage,
      superCategory: supercat,
      superCategoryId: supercatid,
      show: show,
      brandid: brandId,
    };
    console.log(formData);
    const data = await dispatch(brandUpdate(formData));

    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("Brand Updated");
      } else {
        alert(data.message);
      }
    }
  };

  const imageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(brandImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Update Brand</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update Brand</h5>
                </div>
                <div className="card-body">
                  {/* <Row className="g-2  m-2 ">
                    
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={selectcat._id}
                        name={selectcat.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {selectcat.map((data) => (
                          <option
                            selected={data._id === categoryid}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub-Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={selectsubcat._id}
                        name={selectsubcat.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {selectsubcat.map((data) => (
                          <option
                            selected={data._id === subcategoryid}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row> */}
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectsuperCategory(e);
                        }}
                        value={superCatTotal._id}
                        name={superCatTotal.name}
                      >
                        <option value="0">{"Select Super Category"}</option>
                        {superCatTotal.map((data) => (
                          <option
                            selected={data._id === supercatid}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={brand}
                        required
                        onChange={(e) => verifyslugurl(e)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Brand Thumbnail Image</Form.Label>
                      <Form.Control type="file" onChange={imageChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 480px * 360px
                      </p>
                      <div>
                        {imageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={desktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col className="d-flex ">
                      <div className="me-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="show"
                          checked={show === true}
                          onChange={(e) => {
                            setShow(e.target.checked);
                          }}
                        />
                        <Form.Label>Show</Form.Label>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Update Brand
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateBrand;
