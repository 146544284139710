import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import moment from "moment/moment";
import { Button } from "@mui/material";
import {
  setSingleOrder,
  updateOrder,
  updateOrdersingleItem,
} from "../../redux/order/orderSlice";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import "../../cart.css";
import BeatLoader from "react-spinners/BeatLoader";
import { clientUpdate, getClientByMob } from "../../redux/client/clientSlice";

const OrderDetails = () => {
  const { ordertotal, singleOrder } = useSelector((store) => store.orders);
  const { grocerytotal, groceryLoading } = useSelector(
    (store) => store.groceries
  );
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategories);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [temploading, setTempLoading] = useState(true);
  const [productall, setProductall] = useState([]);

  const [products, setProducts] = useState([]);
  const [orderinfo, setOrderinfo] = useState("");
  const [cartamountDetails, setCartamountDetails] = useState("");
  const [editBoxtShow, setEditBoxtShow] = useState(false);
  const [confnewQuentity, setConfnewQuentity] = useState(1);

  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [category, setCategory] = useState("");

  const [categoryid, setCategoryid] = useState("");
  const [catbysuper, setCatbysuper] = useState([]);

  const [subcatByProducts, setSubcatByProducts] = useState([]);
  const [productName, setProductName] = useState("");
  const [productId, setProductId] = useState("");
  const [selectproduct, setSelectProduct] = useState("");
  const [selectPackSize, setSelectPacksize] = useState("");

  const [productsByPack, setProductsByPack] = useState([]);
  const [packName, setPackName] = useState("");
  const [packId, setPackId] = useState("");
  const [packIndexs, setPackIndexs] = useState("");
  const supercat = [
    { id: "63d767855658519287238b63", name: "Supermart" },
    { id: "63d8da2bd405398b770b508f", name: "Non-veg" },
    { id: "63d8da4ad405398b770b50aa", name: "Vegetables & fruits" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/grocery/all`;
      const fetchOrder = await axios.get(url);
      if (fetchOrder.data.success === true) {
        setTempLoading(false);
        const grocTotal = fetchOrder.data.grocery;
        const product = [...grocTotal].sort((a, b) =>
          a.ItemName > b.ItemName ? 1 : -1
        );
        setProductall(product);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const orderId = params.orderid;

    const fetchData = async () => {
      try {
        const url = `${Baseurl}/api/v1/order/singleorderbyclient/${orderId}`;
        const orderFetch = await axios.get(url);

        try {
          if (orderFetch.data.success) {
            const orderdetails = orderFetch.data.order;
            setOrderinfo(orderdetails);
            setProducts(orderdetails.OrderProducts);
          }
        } catch (error) {}
      } catch (error) {}
    };
    fetchData();
  }, [params.orderid]);

  useEffect(() => {
    let cartTotalAmountN = 0;
    let totalAmount = 0;
    let netPayableN = 0;
    let cartTotalMrpN = 0;
    let cartTotalPriceN = 0;
    let totalMrp = 0;
    let totalprice = 0;
    let logisticsAmountN = 40;
    const totalitem = products.filter((order) => order.Status === true);

    for (let i = 0; i < totalitem.length; i++) {
      cartTotalAmountN = cartTotalAmountN + totalitem[i].TotalAmount;

      cartTotalMrpN = cartTotalMrpN + totalitem[i].TotalMrp * totalitem[i].Qty;

      totalMrp = totalMrp + totalitem[i].Mrp * totalitem[i].Qty;

      totalprice = totalprice + totalitem[i].Price * totalitem[i].Qty;

      cartTotalPriceN =
        cartTotalPriceN + totalitem[i].TotalPrice * totalitem[i].Qty;
    }

    if (cartTotalAmountN >= 500) {
      logisticsAmountN = 0;
    } else if (
      orderinfo.CurrentStatus === "Paid" ||
      orderinfo.GrandTotal === 0
    ) {
      logisticsAmountN = 40;
    } else {
      logisticsAmountN = 40;
    }
    const coupondata = orderinfo.couponDetails;
    cartTotalAmountN = cartTotalAmountN + logisticsAmountN;
    totalAmount = cartTotalAmountN - logisticsAmountN;
    netPayableN = totalAmount - orderinfo.wallet;

    const cartAmountsForm = {
      cartTotalAmount: cartTotalAmountN,
      netPayable: netPayableN,
      cartTotalMrp: cartTotalMrpN,
      cartTotalPrice: cartTotalPriceN,
      logisticsAmount: logisticsAmountN,
      totalAmount: cartTotalAmountN,
      totalMrp: totalMrp,
      totalprice: totalprice,
    };

    setCartamountDetails(cartAmountsForm);
  }, [orderinfo, products]);

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercategoryid(value);
    setSupercategory(text);
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
  }, [supercategoryid, categorytotal]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

  const selectProduct = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setProductId(value);
    setProductName(text);
  };
  const selectPacksize = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    const currindex = Number(-1) + Number(index);
    setPackId(value);
    setPackName(text);
    setPackIndexs(currindex);
  };
  useEffect(() => {
    const subcatbycat = subcategorytotal.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategorytotal]);

  useEffect(() => {
    if (subcategoryid !== "") {
      const productbysubcat = productall.filter(
        (product) => product.SubCatId === subcategoryid
      );
      setSubcatByProducts(productbysubcat);
    }
  }, [subcategoryid, productall]);

  useEffect(() => {
    if (productId !== "") {
      const productPack = productall.find(
        (product) => product._id === productId
      );
      setSelectProduct(productPack);
      setProductsByPack(productPack.PackSizes);
    }
  }, [productId, productall]);

  useEffect(() => {
    if (packId !== "") {
      const productPack = productsByPack.find((pack) => pack._id === packId);
      setSelectPacksize(productPack);
    }
  }, [packId, productsByPack]);

  const singleOrders = async () => {
    const xyz = await dispatch(setSingleOrder(orderinfo));
  };

  const addnewitem = async () => {
    if (productId !== "" && packName !== "") {
      const newProd = productall.find((product) => product._id === productId);

      let itemIndex = -1;
      itemIndex = products.findIndex(
        (item) => item.packsizeId === newProd.PackSizes[packIndexs]._id
      );

      if (itemIndex >= 0) {
        let product = [...products].filter((order) => order.Status === true);
        product = product.map((order) => {
          if (order.packsizeId === newProd.PackSizes[packIndexs]._id) {
            order.Qty = order.Qty + 1;
            order.Status = true;
          }
          return order;
        });
        product = product;
        setProducts([...product]);
        setPackName("");
        setPackId("");
        setPackIndexs("");
        setProductId("");
        setEditBoxtShow(false);
      } else {
        let newOrderform = {};
        newOrderform = {
          ProductId: newProd._id,
          ProductName: newProd.ItemName,
          CatName: newProd.Category,
          CatId: newProd.CatId,
          SubCat: newProd.SubCat,
          SubCatId: newProd.SubCatId,
          Brand: newProd.Brand,
          ItemName: newProd.ItemName,
          PackSize: newProd.PackSizes[packIndexs].PackSize,
          Description: newProd.Description,
          ImgUrl: newProd.PackSizes[packIndexs].ImgUrlMbl,
          Price: newProd.PackSizes[packIndexs].SellingPrice,
          Qty: 1,
          TotalAmount: newProd.PackSizes[packIndexs].SellingPrice * 1,
          Mrp: newProd.PackSizes[packIndexs].Mrp,
          TotalPrice: newProd.PackSizes[packIndexs].SellingPrice * 1,
          TotalMrp: newProd.PackSizes[packIndexs].Mrp * 1,
          Discount:
            newProd.PackSizes[packIndexs].Mrp * 1 -
            newProd.PackSizes[packIndexs].SellingPrice * 1,
          Cashback: newProd.Cashback,
          SellingPrice: newProd.PackSizes[packIndexs].SellingPrice,
          GstSellPrc: newProd.PackSizes[packIndexs].GstSellPrc,
          CostPrc: newProd.PackSizes[packIndexs].CostPrc,
          GstCost: newProd.PackSizes[packIndexs].GstCost,
          packsizeId: newProd.PackSizes[packIndexs]._id,
          maximumQuantity: newProd.PackSizes[packIndexs].maximumQuantity,
          Status: true,
        };
        let product = [...products];
        product = [...product, newOrderform];
        setProducts([...product]);
        setPackName("");
        setPackId("");
        setPackIndexs("");
        setProductId("");
        newOrderform = {};
        setEditBoxtShow(false);

        let cartTotalAmountN = 0;
        let totalAmount = 0;
        let netPayableN = 0;
        let cartTotalMrpN = 0;
        let cartTotalPriceN = 0;
        let totalMrp = 0;
        let totalprice = 0;
        let logisticsAmountN = 40;
        let cartproducts = product.filter((order) => order.Status === true);

        for (let i = 0; i < cartproducts.length; i++) {
          cartTotalAmountN = cartTotalAmountN + cartproducts[i].TotalAmount;

          cartTotalMrpN =
            cartTotalMrpN + cartproducts[i].TotalMrp * cartproducts[i].Qty;

          totalMrp = totalMrp + cartproducts[i].Mrp * cartproducts[i].Qty;

          totalprice = totalprice + cartproducts[i].Price * cartproducts[i].Qty;

          cartTotalPriceN =
            cartTotalPriceN + cartproducts[i].TotalPrice * cartproducts[i].Qty;
        }

        if (cartTotalAmountN >= 500) {
          logisticsAmountN = 0;
        } else if (
          orderinfo.CurrentStatus === "Paid" ||
          orderinfo.GrandTotal === 0
        ) {
          logisticsAmountN = 40;
        } else {
          logisticsAmountN = 40;
        }
        const coupondata = orderinfo.couponDetails;
        cartTotalAmountN = cartTotalAmountN + logisticsAmountN;
        totalAmount = cartTotalAmountN - logisticsAmountN;
        netPayableN = totalAmount - orderinfo.wallet;

        const cartAmountsForm = {
          cartTotalAmount: cartTotalAmountN,
          netPayable: netPayableN,
          cartTotalMrp: cartTotalMrpN,
          cartTotalPrice: cartTotalPriceN,
          logisticsAmount: logisticsAmountN,
          totalAmount: totalAmount,
          totalMrp: totalMrp,
          totalprice: totalprice,
        };

        setCartamountDetails(cartAmountsForm);
      }
    }
  };

  const addCartQuentityClick = (e, cartProduct, cartindex) => {
    let product = [...products].filter((order) => order.Status === true);
    if (product[cartindex].Qty < 10) {
      product[cartindex].Qty = product[cartindex].Qty + 1;

      product[cartindex].TotalAmount =
        product[cartindex].Qty * product[cartindex].Price;

      setProducts([...product]);

      let cartTotalAmountN = 0;
      let totalAmount = 0;
      let netPayableN = 0;
      let cartTotalMrpN = 0;
      let cartTotalPriceN = 0;
      let totalMrp = 0;
      let totalprice = 0;
      let logisticsAmountN = 40;
      let cartproducts = product.filter((order) => order.Status === true);

      for (let i = 0; i < cartproducts.length; i++) {
        cartTotalAmountN = cartTotalAmountN + cartproducts[i].TotalAmount;

        cartTotalMrpN =
          cartTotalMrpN + cartproducts[i].TotalMrp * cartproducts[i].Qty;

        totalMrp = totalMrp + cartproducts[i].Mrp * cartproducts[i].Qty;

        totalprice = totalprice + cartproducts[i].Price * cartproducts[i].Qty;

        cartTotalPriceN =
          cartTotalPriceN + cartproducts[i].TotalPrice * cartproducts[i].Qty;
      }

      if (cartTotalAmountN >= 500) {
        logisticsAmountN = 0;
      } else if (
        orderinfo.CurrentStatus === "Paid" ||
        orderinfo.GrandTotal === 0
      ) {
        logisticsAmountN = 40;
      } else {
        logisticsAmountN = 40;
      }
      const coupondata = orderinfo.couponDetails;
      cartTotalAmountN = cartTotalAmountN + logisticsAmountN;
      totalAmount = cartTotalAmountN - logisticsAmountN;
      netPayableN = totalAmount - orderinfo.wallet;

      const cartAmountsForm = {
        cartTotalAmount: cartTotalAmountN,
        netPayable: netPayableN,
        cartTotalMrp: cartTotalMrpN,
        cartTotalPrice: cartTotalPriceN,
        logisticsAmount: logisticsAmountN,
        totalAmount: totalAmount,
        totalMrp: totalMrp,
        totalprice: totalprice,
      };

      setCartamountDetails(cartAmountsForm);
    }
  };
  const removeCartQuentityClick = (e, cartProduct, cartindex) => {
    let product = [...products].filter((order) => order.Status === true);
    if (product[cartindex].Qty > 1) {
      product[cartindex].Qty = product[cartindex].Qty - 1;

      product[cartindex].TotalAmount =
        product[cartindex].Qty * product[cartindex].Price;

      setProducts([...product]);

      let cartTotalAmountN = 0;
      let totalAmount = 0;
      let netPayableN = 0;
      let cartTotalMrpN = 0;
      let cartTotalPriceN = 0;
      let totalMrp = 0;
      let totalprice = 0;
      let logisticsAmountN = 40;
      let cartproducts = product.filter((order) => order.Status === true);

      for (let i = 0; i < cartproducts.length; i++) {
        cartTotalAmountN = cartTotalAmountN + cartproducts[i].TotalAmount;

        cartTotalMrpN =
          cartTotalMrpN + cartproducts[i].TotalMrp * cartproducts[i].Qty;

        totalMrp = totalMrp + cartproducts[i].Mrp * cartproducts[i].Qty;

        totalprice = totalprice + cartproducts[i].Price * cartproducts[i].Qty;

        cartTotalPriceN =
          cartTotalPriceN + cartproducts[i].TotalPrice * cartproducts[i].Qty;
      }

      if (cartTotalAmountN >= 500) {
        logisticsAmountN = 0;
      } else if (
        orderinfo.CurrentStatus === "Paid" ||
        orderinfo.GrandTotal === 0
      ) {
        logisticsAmountN = 40;
      } else {
        logisticsAmountN = 40;
      }
      const coupondata = orderinfo.couponDetails;
      cartTotalAmountN = cartTotalAmountN + logisticsAmountN;
      totalAmount = cartTotalAmountN - logisticsAmountN;
      netPayableN = totalAmount - orderinfo.wallet;

      const cartAmountsForm = {
        cartTotalAmount: cartTotalAmountN,
        netPayable: netPayableN,
        cartTotalMrp: cartTotalMrpN,
        cartTotalPrice: cartTotalPriceN,
        logisticsAmount: logisticsAmountN,
        totalAmount: totalAmount,
        totalMrp: totalMrp,
        totalprice: totalprice,
      };

      setCartamountDetails(cartAmountsForm);
    }
  };
  const removecartProductClick = (e, cartProduct) => {
    let product = [...products].filter((order) => order.Status === true);
    const prostatus = product;
    if (prostatus.length > 1) {
      product = product.map((order) => {
        if (order.packsizeId === cartProduct.packsizeId) {
          order.Status = false;
        }

        return order;
      });
      product = product;
      setProducts([...product]);

      let cartTotalAmountN = 0;
      let totalAmount = 0;
      let netPayableN = 0;
      let cartTotalMrpN = 0;
      let cartTotalPriceN = 0;
      let totalMrp = 0;
      let totalprice = 0;
      let logisticsAmountN = 40;
      let cartproducts = product.filter((order) => order.Status === true);

      for (let i = 0; i < cartproducts.length; i++) {
        cartTotalAmountN = cartTotalAmountN + cartproducts[i].TotalAmount;

        cartTotalMrpN =
          cartTotalMrpN + cartproducts[i].TotalMrp * cartproducts[i].Qty;
        totalMrp = totalMrp + cartproducts[i].Mrp * cartproducts[i].Qty;
        totalprice = totalprice + cartproducts[i].Price * cartproducts[i].Qty;
        cartTotalPriceN =
          cartTotalPriceN + cartproducts[i].TotalPrice * cartproducts[i].Qty;
      }

      if (cartTotalAmountN >= 500) {
        logisticsAmountN = 0;
      } else if (
        orderinfo.CurrentStatus === "Paid" ||
        orderinfo.GrandTotal === 0
      ) {
        logisticsAmountN = 40;
      } else {
        logisticsAmountN = 40;
      }
      const coupondata = orderinfo.couponDetails;
      cartTotalAmountN = cartTotalAmountN + logisticsAmountN;
      totalAmount = cartTotalAmountN - logisticsAmountN;
      netPayableN = totalAmount - orderinfo.wallet;

      const cartAmountsForm = {
        cartTotalAmount: cartTotalAmountN,
        netPayable: netPayableN,
        cartTotalMrp: cartTotalMrpN,
        cartTotalPrice: cartTotalPriceN,
        logisticsAmount: logisticsAmountN,
        totalAmount: totalAmount,
        totalMrp: totalMrp,
        totalprice: totalprice,
      };

      setCartamountDetails(cartAmountsForm);
    }
  };

  const orderUpdateClick = async () => {
    let netPayblecart = 0;
    let paidstatus = orderinfo.CurrentStatus;
    if (orderinfo.CurrentStatus === "Paid" || orderinfo.GrandTotal === 0) {
      if (
        Number(cartamountDetails.totalAmount) > Number(orderinfo.TotalAmount)
      ) {
        netPayblecart =
          Number(cartamountDetails.totalAmount) - Number(orderinfo.TotalAmount);
        paidstatus = "Partial Payment";
      } else {
        netPayblecart = 0;
        const getClientForm = {
          Mobile: orderinfo.Mobile,
        };

        const getOrderClient = await dispatch(getClientByMob(getClientForm));
        let getclient = getOrderClient.payload.client;
        const formDta = {
          WalletBalance:
            Number(getclient.WalletBalance) +
            Number(orderinfo.TotalAmount) -
            Number(cartamountDetails.totalAmount),
          claintid: orderinfo.ClientId,
        };
        dispatch(clientUpdate(formDta));
      }
    } else if (orderinfo.CurrentStatus === "Not Paid") {
      netPayblecart =
        Number(cartamountDetails.totalAmount) - Number(orderinfo.wallet);
    } else if (orderinfo.CurrentStatus === "Partial Payment") {
      netPayblecart =
        orderinfo.GrandTotal +
        Number(cartamountDetails.totalAmount) -
        Number(orderinfo.TotalAmount);
      if (netPayblecart < 0) {
        netPayblecart = 0;
        const getClientForm = {
          Mobile: orderinfo.Mobile,
        };

        const getOrderClient = await dispatch(getClientByMob(getClientForm));
        let getclient = getOrderClient.payload.client;
        const formDta = {
          WalletBalance: Number(getclient.WalletBalance) - netPayblecart,
          claintid: orderinfo.ClientId,
        };
        dispatch(clientUpdate(formDta));
      }
    }
    let produCount = products.filter((order) => order.Status === true);
    const formData = {
      orderid: orderinfo._id,
      OrderProducts: products,
      Saving: cartamountDetails.cartTotalMrp - cartamountDetails.cartTotalPrice,
      TotalAmount: Number(cartamountDetails.cartTotalAmount),
      DeliveryCharge: Number(cartamountDetails.logisticsAmount),
      GrandTotal: Number(netPayblecart),
      ProductCount: produCount.length,
      CurrentStatus: String(paidstatus),
    };
    const data = await dispatch(updateOrder(formData));
    const orderINFO = data.payload.order;
    setOrderinfo(orderINFO);
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card me-2 ms-2">
            <div className="card-footer d-flex justify-content-between">
              <h5>Order Details</h5>

              {orderinfo.Status >= 2 ? (
                <>
                  <a
                    href="https://admin.kiranaworld.in/#/admin/order-invoice"
                    // href="http://localhost:3000/#/admin/order-invoice"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => singleOrders()}
                      style={{ background: "#556ee6" }}
                    >
                      INVOICE
                    </Button>
                  </a>
                </>
              ) : (
                <></>
              )}

              {editBoxtShow === true ? (
                <></>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setEditBoxtShow(true)}
                    style={{ background: "#556ee6" }}
                  >
                    ADD NEW ITEMS
                  </Button>
                </>
              )}
            </div>

            {editBoxtShow === true ? (
              <>
                {temploading ? (
                  <>
                    {" "}
                    <div className="d-flex justify-content-center loadingMain">
                      <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                        Product is being fetched
                      </h4>
                      <BeatLoader
                        color={"#36d7b7"}
                        loading={temploading}
                        size={10}
                        className="mt-2 mx-2"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card-body">
                      <Row className="g-2  m-2">
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            Super Category
                          </Form.Label>
                          <Form.Select
                            aria-label="Floating label select example"
                            onChange={(e) => {
                              selectsupCategory(e);
                            }}
                            value={supercat.id}
                            name={supercat.name}
                          >
                            <option value="0">{"Select Super Category"}</option>
                            {supercat.map((data) => (
                              <option
                                key={data.id}
                                value={data.id}
                                name={data.name}
                                required
                              >
                                {data.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            Category
                          </Form.Label>
                          <Form.Select
                            aria-label="Floating label select example"
                            onChange={(e) => {
                              selectCategory(e);
                            }}
                            value={catbysuper._id}
                            name={catbysuper.name}
                          >
                            <option value="0">{"Select Category"}</option>
                            {catbysuper.map((data) => (
                              <option
                                key={data._id}
                                value={data._id}
                                name={data.name}
                                required
                              >
                                {data.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            Sub Category
                          </Form.Label>
                          <Form.Select
                            aria-label="Floating label select example"
                            onChange={(e) => {
                              selectSubCategory(e);
                            }}
                            value={subCat._id}
                            name={subCat.name}
                          >
                            <option value="0">{"Select Sub Category"}</option>
                            {subCat.map((data) => (
                              <option
                                key={data._id}
                                value={data._id}
                                name={data.name}
                                required
                              >
                                {data.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                      <Row className="g-2  m-2">
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            Items
                          </Form.Label>
                          <Form.Select
                            aria-label="Floating label select example"
                            onChange={(e) => {
                              selectProduct(e);
                            }}
                            value={subcatByProducts._id}
                            name={subcatByProducts.ItemName}
                          >
                            <option value="0">{"Select Item"}</option>
                            {subcatByProducts.map((data) => (
                              <option
                                key={data._id}
                                value={data._id}
                                name={data.ItemName}
                                required
                              >
                                {data.ItemName}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            Packsize
                          </Form.Label>
                          <Form.Select
                            aria-label="Floating label select example"
                            onChange={(e) => {
                              selectPacksize(e);
                            }}
                            value={productsByPack._id}
                            name={productsByPack.PackSize}
                          >
                            <option value="0">{"Select Packsize"}</option>
                            {productsByPack.map((data) => (
                              <option
                                key={data._id}
                                value={data._id}
                                name={data.PackSize}
                                required
                              >
                                {data.PackSize}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                      <Row className="g-2 w-25  m-2">
                        <Col md>
                          {confnewQuentity > 0 ? (
                            <>
                              {" "}
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => addnewitem()}
                                style={{ background: "#556ee6", color: "#fff" }}
                                className="mt-4"
                              >
                                ADD ITEMS
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                // onClick={() => addnewitem()}
                                style={{ background: "#556ee6", color: "#fff" }}
                                className="mt-4"
                                disabled
                              >
                                ADD ITEMS
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>

          <section className="h-100" style={{ backgroundColor: "#eee" }}>
            <div className="container h-100 py-2">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-12 m-0 p-0 h-75">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3 className="fw-normal mb-0 text-black ms-2">
                      Order Cart
                    </h3>
                    <div></div>
                  </div>
                  {products &&
                    products
                      .filter((order) => order.Status === true)
                      .map((cartPro, cartindex) => (
                        <div
                          className="card rounded-3 mb-1  "
                          key={cartPro._id}
                        >
                          <div className="container p-4 mt-0 ">
                            <div className="row  total-div">
                              <div className=" col-md-2 col-lg-3 col-xl-2 sub_child ">
                                <img
                                  src={cartPro.ImgUrl}
                                  className="img-fluid blur-up  "
                                />
                              </div>
                              <div className="col-md-3 col-lg-3 col-xl-3  Product-info">
                                <p className="lead fw-normal m-0 product-font">
                                  <span style={{ fontSize: "16px" }}>
                                    {cartPro.ItemName}
                                  </span>
                                  <span
                                    style={{ fontSize: "16px" }}
                                    className="mt-2"
                                  >
                                    Pack Size : {cartPro.PackSize}
                                  </span>
                                </p>
                              </div>
                              <div className="col-md-3 col-lg-2 col-xl-3  price-ifo">
                                <h5 className="price-margin">
                                  Price : ₹ {cartPro.Price}
                                </h5>
                                <h5 className="price-margin">
                                  MRP : ₹ {cartPro.Mrp}
                                </h5>
                                <h5 style={{ color: "#0e947a" }}>
                                  Total Amount : ₹ {cartPro.TotalAmount}
                                </h5>
                              </div>
                              <div className="col-md-3 col-lg-3 col-xl-3   Add-to-cart">
                                <button
                                  className="btn btn-link  button1"
                                  onClick={(e) =>
                                    removeCartQuentityClick(
                                      e,
                                      cartPro,
                                      cartindex
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                  disabled={
                                    orderinfo.Status === 5 &&
                                    orderinfo.Status === 0
                                      ? true
                                      : ""
                                  }
                                >
                                  <i className="fas fa-minus b1" />
                                </button>
                                <input
                                  // id="form1"
                                  type="text"
                                  name="quantity"
                                  value={cartPro.Qty}
                                  contentEditable="false"
                                  readOnly="readOnly"
                                  className="form-control forminputs form-control-sm"
                                  style={{ backgroundColor: "transparent" }}
                                />
                                <button
                                  className="btn btn-link  button1"
                                  onClick={(e) =>
                                    addCartQuentityClick(e, cartPro, cartindex)
                                  }
                                  disabled={
                                    orderinfo.Status === 5 &&
                                    orderinfo.Status === 0
                                      ? true
                                      : ""
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="fas fa-plus b1 " />
                                </button>
                              </div>
                              {orderinfo.Status === 5 &&
                              orderinfo.Status === 0 ? (
                                <>
                                  <div className="col-md-1 col-lg-1 col-xl-1 text-end  Remove">
                                    <a
                                      className="text-danger "
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="fas fa-trash fa-lg" />{" "}
                                      <button
                                        type="button"
                                        className="mobileView text-danger"
                                      >
                                        Remove
                                      </button>
                                    </a>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="col-md-1 col-lg-1 col-xl-1 text-end  Remove"
                                    onClick={(e) =>
                                      removecartProductClick(
                                        e,
                                        cartPro,
                                        cartindex
                                      )
                                    }
                                  >
                                    <a
                                      className="text-danger "
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="fas fa-trash fa-lg" />{" "}
                                      <button
                                        type="button"
                                        className="mobileView text-danger"
                                      >
                                        Remove
                                      </button>
                                    </a>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}

                  <div className="card rounded-3 mb-1 ">
                    <div className="d-flex justify-content-end">
                      <ul className="">
                        <li
                          className="mb-0"
                          style={{
                            listStyle: "none",
                            fontSize: "18px",
                            fontWeight: "400",
                          }}
                        >
                          Total MRP :
                        </li>
                        <li
                          className="mb-0"
                          style={{
                            listStyle: "none",
                            fontSize: "18px",
                            fontWeight: "400",
                          }}
                        >
                          Sub-Total :
                        </li>
                        <li
                          className="mb-0"
                          style={{
                            listStyle: "none",
                            fontSize: "18px",
                            fontWeight: "400",
                          }}
                        >
                          Shipping :
                        </li>

                        <li
                          className="mb-0"
                          style={{
                            listStyle: "none",
                            fontSize: "18px",
                            fontWeight: "400",
                          }}
                        >
                          Wallet Used :
                        </li>
                        <li
                          className="mb-0"
                          style={{
                            listStyle: "none",
                            fontSize: "18px",
                            fontWeight: "400",
                          }}
                        >
                          Grand Total :
                        </li>
                      </ul>
                      <ul className="mr-3">
                        <li
                          className="mb-0"
                          style={{
                            listStyle: "none",
                            fontSize: "18px",
                            fontWeight: "400",
                          }}
                        >
                          ₹ {cartamountDetails.totalMrp}
                        </li>
                        <li
                          className="mb-0"
                          style={{
                            listStyle: "none",
                            fontSize: "18px",
                            fontWeight: "400",
                          }}
                        >
                          ₹ {cartamountDetails.cartTotalAmount}
                        </li>
                        <li
                          className="mb-0"
                          style={{
                            listStyle: "none",
                            fontSize: "18px",
                            fontWeight: "400",
                          }}
                        >
                          ₹ {cartamountDetails.logisticsAmount}
                        </li>
                        <li
                          className="mb-0"
                          style={{
                            listStyle: "none",
                            fontSize: "18px",
                            fontWeight: "400",
                          }}
                        >
                          ₹ {orderinfo.wallet}
                        </li>
                        <li
                          className="mb-0"
                          style={{
                            listStyle: "none",
                            fontSize: "18px",
                            fontWeight: "400",
                          }}
                        >
                          ₹{" "}
                          {cartamountDetails.cartTotalAmount - orderinfo.wallet}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {orderinfo.Status === 5 && orderinfo.Status === 0 ? (
                    <>
                      <div className="card " style={{ height: "80px" }}>
                        <div className="card-body mb-4">
                          <button
                            type="button"
                            className="btn btn-warning btn-block btn-lg"
                          >
                            Proceed to Order Update
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="card " style={{ height: "80px" }}>
                        <div className="card-body mb-4">
                          <button
                            type="button"
                            className="btn btn-warning btn-block btn-lg"
                            onClick={() => orderUpdateClick()}
                          >
                            Proceed to Order Update
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    </>
  );
};

export default OrderDetails;
