import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Link, useParams } from "react-router-dom";
import {
  subCatBannerMobileImages,
  subCatBannerDesktopImages,
  subCatBannerUpdate,
  updateSubCatBannerMobileImage,
  updateSubCatBannerDeskImage,
} from "../../../redux/subCatBanner/subCatBannerSlice";
import { useEffect } from "react";

const UpdateSubCatBanner = () => {
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const {
    mobileimage,
    desktopimage,
    mobileimageLoading,
    desktopimageLoading,
    subcatbannertotal,
  } = useSelector((store) => store.Subcatbanner);
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [show, setShow] = useState(Boolean(0));
  const [checkurl, setCheckurl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [subCatBannerId, setSubCatBannerId] = useState("");

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

  const desktopChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(subCatBannerDesktopImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const mobileChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(subCatBannerMobileImages({ mobileImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    const subcatbannerurl = params.url;
    const updatesubcatbanner = subcatbannertotal.find(
      (banner) => banner._id === subcatbannerurl
    );
    setSubCatBannerId(updatesubcatbanner._id);
    setName(updatesubcatbanner.name);
    setUrl(updatesubcatbanner.URL);
    setShow(updatesubcatbanner.show);
    setCategory(updatesubcatbanner.Category);
    setCategoryid(updatesubcatbanner.CatId);
    setSubcategory(updatesubcatbanner.SubCat);
    setSubcategoryid(updatesubcatbanner.SubCatId);
    dispatch(updateSubCatBannerMobileImage(updatesubcatbanner.MblImg));
    dispatch(updateSubCatBannerDeskImage(updatesubcatbanner.DeskImg));
  }, [params.url, subcatbannertotal]);

  const verifyurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckurl("");
    const subcatbannerurl = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setUrl(subcatbannerurl);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
      URL: url,
      Category: category,
      CatId: categoryid,
      SubCat: subcategory,
      SubCatId: subcategoryid,
      DeskImg: mobileimage,
      MblImg: desktopimage,
      show: show,
      subCatBannerid: subCatBannerId,
    };
    console.log(formData);
    const data = await dispatch(subCatBannerUpdate(formData));

    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("SubCat Banner Updated");
      } else {
        alert(data.message);
        console.log(data.error);
      }
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Update Sub Category Banner</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={categorytotal._id}
                        name={categorytotal.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {categorytotal.map((data) => (
                          <option
                            selected={data._id === categoryid}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub-Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={subcategorytotal._id}
                        name={subcategorytotal.name}
                      >
                        <option value="0">{"Select Sub Category"}</option>
                        {subcategorytotal.map((data) => (
                          <option
                            selected={data._id === subcategoryid}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        required
                        onChange={(e) => verifyurl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    {/* <Col md>
                      <Form.Label htmlFor="disabledTextInput">Url *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={url}
                        required
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </Col> */}
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control type="file" onChange={desktopChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 1500px * 470px
                      </p>
                      <div>
                        {desktopimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={desktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Mobile Image</Form.Label>
                      <Form.Control type="file" onChange={mobileChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 500px * 226px
                      </p>
                      <div>
                        {mobileimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={mobileimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col className="d-flex ">
                      <div className="me-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="show"
                          checked={show === true}
                          onChange={(e) => {
                            setShow(e.target.checked);
                          }}
                        />
                        <Form.Label>Show</Form.Label>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Update Sub-Category Banner
                  </button>
                </div>
                {/* <div className="card-footer ">
                  {checkurl !== "" &&
                  categoryid !== "" &&
                  !mobileimageLoading &&
                  !desktopimageLoading ? (
                    <button className="btn btn-primary" type="submit">
                      Add Sub Category Banner
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Sub Category Banner
                    </button>
                  )}
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateSubCatBanner;
