// import React from "react";
// import { useState } from "react";
// import { Fragment } from "react";
// import Col from "react-bootstrap/Col";
// import Form from "react-bootstrap/Form";
// import Row from "react-bootstrap/Row";
// import { useDispatch, useSelector } from "react-redux";
// import { categoryImages } from "../../../redux/category/categorySlice";

// const Banners = () => {
//       const {
//         mobileimage,
//         desktopimage,
//         isCatmobileImageLoading,
//         isDeskImageLoading,
//         checkSlugurl,
//         categorytotal,
//     } = useSelector((store) => store.categoryAll);
//      const [categroy, setCategroy] = useState("");
//     const [categroyid, setCategroyid] = useState("");
//     const dispatch = useDispatch();

//       const selectCategory = (evt) => {
//         const { value } = evt.target;
//         const index = evt.nativeEvent.target.selectedIndex;
//         const text = evt.nativeEvent.target[index].text;
//         setCategroyid(value);
//         setCategroy(text);
//     };
    
//       const categoryImageChange = (e) => {
//         const files = Array.from(e.target.files);
//         files.forEach((file) => {
//           const reader = new FileReader();
//           reader.onload = () => {
//             if (reader.readyState === 2) {
//               dispatch(categoryImages({ desktopImage: reader.result }));
//             }
//           };
//           reader.readAsDataURL(file);
//         });
//     };
    
//   return (
//     <>
//       <Fragment>
//         <div className="content-wrapper">
//           <div className="employeesContainer">
//             <div className="card-body">
//               <Row className="g-2  m-2 ">
//                 <Col md>
//                   <Form.Label htmlFor="disabledTextInput">
//                     Select Banner
//                   </Form.Label>

//                   <Form.Select
//                     aria-label="Floating label select example"
//                     onChange={(e) => {
//                       selectCategory(e);
//                     }}
//                     value={categorytotal._id}
//                     name={categorytotal.name}
//                   >
//                     <option value="0">{"Select Banner"}</option>
//                     {categorytotal.map((data) => (
//                       <option
//                         selected={data._id === categroyid}
//                         key={data._id}
//                         value={data._id}
//                         name={data.name}
//                         required
//                       >
//                         {data.name}
//                       </option>
//                     ))}
//                   </Form.Select>
//                 </Col>
//                 <Col md>
//                   <Form.Label>Desktop Image</Form.Label>
//                   <Form.Control type="file" onChange={categoryImageChange} />
//                   <p className="text-center mt-2">
//                     * The image width and height should be 191px * 218px
//                   </p>
//                   <div>
//                     {isDeskImageLoading ? (
//                       <div></div>
//                     ) : (
//                       <img
//                         src={desktopimage}
//                         width={250}
//                         height={120}
//                         alt="img"
//                       />
//                     )}
//                   </div>
//                 </Col>
//               </Row>
//             </div>
//           </div>
//         </div>
//       </Fragment>
//     </>
//   );
// };

// export default Banners;

import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  categoryUpdate,
  validateSlugUrl,
  categoryImages,
  updateCatMobileImage,
  updateCatDeskimage,
} from "../../../redux/category/categorySlice";

const Banners = () => {
      const {
        mobileimage,
        desktopimage,
        isCatmobileImageLoading,
        isDeskImageLoading,
        checkSlugurl,
        categorytotal,
      } = useSelector((store) => store.categoryAll);
      const { superCatTotal } = useSelector((store) => store.superCategory);

      const [categroy, setCategroy] = useState("");
      const [slugUrl, setSlugUrl] = useState("");
      const [checkslugUrl, setCheckSlugUrl] = useState("");
      const [error, setError] = useState("");
      const [supercat, setSupercat] = useState("");
      const [supercatid, setSupercatid] = useState("");
      const [metatitle, setMetatitle] = useState("");
      const [metakeyword, setMetakeyword] = useState("");
      const [metadesc, setMetadesc] = useState("");
      const [errorcolor, setErrorcolor] = useState("red");
      const [catId, setCatId] = useState("");

      const params = useParams();
      useEffect(() => {
        const catUrl = params.slugurl;
        const updatecat = categorytotal.find((cat) => cat.slugUrl === catUrl);
        // setCatId(updatecat._id);
        // setCategroy(updatecat.name);
        // setSlugUrl(updatecat.slugUrl);
        // setSupercat(updatecat.superCategory);
        // setSupercatid(updatecat.superCategoryId);
        // setMetatitle(updatecat.metaTitle);
        // setMetakeyword(updatecat.metaKeyword);
        // setMetadesc(updatecat.metaDesc);
        // dispatch(updateCatMobileImage(updatecat.mobileImage));
        // dispatch(updateCatDeskimage(updatecat.desktopImage));
      }, [params.slugurl, categorytotal]);

      const verifyslugurl = async (e) => {
        const value = e.target.value;
        setCategroy(value);
        const catSlug = value
          .trim()
          .toLowerCase()
          .replace(" ", "-")
          .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
          .replace("--", "-")
          .replace("---", "-")
          .replace("----", "-")
          .replace("/", "-")
          .replace("//", "-")
          .replace("///", "-");
        setSlugUrl(catSlug);
      };

      const handlesubmit = async (e) => {
        e.preventDefault();
        const formData = {
          name:
            categroy.charAt(0).toUpperCase() + categroy.slice(1).toLowerCase(),
          slugUrl: slugUrl,
          superCategoryId: supercatid,
          superCategory: supercat,
          metaTitle: metatitle,
          metaKeyword: metakeyword,
          metaDesc: metadesc,
          mobileImage: mobileimage,
          desktopImage: desktopimage,
          catid: catId,
        };
        console.log(formData);
        const data = await dispatch(categoryUpdate(formData));

        if (!data) {
          <div></div>;
        } else {
          if (data.payload.success) {
            alert("Category Updated");
          } else {
            alert(data.message);
          }
        }
      };

      const selectSuperCat = (evt) => {
        const { value } = evt.target;
        const index = evt.nativeEvent.target.selectedIndex;
        const text = evt.nativeEvent.target[index].text;
        setSupercatid(value);
        setSupercat(text);
      };

      const dispatch = useDispatch();

      const categoryImageChange = (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              dispatch(categoryImages({ desktopImage: reader.result }));
            }
          };
          reader.readAsDataURL(file);
        });
      };
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Update Banner</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category
                      </Form.Label>

                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSuperCat(e);
                        }}
                        value={superCatTotal._id}
                        name={superCatTotal.name}
                      >
                        <option value="0">{"Select Super Category"}</option>
                        {superCatTotal.map((data) => (
                          <option
                            selected={data._id === supercatid}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter Category"
                        required
                        value={categroy}
                        onChange={(e) => verifyslugurl(e)}
                      />
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={categoryImageChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 191px * 218px
                      </p>
                      <div>
                        {isDeskImageLoading ? (
                          <div></div>
                        ) : (
                          <img src={desktopimage} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Meta Descriptrion </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Title </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Keyword </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Update Category
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
}

export default Banners
