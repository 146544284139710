import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";

const Example = () => {
  const [loadData, setLoadData] = useState([]);

  useEffect(() => {
    loadList();
  }, []);

  const loadList = async () => {
    const result = await axios.get("http://localhost:4000/api/v1/category/all");
    setLoadData(result.data.category.reverse());
  };
  return (
    <>
      <div className="container">
        <h4 className="text-center text-success ml-4 mb-4 mt-4"> export table</h4>
        <div className="mb-3">
            <CSVLink data={loadData} onClick={() =>{}} style={{marginLeft:"1000px"}}>export data</CSVLink>
        </div>
        <div className="row">
            <div className="col-sm-12">
                <div className="row">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>_id</th>
                                <th>name</th>
                                <th>super Category</th>
                            </tr>
                        </thead>
                        {loadData.map((name) =>{
                            <tbody>
                                <tr>
                                    <td>{name._id}</td>
                                    <td>{name.name}</td>
                                    <td>{name.superCategory}</td>
                                </tr>
                            </tbody>
                        })}
                    </table>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default Example;
