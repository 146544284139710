import "./App.css";
import { Route, Routes, HashRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import MainLayout from "./components/layout/MainLayout";
import Dashboard from "./pages/Dashboard";
import Error from "../src/pages/Error";
import Loginpage from "./pages/Loginpage";
import AddSuperCategory from "./pages/add-edit/superCategory/AddSuperCategory";
import ListSuperCategory from "./pages/add-edit/superCategory/ListSuperCategory";
import AddCategory from "./pages/add-edit/category/AddCategory";
import ListCategory from "./pages/add-edit/category/ListCategory";
import AddBrand from "./pages/add-edit/brand/AddBrand";
import ListBrand from "./pages/add-edit/brand/ListBrand";
import { getSuperCategory } from "./redux/superCategory/superCategorySlice";
import { getCategory } from "./redux/category/categorySlice";
import { getBrand } from "./redux/brand/brandSlice";
import { getSubCategory } from "./redux/subCategory/subCategorySlice";
import { getGrocery } from "./redux/grocery/grocerySlice";
import { getProduct } from "./redux/product/productSlice";
import { getSubscription } from "./redux/subscription/subscriptionSlice";
import { getOrder } from "./redux/order/orderSlice";
import { getSlider } from "./redux/slider/sliderSlice";
import { getStock } from "./redux/stock/stockSlice";
import { getCoupon } from "./redux/coupon/couponSlice";
import { getEmployees } from "./redux/emplyees/employeesSlice";
import { getPinAmount } from "./redux/pinAmount/pinAmountSlice";
import { getuniversaltag } from "./redux/tags/TagSlice";
import { getcategorytag } from "./redux/tags/TagSlice";
import { getbannertag } from "./redux/tags/TagSlice";
import { getOfferBanner } from "./redux/offersBanner/offersBannerSlice";
import AddSubCategory from "./pages/add-edit/subcategory/AddSubCategory";
import ListSubCategory from "./pages/add-edit/subcategory/ListSubCategory";
import UpdateCategory from "./pages/add-edit/category/UpdateCategory";
import UpdateSubCategory from "./pages/add-edit/subcategory/UpdateSubCategory";
import UpdateBrand from "./pages/add-edit/brand/UpdateBrand";
import UpdateSuperCategory from "./pages/add-edit/superCategory/UpdateSuperCategory";
import AddGrocery from "./pages/add-edit/grocery/AddGrocery";
import ListGrocery from "./pages/add-edit/grocery/ListGrocery";
import UpdateGrocery from "./pages/add-edit/grocery/UpdateGrocery";
import AddSubscription from "./pages/add-edit/subscription/AddSubscription";
import ListSubscription from "./pages/add-edit/subscription/ListSubscription";
import UpdateSubscription from "./pages/add-edit/subscription/UpdateSubscription";
import AddElectronics from "./pages/add-edit/product/electronics/AddElectronics";
import ListElectronics from "./pages/add-edit/product/electronics/ListElectronics";
import AddClothing from "./pages/add-edit/product/clothing/AddClothing";
import AddSoftToys from "./pages/add-edit/product/softToys/AddSoftToys";
import UpdateElectronics from "./pages/add-edit/product/electronics/UpdateElectronics";
import ListClothing from "./pages/add-edit/product/clothing/ListClothing";
import UpdateClothing from "./pages/add-edit/product/clothing/UpdateClothing";
import ListSoftToys from "./pages/add-edit/product/softToys/ListSoftToys";
import UpdateSoftToys from "./pages/add-edit/product/softToys/UpdateSoftToys";
import BookedOrder from "./pages/orders/BookedOrder";
import CancelledOrder from "./pages/orders/CancelledOrder";
import DeliveredOrder from "./pages/orders/DeliveredOrder";
import DispatchOrder from "./pages/orders/DispatchOrder";
import OutForDeliveryOrder from "./pages/orders/OutForDeliveryOrder";
import ProcessingOrder from "./pages/orders/ProcessingOrder";
import Example from "./pages/add-edit/Example";
import AddSlider from "./pages/add-edit/sliders-banners/AddSlider";
import UpdateSlider from "./pages/add-edit/sliders-banners/UpdateSlider";
import AddStock from "./pages/add-edit/stocks/AddStock";
import AddCoupon from "./pages/add-edit/coupon/AddCoupon";
import AddEmplyee from "./pages/add-edit/employees/AddEmplyee";
import UpdateEmployee from "./pages/add-edit/employees/UpdateEmployee";
import AddPinAmount from "./pages/add-edit/pinAmount/AddPinAmount";
import UpdatePinAmount from "./pages/add-edit/pinAmount/UpdatePinAmount";
import Banners from "./pages/add-edit/sliders-banners/Banners";
import ChangeOrderStatus from "./pages/reports & Settings/ChangeOrderStatus";
import ClientData from "./pages/reports & Settings/ClientData";
import DeliveredExport from "./pages/reports & Settings/DeliveredExport";
import EditOrder from "./pages/reports & Settings/EditOrder";
import Export from "./pages/reports & Settings/Export";
import NewOrder from "./pages/reports & Settings/NewOrder";
import ProcessingItems from "./pages/reports & Settings/ProcessingItems";
import ProductSEO from "./pages/reports & Settings/ProductSEO";
import UpdatePayment from "./pages/reports & Settings/UpdatePayment";
import WebSetting from "./pages/reports & Settings/WebSetting";
import OrderDetails from "./pages/orders/OrderDetails";
import AddOffersBanner from "./pages/add-edit/offersBanner/AddOffersBanner";
import AddBannerTag from "./pages/add-edit/Tags/bannerTags/AddBannerTag";
import ListBannerTag from "./pages/add-edit/Tags/bannerTags/ListBannerTag";
import AddCategoryTag from "./pages/add-edit/Tags/categoryTags/AddCategoryTag";
import ListCategoryTag from "./pages/add-edit/Tags/categoryTags/ListCategoryTag";
import AddUniversalTag from "./pages/add-edit/Tags/UniversalTags/AddUniversalTag";
import ListUniversalTag from "./pages/add-edit/Tags/UniversalTags/ListUniversalTag";
import AddSubCatBanner from "./pages/add-edit/sliders-banners/AddSubCatBanner";
import { getSubCatBanner } from "./redux/subCatBanner/subCatBannerSlice";
import UpdateSubCatBanner from "./pages/add-edit/sliders-banners/UpdateSubCatBanner";
import GroceryBulkUpload from "./pages/add-edit/grocery/GroceryBulkUpload";
import GroceryPackSizeUpload from "./pages/add-edit/grocery/GroceryPackSizeUpload";
import VegPage from "./pages/pageStatus/VegPage";
import { getVegPage } from "./redux/vegpage/VegPageSlice";
import AddTodayFeatured from "./pages/add-edit/sliders-banners/AddTodayFeatured";
import { getTodayFeatured } from "./redux/AllBanner&Slider/TodayFeaturedSlice";
import { getBestOffers } from "./redux/AllBanner&Slider/BestOffersSlice";
import { getTopBanner } from "./redux/AllBanner&Slider/TopBannerSlice";
import { getInfocusToday } from "./redux/AllBanner&Slider/InFocusTodaySlice";
import { getFeaturedInWeek } from "./redux/AllBanner&Slider/FeaturedInWeekSlice";
import AddBestOffer from "./pages/add-edit/sliders-banners/AddBestOffer";
import AddTopBanner from "./pages/add-edit/sliders-banners/AddTopBanner";
import AddInfocusToday from "./pages/add-edit/sliders-banners/AddInfocusToday";
import AddFeaturedInWeek from "./pages/add-edit/sliders-banners/AddFeaturedInWeek";
import AssignDeliveryPersion from "./pages/orders/AssignDeliveryPersion";
import ProductPriceEdit from "./pages/add-edit/productPriceEdit/ProductPriceEdit";
import BulkclientUploade from "./pages/BulkclientUploade";
import AddressBulkUploade from "./pages/AddressBulkUploade";
import OrderInvoice from "./pages/orders/OrderInvoice";
import WalletUpdate from "./pages/reports & Settings/WalletUpdate";
import WalletHistory from "./pages/reports & Settings/WalletHistory";
import SubstactWalletUpdate from "./pages/reports & Settings/SubstactWalletUpdate";
import ShowProducts from "./pages/add-edit/superCategory/ShowProducts";
import OrderGeneratePage from "./pages/subscriptionOrder/OrderGeneratePage";
import SubscriptionOrderInfo from "./pages/subscriptionOrder/SubscriptionOrderInfo";
import SubscriptionAllOrders from "./pages/subscriptionOrder/SubscriptionAllOrders";
import TodayGeneratedOrders from "./pages/subscriptionOrder/TodayGeneratedOrders";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const initialSetup = async () => {
      try {
        // getGrocery
        // dispatch(getGrocery());
        // getSuperCategory
        dispatch(getSuperCategory());

        // getCategory
        dispatch(getCategory());

        // getBrand
        dispatch(getBrand());

        // getSubCategory
        dispatch(getSubCategory());

        // getProduct
        dispatch(getProduct());

        // getSubscription
        dispatch(getSubscription());

        // getOrder
        dispatch(getOrder());

        // getSlider
        dispatch(getSlider());

        // getStock
        dispatch(getStock());

        // getCoupon
        dispatch(getCoupon());

        // getEmployees
        dispatch(getEmployees());

        // getPinAmount
        dispatch(getPinAmount());

        // getOfferBanner
        dispatch(getOfferBanner());

        // getuniversaltag
        dispatch(getuniversaltag());

        // getcategorytag
        dispatch(getcategorytag());

        // getbannertag
        dispatch(getbannertag());

        // getSubCatBanner
        dispatch(getSubCatBanner());

        // getVegPage
        dispatch(getVegPage());

        // getTodayFeatured
        dispatch(getTodayFeatured());

        // getBestOffers
        dispatch(getBestOffers());

        // getTopBanner
        dispatch(getTopBanner());

        // getInfocusToday
        dispatch(getInfocusToday());

        // getFeaturedInWeek
        dispatch(getFeaturedInWeek());
      } catch (error) { }
    };
    initialSetup();
  }, []);

  return (
    <div className="wrapper">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Loginpage />} />
          <Route path="/admin/order-invoice" element={<OrderInvoice />} />
          <Route path="/admin" exact element={<MainLayout />}>
            <Route index element={<Dashboard />} />

            {/* supercategory */}
            <Route
              path="/admin/addsupercategory"
              element={<AddSuperCategory />}
            />
            <Route
              path="/admin/product-price-edit"
              element={<ProductPriceEdit />}
            />
            <Route
              path="/admin/listsupercategory"
              element={<ListSuperCategory />}
            />
            <Route
              path="/admin/showproducts"
              element={<ShowProducts />}
            />
            <Route
              path="/admin/updatesupercategory/:slugurl"
              element={<UpdateSuperCategory />}
            />

            {/* category */}
            <Route path="/admin/addcategory" element={<AddCategory />} />
            <Route path="/admin/listcategory" element={<ListCategory />} />
            <Route path="/admin/vegpage" element={<VegPage />} />
            <Route path="/admin/wallet-update" element={<WalletUpdate />} />
            <Route path="/admin/wallet-history" element={<WalletHistory />} />
            <Route
              path="/admin/updatecategory/:slugurl"
              element={<UpdateCategory />}
            />

            {/* brand */}
            <Route path="/admin/addbrand" element={<AddBrand />} />
            <Route path="/admin/listbrand" element={<ListBrand />} />
            <Route
              path="/admin/updatebrand/:slugurl"
              element={<UpdateBrand />}
            />

            {/* subcategory */}
            <Route path="/admin/addsubcategory" element={<AddSubCategory />} />
            <Route
              path="/admin/listsubcategory"
              element={<ListSubCategory />}
            />
            <Route
              path="/admin/updatesubcategory/:slugurl"
              element={<UpdateSubCategory />}
            />

            {/* grocery */}
            <Route path="/admin/addgrocery" element={<AddGrocery />} />
            <Route path="/admin/listgrocery" element={<ListGrocery />} />
            <Route
              path="/admin/updategrocery/:slugurl"
              element={<UpdateGrocery />}
            />
            <Route
              path="/admin/grocerybulkupload"
              element={<GroceryBulkUpload />}
            />
            <Route
              path="/admin/grocerypacksizebulkupload"
              element={<GroceryPackSizeUpload />}
            />

            {/* product */}
            <Route path="/admin/addelectronics" element={<AddElectronics />} />
            <Route
              path="/admin/listelectronics"
              element={<ListElectronics />}
            />
            <Route
              path="/admin/updateelectronics/:slugurl"
              element={<UpdateElectronics />}
            />

            <Route path="/admin/addclothing" element={<AddClothing />} />
            <Route path="/admin/listclothing" element={<ListClothing />} />
            <Route
              path="/admin/updateclothing/:slugurl"
              element={<UpdateClothing />}
            />

            <Route path="/admin/addsofttoys" element={<AddSoftToys />} />
            <Route path="/admin/listsofttoys" element={<ListSoftToys />} />
            <Route
              path="/admin/updatesofttoys/:slugurl"
              element={<UpdateSoftToys />}
            />

            {/* subscription */}
            <Route
              path="/admin/addsubscription"
              element={<AddSubscription />}
            />
            <Route
              path="/admin/listsubscription"
              element={<ListSubscription />}
            />
            <Route
              path="/admin/updatesubscription/:slugurl"
              element={<UpdateSubscription />}
            />

            {/* order details */}
            <Route path="/admin/bookedorder" element={<BookedOrder />} />
            <Route
              path="/admin/orderdetails/:orderid"
              element={<OrderDetails />}
            />
            <Route
              path="/admin/assign-delivery/:orderid"
              element={<AssignDeliveryPersion />}
            />
            <Route path="/admin/cancelledorder" element={<CancelledOrder />} />
            <Route path="/admin/deliveredorder" element={<DeliveredOrder />} />
            <Route path="/admin/dispatchorder" element={<DispatchOrder />} />
            <Route
              path="/admin/outfordeliveryorder"
              element={<OutForDeliveryOrder />}
            />
            <Route
              path="/admin/processingorder"
              element={<ProcessingOrder />}
            />

            <Route path="/admin/example" element={<Example />} />

            {/* slider */}
            <Route path="/admin/addsliders" element={<AddSlider />} />
            <Route path="/admin/updateslider/:url" element={<UpdateSlider />} />
            {/* Banners */}
            <Route path="/admin/banners" element={<Banners />} />
            {/*subcat Banners */}
            <Route
              path="/admin/addsubcatbanner"
              element={<AddSubCatBanner />}
            />
            <Route
              path="/admin/addtodayfeatured"
              element={<AddTodayFeatured />}
            />
            <Route path="/admin/addbestoffer" element={<AddBestOffer />} />
            <Route path="/admin/addtopbanner" element={<AddTopBanner />} />
            <Route
              path="/admin/addinfocustoday"
              element={<AddInfocusToday />}
            />
            <Route
              path="/admin/addfeaturedinweek"
              element={<AddFeaturedInWeek />}
            />
            <Route
              path="/admin/updatesubcatbanner/:url"
              element={<UpdateSubCatBanner />}
            />

            {/* stocks */}
            <Route path="/admin/addstock" element={<AddStock />} />

            {/* coupon */}
            <Route path="/admin/addcoupon" element={<AddCoupon />} />

            {/* employee */}
            <Route path="/admin/addemployee" element={<AddEmplyee />} />
            <Route
              path="/admin/updateemployee/:id"
              element={<UpdateEmployee />}
            />

            {/* pinamount */}
            <Route path="/admin/addpinamount" element={<AddPinAmount />} />
            <Route
              path="/admin/updatepinamount/:pinamtid"
              element={<UpdatePinAmount />}
            />

            {/* offersbanner */}
            <Route
              path="/admin/addoffersbanner"
              element={<AddOffersBanner />}
            />

            {/* report&setting */}
            <Route
              path="/admin/changeorderstatus"
              element={<ChangeOrderStatus />}
            />
            <Route path="/admin/clientdata" element={<ClientData />} />
            <Route
              path="/admin/deliveredexport"
              element={<DeliveredExport />}
            />
            <Route path="/admin/editorder" element={<EditOrder />} />
            <Route path="/admin/exports" element={<Export />} />
            <Route path="/admin/neworder" element={<NewOrder />} />
            <Route
              path="/admin/processingitems"
              element={<ProcessingItems />}
            />
            <Route path="/admin/productseo" element={<ProductSEO />} />
            <Route path="/admin/updatepayment" element={<UpdatePayment />} />
            <Route path="/admin/substract-wallet" element={<SubstactWalletUpdate />} />
            <Route path="/admin/websetting" element={<WebSetting />} />

            {/* Tags */}
            <Route
              path="/admin/adduniversaltag"
              element={<AddUniversalTag />}
            />
            <Route
              path="/admin/listuniversaltag"
              element={<ListUniversalTag />}
            />

            <Route path="/admin/addbannertag" element={<AddBannerTag />} />
            <Route path="/admin/listbannertag" element={<ListBannerTag />} />

            <Route path="/admin/addcategorytag" element={<AddCategoryTag />} />
            <Route
              path="/admin/listcategorytag"
              element={<ListCategoryTag />}
            />
            <Route path="/admin/bulkuploade" element={<BulkclientUploade />} />
            <Route
              path="/admin/addresbulkuploade"
              element={<AddressBulkUploade />}
            />
            <Route
              path="/admin/subscription-order-generate"
              element={<OrderGeneratePage />}
            />
            <Route
              path="/admin/subscription-orders"
              element={<SubscriptionAllOrders />}
            />
            <Route
              path="/admin/today-subscription-orders"
              element={<TodayGeneratedOrders />}
            />
            <Route
              path="/admin/subscription-order-info/:orderid"
              element={<SubscriptionOrderInfo />}
            />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
