import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
    brandtotal: localStorage.getItem("brandtotal")
      ? JSON.parse(localStorage.getItem("brandtotal")).sort()
      : [],
    mobileimage: "",
    desktopimage: "",
    imageLoading:true,
    isLoading: true,
    brandLoading: true,
    delbrandLoading: true,
  };

  export const getBrand = createAsyncThunk("brand/getBrand", async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/brand/all`;
      const resp = await axios(url);
      return resp.data.brands;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  });

  export const brandPost = createAsyncThunk(
    "brand/brandPost",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/brand/new`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("brand Not create");
      }
    }
);
  

  export const validatebrandSlugUrl = createAsyncThunk(
    "brand/validatebrandSlugUrl",
    async (slugurl , thunkAPI) => {
      let resp = {
        success: false,
        message: "new email",
      }
      try {
        const url = `${Baseurl}/api/v1/brand/slugurl/${slugurl}`;
        const resp = await axios.get(url);
        return resp.data;
      } catch (error) {
        return error;
      }
    }
  );

  export const brandImages = createAsyncThunk(
    "brand/brandImages",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/brand/brandimages`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("brandimages Not create");
      }
    }
  );
  export const brandUpdate = createAsyncThunk(
    "brand/brandUpdate",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/brand/${formData.brandid}`;
        const resp = await axios.put(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("brand Not create");
      }
    }
  );

  export const brandDelete = createAsyncThunk(
    "brand/brandDelete",
    async (id, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/brand/${id}`;
        const resp = await axios.delete(url, id, config);
        console.log(resp.data);
        const myreturn = {
          success: resp.data.success,
          id: id,
        };
        return myreturn;
      } catch (error) {
        return thunkAPI.rejectWithValue("brand Not create");
      }
    }
  );

const BrandSlice = createSlice({
    name: "brand",
    initialState,
    reducers: {
      updateBrandMobileImage(state, action) {
        state.mobileimage = action.payload;
        state.imageLoading = false;
      },
      updateBrandDeskImage(state, action) {
        state.desktopimage = action.payload;
        state.imageLoading = false;
      }
    },
    extraReducers: {
      [getBrand.pending]: (state) => {
        state.isLoading = true;
      },
      [getBrand.fulfilled]: (state, action) => {
        state.brandtotal = action.payload.sort();
        localStorage.setItem("brandtotal", JSON.stringify(state.brandtotal));
        state.isLoading = false;
        state.brandLoading = false;
      },
      [getBrand.rejected]: (state, action) => {
        state.isLoading = true;
      },
      [brandPost.pending]: (state) => {
        state.brandLoading = true;
      },
  
      [brandPost.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.brandtotal = [...state.brandtotal, action.payload.brand].sort();
          localStorage.setItem("brandtotal", JSON.stringify(state.brandtotal));
        }
        state.brandLoading = false;
      },
  
      [brandPost.rejected]: (state, action) => {
        state.brandLoading = true;
      },

      [brandImages.pending]: (state) => {
        state.imageLoading = true;
      },
  
      [brandImages.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.mobileimage = action.payload.mobileimages;
          state.desktopimage = action.payload.desktopImages;
        }
        state.imageLoading = false;
      },
  
      [brandImages.rejected]: (state, action) => {
        state.imageLoading = true;
      },
      [brandUpdate.pending]: (state) => {
        state.brandLoading = true;
      },
  
      [brandUpdate.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.brandtotal = state.brandtotal.filter(
            (brand) => brand._id !== action.payload.brand._id
          );
          state.brandtotal = [...state.brandtotal, action.payload.brand];
          state.mobileimage = "";
          state.desktopimage = "";
          state.imageLoading = true;
          localStorage.setItem("brandtotal", JSON.stringify(state.brandtotal));
        }
        state.brandLoading = false;
      },
  
      [brandUpdate.rejected]: (state, action) => {
        state.brandLoading = true;
      },

      [brandDelete.pending]: (state) => {
        state.delbrandLoading = true;
      },
  
      [brandDelete.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.brandtotal = state.brandtotal.filter(
            (brand) => brand._id !== action.payload.id
          ).sort();
          localStorage.setItem("brandtotal", JSON.stringify(state.brandtotal));
        }
        state.delbrandLoading = false;
      },
  
      [brandDelete.rejected]: (state, action) => {
        state.delbrandLoading = true;
      },
    },
  });
  export const {
    updateBrandMobileImage,
    updateBrandDeskImage
  } = BrandSlice.actions;
  export default BrandSlice.reducer;
  