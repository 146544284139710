import React from 'react'

const Error = () => {
  return (
    <>
    <div className='errorCont'>
        <img src="dist/img/404error.jpg" alt="404 Not Available" />
    </div>
    </>
  )
}

export default Error