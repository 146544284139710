import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Modal, ModalHeader } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import {
  UpdateGrocerySinglePackSize,
  groceryUpdate,
} from "../../../redux/grocery/grocerySlice";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";

const ProductPriceEdit = () => {
  const { grocerytotal, groceryLoading } = useSelector(
    (store) => store.groceries
  );
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategories);

  const dispatch = useDispatch();

  const [catbysuper, setCatbysuper] = useState([]);
  const [subCat, setSubcat] = useState([]);
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [categoryid, setCategoryid] = useState("");

  const [filterProducts, setfilterProducts] = useState([]);
  const [temploading, setTempLoading] = useState(true);
  const [productall, setProductall] = useState([]);

  const [selectPack, setSelectPack] = useState("");
  const [model, setModel] = useState(false);
  const [outofStock, setOutofStock] = useState(false);
  const [price, setPrice] = useState(0);
  const [mrp, setMrp] = useState(0);
  const [packsizename, setPacksizename] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/grocery/all`;
      const fetchOrder = await axios.get(url);
      if (fetchOrder.data.success === true) {
        setTempLoading(false);
        const grocTotal = fetchOrder.data.grocery;
        const product = [...grocTotal].sort((a, b) =>
          a.ItemName > b.ItemName ? 1 : -1
        );
        setProductall(product);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (subcategoryid !== "") {
      const filterProduct = productall.filter(
        (product) => product.SubCatId === subcategoryid
      );
      setfilterProducts(filterProduct);
    }
  }, [subcategoryid, productall]);

  const supercat = [
    { id: "63d767855658519287238b63", name: "Supermart" },
    { id: "63d8da2bd405398b770b508f", name: "Non-veg" },
    { id: "63d8da4ad405398b770b50aa", name: "Vegetables & fruits" },
  ];

  const statusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        grocedeyid: data._id,
        OutOfStack: value,
      };
      await dispatch(groceryUpdate(formData));
    }
  };

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercategoryid(value);
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
  };

  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
  }, [supercategoryid, categorytotal]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
  };
  useEffect(() => {
    const subcatbycat = subcategorytotal.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategorytotal]);

  const updatepacksize = async (e, product, packsize) => {
    e.preventDefault();
    let calDiscount = Math.round(((mrp - price) * 100) / mrp);
    const formData = {
      Id: product._id,
      packsizeId: packsize._id,
      Mrp: mrp,
      SellingPrice: price,
      PackSize: packsizename,
      OutOfStack: outofStock,
      Discount: calDiscount,
    };
    const data = await dispatch(UpdateGrocerySinglePackSize(formData));
    if (data.payload.success) {
      let produ = filterProducts;
      // produ = produ.filter(
      //   (grocery) => grocery._id !== data.payload.grocery._id
      // );
      // produ = [...produ, data.payload.grocery];
      produ = produ.map((produc) => {
        if (produc._id === product._id) {
          let prodpack = produc.PackSizes;
          for (let index = 0; index < prodpack.length; index++) {
            if (prodpack[index]._id === packsize._id) {
              prodpack[index].Mrp = formData.Mrp;
              prodpack[index].SellingPrice = formData.SellingPrice;
              prodpack[index].PackSize = formData.PackSize;
              prodpack[index].OutOfStack = formData.OutOfStack;
            }
          }
        }

        return produc;
      });

      setfilterProducts([...produ]);
    }

    setSelectPack("");
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form>
              {temploading ? (
                <>
                  <div className="d-flex justify-content-center loadingMain">
                    <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                      Product is being fetched
                    </h4>
                    <BeatLoader
                      color={"#36d7b7"}
                      loading={temploading}
                      size={10}
                      className="mt-2 mx-2"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="card me-2 ms-2">
                    <div className="card-footer">
                      <h5> Products-Price-Edit</h5>
                    </div>
                    <div className="card-body">
                      <Row className="g-2  m-2">
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            Super Category
                          </Form.Label>
                          <Form.Select
                            aria-label="Floating label select example"
                            onChange={(e) => {
                              selectsupCategory(e);
                            }}
                            value={supercat.id}
                            name={supercat.name}
                          >
                            <option value="0">{"Select Super Category"}</option>
                            {supercat.map((data) => (
                              <option
                                key={data.id}
                                value={data.id}
                                name={data.name}
                                required
                              >
                                {data.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            Category
                          </Form.Label>
                          <Form.Select
                            aria-label="Floating label select example"
                            onChange={(e) => {
                              selectCategory(e);
                            }}
                            value={catbysuper._id}
                            name={catbysuper.name}
                          >
                            <option value="0">{"Select Category"}</option>
                            {catbysuper.map((data) => (
                              <option
                                key={data._id}
                                value={data._id}
                                name={data.name}
                                required
                              >
                                {data.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col md>
                          <Form.Label htmlFor="disabledTextInput">
                            Sub Category
                          </Form.Label>
                          <Form.Select
                            aria-label="Floating label select example"
                            onChange={(e) => {
                              selectSubCategory(e);
                            }}
                            value={subCat._id}
                            name={subCat.name}
                          >
                            <option value="0">{"Select Sub Category"}</option>
                            {subCat.map((data) => (
                              <option
                                key={data._id}
                                value={data._id}
                                name={data.name}
                                required
                              >
                                {data.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                    </div>

                    {filterProducts.map((product, index) => (
                      <div className="card-body" key={index}>
                        <Row className="g-2 w-50 m-2 ">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              {index + 1}
                              {")"} Product Name
                            </Form.Label>

                            <Form.Control
                              type="text"
                              placeholder="Enter Category"
                              disabled
                              value={product.ItemName}
                            />
                          </Col>
                        </Row>
                        {product.PackSizes.map((packsize, pacindex) => (
                          <>
                            {selectPack === packsize._id ? (
                              <>
                                <div key={pacindex}>
                                  <Row className="g-2  m-2 ">
                                    <Col
                                      md
                                      className="d-flex justify-content-center align-items-center"
                                    >
                                      <img
                                        src={packsize.ImgUrlDesk}
                                        height={100}
                                        alt="img"
                                      />
                                    </Col>
                                    <Col md>
                                      <Form.Label htmlFor="disabledTextInput">
                                        Pack Size Name
                                      </Form.Label>

                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Category"
                                        value={packsizename}
                                        onChange={(e) =>
                                          setPacksizename(e.target.value)
                                        }
                                      />
                                    </Col>

                                    <Col md>
                                      <Form.Label htmlFor="disabledTextInput">
                                        MRP
                                      </Form.Label>

                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Category"
                                        value={mrp}
                                        onChange={(e) => setMrp(e.target.value)}
                                      />
                                    </Col>
                                    <Col md>
                                      <Form.Label htmlFor="disabledTextInput">
                                        Price
                                      </Form.Label>

                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Category"
                                        value={price}
                                        onChange={(e) =>
                                          setPrice(e.target.value)
                                        }
                                      />
                                    </Col>
                                    <Col>
                                      <Form.Label htmlFor="disabledTextInput">
                                        Out Of Stock
                                      </Form.Label>
                                      <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label=" "
                                        className="d-flex justify-content-center align-items-center"
                                        value={outofStock}
                                        checked={outofStock}
                                        onChange={(e) =>
                                          setOutofStock(e.target.checked)
                                        }
                                      />
                                    </Col>
                                    <Col className="d-flex justify-content-center align-items-center">
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        // onClick={(e) => setModel(false)}
                                        onClick={(e) =>
                                          updatepacksize(e, product, packsize)
                                        }
                                      >
                                        UPDATE
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row className="g-2  m-2 "></Row>
                                </div>
                              </>
                            ) : (
                              <>
                                <div key={pacindex}>
                                  <Row className="g-2  m-2 ">
                                    <Col
                                      md
                                      className="d-flex justify-content-center align-items-center"
                                    >
                                      <img
                                        src={packsize.ImgUrlDesk}
                                        height={100}
                                        alt="img"
                                      />
                                    </Col>
                                    <Col md>
                                      <Form.Label htmlFor="disabledTextInput">
                                        Pack Size Name
                                      </Form.Label>

                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Category"
                                        value={packsize.PackSize}
                                        disabled
                                      />
                                    </Col>

                                    <Col md>
                                      <Form.Label htmlFor="disabledTextInput">
                                        MRP
                                      </Form.Label>

                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Category"
                                        value={packsize.Mrp}
                                        disabled
                                      />
                                    </Col>
                                    <Col md>
                                      <Form.Label htmlFor="disabledTextInput">
                                        Price
                                      </Form.Label>

                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Category"
                                        value={packsize.SellingPrice}
                                        disabled
                                      />
                                    </Col>
                                    <Col>
                                      <Form.Label htmlFor="disabledTextInput">
                                        Out Of Stock
                                      </Form.Label>
                                      <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label=" "
                                        className="d-flex justify-content-center align-items-center"
                                        value={packsize.OutOfStack}
                                        checked={packsize.OutOfStack}
                                        // onChange={(e) => statusChange(e, packsize)}
                                        disabled
                                      />
                                    </Col>

                                    <Col className="d-flex justify-content-center align-items-center">
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(e) => {
                                          setModel(true);
                                          setSelectPack(packsize._id);
                                          setPrice(packsize.SellingPrice);
                                          setOutofStock(packsize.OutOfStack);
                                          setMrp(packsize.Mrp);
                                          setPacksizename(packsize.PackSize);
                                        }}
                                      >
                                        EDIT
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row className="g-2  m-2 "></Row>
                                </div>
                              </>
                            )}
                          </>
                        ))}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ProductPriceEdit;
