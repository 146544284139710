import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  offerbannertotal: localStorage.getItem("offerbannertotal")
    ? JSON.parse(localStorage.getItem("offerbannertotal"))
    : [],
  mobileimage: "",
  desktopimage: "",
  mobileimageLoading: true,
  desktopimageLoading: true,
  isLoading: true,
  offerbannerLoading: true,
  delofferbannerLoading: true,
};

  export const getOfferBanner = createAsyncThunk(
    "OfferBanner/getOfferBanner",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/offersbanner/all`;
        const resp = await axios(url);
        return resp.data.offersbanners;
      } catch (error) {
        return thunkAPI.rejectWithValue("404 Not Found");
      }
    }
);
  
export const OfferBannerPost = createAsyncThunk(
  "OfferBanner/OfferBannerPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/offersbanner/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("offersbanner Not create");
    }
  }
);

export const OffersBannerMobileImages = createAsyncThunk(
  "OffersBanner/OffersBannerMobileImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/offersbanner/offersbannermobileimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("offersbannermobileimages Not create");
    }
  }
);

export const OffersBannerDesktopImages = createAsyncThunk(
  "OffersBanner/OffersBannerDesktopImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/offersbanner/offersbannerdeskimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("offersbannerdeskimages Not create");
    }
  }
);


const OffersBannerSlice = createSlice({
  name: "Offersbanner",
  initialState,
  extraReducers: {
    [getOfferBanner.pending]: (state) => {
      state.isLoading = true;
    },
    [getOfferBanner.fulfilled]: (state, action) => {
      state.offerbannertotal = action.payload;

      state.isLoading = false;
      state.offerbannerLoading = false;
      localStorage.setItem(
        "offerbannertotal",
        JSON.stringify(state.offerbannertotal)
      );
    },
    [getOfferBanner.rejected]: (state, action) => {
      state.isLoading = true;
    },

    [OfferBannerPost.pending]: (state) => {
      state.offerbannerLoading = true;
    },

    [OfferBannerPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.offerbannertotal = [
          ...state.offerbannertotal,
          action.payload.offersbanner,
        ];
        localStorage.setItem(
          "offerbannertotal",
          JSON.stringify(state.offerbannertotal)
        );
      }
      state.offerbannerLoading = false;
    },
    [OfferBannerPost.rejected]: (state, action) => {
      state.offerbannerLoading = true;
    },

    [OffersBannerMobileImages.pending]: (state) => {
      state.mobileimageLoading = true;
    },
    [OffersBannerMobileImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.mobileimage = action.payload.mobileImages;
      }
      state.mobileimageLoading = false;
    },
    [OffersBannerMobileImages.rejected]: (state, action) => {
      state.mobileimageLoading = true;
    },

    [OffersBannerDesktopImages.pending]: (state) => {
      state.desktopimageLoading = true;
    },
    [OffersBannerDesktopImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.desktopimage = action.payload.desktopImages;
      }
      state.desktopimageLoading = false;
    },
    [OffersBannerDesktopImages.rejected]: (state, action) => {
      state.desktopimageLoading = true;
    },
  },
});

export default OffersBannerSlice.reducer;