import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import BeatLoader from "react-spinners/BeatLoader";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const WalletHistory = () => {
  const dispatch = useDispatch();

  const [temploading, setTempLoading] = useState(true);
  const [walletHistory, setWalletHistory] = useState([]);
  const [search, setSearch] = useState("");
  const [filtered, setfiltered] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/wallet/all`;
      const fetchwalletHistory = await axios.get(url);
      if (fetchwalletHistory.data.success === true) {
        const wallettotal = fetchwalletHistory.data.wallets;
        setWalletHistory(wallettotal);
        setTempLoading(false);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    let walletHistoryFilter = walletHistory;

    if (search !== "") {
      walletHistoryFilter = walletHistoryFilter.filter((bushisto) => {
        return bushisto.Mobile.toLowerCase().match(search.toLowerCase());
      });
    }

    let reverdata = walletHistoryFilter.slice().reverse();
    setfiltered(reverdata);
  }, [search, walletHistory]);

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "Name",
      width: 100,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto" style={{ textTransform: "uppercase" }}>
            {params.row.ClientName.slice(18)}
          </div>
        );
      },
    },

    {
      field: "Mobile",
      headerName: "Mobile",
      width: 150,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "Type",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto" style={{ textTransform: "uppercase" }}>
            {params.row.Type}
          </div>
        );
      },
    },

    {
      field: "transactionAmount",
      headerName: "Used Amount",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "availableAmount",
      headerName: "Available Amount",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "previousAmount",
      headerName: "Previous Amount",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="card m-4 d-flex">
            <Row className="g-2  m-2 w-50 ">
              <Col md>
                <Form.Label htmlFor="disabledTextInput">Search</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Search by number"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Col>
            </Row>
            <div className="card">
              {temploading ? (
                <>
                  <div className="d-flex justify-content-center loadingMain">
                    <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                      Wallet History is being fetched
                    </h4>
                    <BeatLoader
                      color={"#36d7b7"}
                      loading={temploading}
                      size={10}
                      className="mt-2 mx-2"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{ height: 650, width: "100%" }}
                    className="d-flex m-auto text-align-center"
                  >
                    <DataGrid
                      rows={filtered}
                      columns={columns}
                      getRowId={(row) => row._id}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default WalletHistory;
