import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  infocusTodaytotal: localStorage.getItem("infocusTodaytotal")
    ? JSON.parse(localStorage.getItem("infocusTodaytotal"))
    : [],
  mobileimage: "",
  desktopimage: "",
  posterimage: "",
  mobileimageLoading: true,
  desktopimageLoading: true,
  posterimageLoading: true,
  isLoading: true,
  isInfocusTodayLoading: true,
  delInfocusTodayLoading: true,
};


export const getInfocusToday = createAsyncThunk(
    "InfocusToday/getInfocusToday",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/infocustoday/all`;
        const resp = await axios(url);
        return resp.data.infocusToday;
      } catch (error) {
        return thunkAPI.rejectWithValue("404 Not Found");
      }
    }
  );
  
  export const InfocusTodayPost = createAsyncThunk(
    "InfocusToday/InfocusTodayPost",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/infocustoday/new`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("infocustoday Not create");
      }
    }
  );
  
  export const DesktopImages = createAsyncThunk(
    "Desktop/DesktopImages",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/infocustoday/deskimages`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("DesktopImages Not create");
      }
    }
  );
  
  export const MobileImages = createAsyncThunk(
    "Mobile/MobileImages",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/infocustoday/mobileimages`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("MobileImages Not create");
      }
    }
  );
  
  export const MobilePosterImages = createAsyncThunk(
    "MobilePoster/MobilePosterImages",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/infocustoday/posterimages`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("MobilePosterImages Not create");
      }
    }
  );
  
  export const InfocustodayDelete = createAsyncThunk(
    "Infocustoday/InfocustodayDelete",
    async (id, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/infocustoday/${id}`;
        const resp = await axios.delete(url, id, config);
        const myreturn = {
          success: resp.data.success,
          id: id,
        };
        return myreturn;
      } catch (error) {
        return thunkAPI.rejectWithValue("infocustoday Not create");
      }
    }
  );

  const InfocusTodaySlice = createSlice({
    name: "InfocusToday",
    initialState,
    reducers: {},
    extraReducers: {
      [getInfocusToday.pending]: (state) => {
        state.isLoading = true;
      },
      [getInfocusToday.fulfilled]: (state, action) => {
        state.infocusTodaytotal = action.payload;
  
        state.isLoading = false;
        state.isInfocusTodayLoading = false;
        localStorage.setItem(
          "infocusTodaytotal",
          JSON.stringify(state.infocusTodaytotal)
        );
      },
      [getInfocusToday.rejected]: (state) => {
        state.isLoading = true;
      },
  
      [InfocusTodayPost.pending]: (state) => {
        state.isInfocusTodayLoading = true;
      },
  
      [InfocusTodayPost.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.infocusTodaytotal = [
            ...state.infocusTodaytotal,
            action.payload.infocusToday,
          ];
          localStorage.setItem(
            "infocusTodaytotal",
            JSON.stringify(state.infocusTodaytotal)
          );
        }
        state.isInfocusTodayLoading = false;
      },
  
      [InfocusTodayPost.rejected]: (state) => {
        state.isInfocusTodayLoading = true;
      },
  
      [DesktopImages.pending]: (state) => {
        state.desktopimageLoading = true;
      },
      [DesktopImages.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.desktopimage = action.payload.desktopImages;
        }
        state.desktopimageLoading = false;
      },
      [DesktopImages.rejected]: (state) => {
        state.desktopimageLoading = true;
      },
  
      [MobileImages.pending]: (state) => {
        state.mobileimageLoading = true;
      },
      [MobileImages.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.mobileimage = action.payload.mobileImages;
        }
        state.mobileimageLoading = false;
      },
      [MobileImages.rejected]: (state) => {
        state.mobileimageLoading = true;
      },
  
      [MobilePosterImages.pending]: (state) => {
        state.posterimageLoading = true;
      },
      [MobilePosterImages.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.posterimage = action.payload.posterImages;
        }
        state.posterimageLoading = false;
      },
      [MobilePosterImages.rejected]: (state) => {
        state.posterimageLoading = true;
      },
  
      [InfocustodayDelete.pending]: (state) => {
        state.delInfocusTodayLoading = true;
      },
  
      [InfocustodayDelete.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.infocusTodaytotal = state.infocusTodaytotal.filter(
            (slider) => slider._id !== action.payload.id
          );
          localStorage.setItem(
            "infocusTodaytotal",
            JSON.stringify(state.infocusTodaytotal)
          );
        }
        state.delInfocusTodayLoading = false;
      },
  
      [InfocustodayDelete.rejected]: (state) => {
        state.delInfocusTodayLoading = true;
      },
    },
  });
  
  export const {} = InfocusTodaySlice.actions;
  export default InfocusTodaySlice.reducer;

