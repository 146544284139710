import React, { useEffect } from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";
import { groceryPost } from "../../../redux/grocery/grocerySlice";

const GroceryBulkUpload = () => {
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const [data, setData] = useState("");
  const [supcategory, setSupCategory] = useState("");
  const [supcategoryid, setSupCategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [uploadedProductName, setUploadedProductName] = useState("");
  const [erroeuploadedProductName, setErrorUploadedProductName] = useState([]);
  const dispatch = useDispatch();
  const supercat = [
    { id: "63d767855658519287238b63", name: "Supermart" },
    { id: "63d8da2bd405398b770b508f", name: "Non-veg" },
    { id: "63d8da4ad405398b770b50aa", name: "Vegetables & fruits" },
  ];
  const selectSuperCat = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupCategoryid(value);
    setSupCategory(text);
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };
  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (supercat) => supercat.superCategoryId === supcategoryid
    );
    const slotFilter = catbysupercat.sort((a, b) => (a.name > b.name ? 1 : -1));
    setCategories(slotFilter);
  }, [supcategoryid, categorytotal]);

  useEffect(() => {
    const catbysupercat = subcategorytotal.filter(
      (supercat) => supercat.categoryId === categoryid
    );
    const slotFilter = catbysupercat.sort((a, b) => (a.name > b.name ? 1 : -1));
    setSubcat(slotFilter);
  }, [categoryid, subcategorytotal]);

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    console.log(datas);
    setData(datas);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    let numbers = 0;
    let errordata = [];
    for (let i in data) {
      let myrow = data[i];
      numbers = i;
      const formData = {
        ItemName: myrow.ItemName,
        Url: myrow.Url,
        superCategoryId: supcategoryid,
        superCategory: supcategory,
        Category: category,
        CatId: categoryid,
        SubCat: subcategory,
        SubCatId: subcategoryid,
        KeyWords: myrow.KeyWords,
        Description: myrow.Description,
        Title: myrow.Title,
        About: myrow.About,
        Ingredient: myrow.Inqredient,
        ProductInfo: myrow.ProductInfo,
        Type: myrow.Type,
        Rating: myrow.Reting,
        Brand: myrow.Brand,
        Recommends: myrow.Recommends,
        HotProducts: myrow.HotProducts,
        Trending: myrow.Trending,
        Offers: myrow.Offers,
        Multi: myrow.Multi,
        Single: myrow.Single,
        Cashback: myrow.Cashback,
        Options: myrow.Optios,
        Pcb1: myrow.Pcb1,
        Pcb2: myrow.Pcb2,
        Pcb3: myrow.Pcb3,
        ProductId: myrow.ItemId,
      };
      console.log(formData);
      const uploadProducts = await dispatch(groceryPost(formData));

      if (uploadProducts.payload.success) {
        setUploadedProductName(
          `${numbers}) ${myrow.ItemName}  upload successfull`
        );
      } else {
        // errordata = [...errordata, myrow];
        console.log(errordata.length, "data.length");
        // setErrorUploadedProductName(
        //   `${numbers}) ${myrow.ItemName} this are not uploaded`
        // );
        // setErrorUploadedProductName(data);
      }
    }
    
  };

  return (
    <div className="content-wrapper">
      <div className="employeesContainer">
        <form onSubmit={handlesubmit}>
          <div className="card m-4">
            <div className="card-footer">
              <h5>Grocery Bulk Upload</h5>
            </div>
            <div className="card-body">
              <h6 className="ps-3">Upload Excel File *</h6>
              <Row className="g-2  m-2">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">
                    Super Category
                  </Form.Label>
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => {
                      selectSuperCat(e);
                    }}
                    value={supercat.id}
                    name={supercat.name}
                  >
                    <option value="0">{"Select Super Category"}</option>
                    {supercat.map((data) => (
                      <option
                        key={data.id}
                        value={data.id}
                        name={data.name}
                        required
                      >
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">Category</Form.Label>
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => {
                      selectCategory(e);
                    }}
                    value={categories._id}
                    name={categories.name}
                  >
                    <option value="0">{"Select Category"}</option>
                    {categories.map((data) => (
                      <option
                        key={data._id}
                        value={data._id}
                        name={data.name}
                        required
                      >
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">
                    Sub-Category
                  </Form.Label>
                  <Form.Select
                    aria-label="Floating label select example"
                    onChange={(e) => {
                      selectSubCategory(e);
                    }}
                    value={subCat._id}
                    name={subCat.name}
                  >
                    <option value="0">{"Select Sub-Category"}</option>
                    {subCat.map((data) => (
                      <option
                        key={data._id}
                        value={data._id}
                        name={data.name}
                        required
                      >
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="g-2  m-2">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput"></Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="enter name....."
                    onChange={(e) => handlefile(e)}
                  />
                </Col>
                <Col md>
                  <button
                    className="btn btn-primary me-2 mt-4 ml-5 "
                    type="submit"
                  >
                    Grocery Bulk Upload
                  </button>
                </Col>
              </Row>

              <p style={{ color: "green" }} className="mt-2">
                {uploadedProductName}
              </p>
              {erroeuploadedProductName.map((data, index) => (
                <p style={{ color: "red" }} className="mt-2">
                  {index + 1} ) {data.ItemName} this are not uploaded
                </p>
              ))}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GroceryBulkUpload;
