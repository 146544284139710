
 import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { subCategoryPost,subCategoryImages,validateSlugUrl} from "../../../redux/subCategory/subCategorySlice";

const AddSubCategory = () => {
  const { mobileimage, desktopimage, isSubDeskImageLoading} = useSelector((store) => store.subCategories);

  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { categorytotal } = useSelector((store) => store.categoryAll);

  const [names, setNames] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [supercat, setSupercat] = useState("");
  const [supercatid, setSupercatid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [catbySupercat, setCatbySupercat] = useState([]);
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");


  const config = {
    Headers: { "Content-Type": "application/json" },
  };

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setNames(value);
    setCheckSlugUrl("");
    const subcatSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(subcatSlug);

    if (subcatSlug !== "") {
      let responce = await dispatch(validateSlugUrl(subcatSlug));
      if (responce.payload.success) {
        setError("Sub Category alredy exist");
        setErrorcolor("red");
      } else if (subcatSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (subcatSlug.length >= 3) {
        setErrorcolor("");
        setError("");
        setCheckSlugUrl(subcatSlug);
      }
    }
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    if(checkslugUrl !==""){
      const formData = {
        name: names.charAt(0).toUpperCase() + names.slice(1).toLowerCase(),
        slugUrl: slugUrl,
        superCategory: supercat,
        superCategoryId: supercatid,
        category: category,
        categoryId: categoryid,
        metaTitle: metatitle,
        metaKeyword: metakeyword,
        metaDesc: metadesc,
        mobileImage: mobileimage,
        desktopImage: desktopimage,
      };

    console.log(formData);
    const data = await dispatch(subCategoryPost(formData));

    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("Sub-Category Added");
        setNames("");
        setSlugUrl("");
        setCategory("");
        setMetatitle("");
        setMetakeyword("");
        setMetadesc("");
      } else {
        alert(data.message);
      }
    }
  }else {
    alert("please enter correct values");
  }
  };


  const selectsuperCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercatid(value);
    setSupercat(text);
  };
    const selectCategory = (evt) => {
      const { value } = evt.target;
      const index = evt.nativeEvent.target.selectedIndex;
      const text = evt.nativeEvent.target[index].text;
      setCategoryid(value);
      setCategory(text);
  };
  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (cat) => cat.superCategoryId === supercatid
    );
    setCatbySupercat(catbysupercat);
  }, [supercatid,categorytotal]);
  

  const dispatch = useDispatch();

  const imagesChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(subCategoryImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Sub-Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category Name
                      </Form.Label>

                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectsuperCategory(e);
                        }}
                        value={superCatTotal._id}
                        name={superCatTotal.name}
                      >
                        <option value="0">{"Select Super Category"}</option>
                        {superCatTotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category Name
                      </Form.Label>

                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={catbySupercat._id}
                        name={catbySupercat.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {catbySupercat.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={names}
                        onChange={(e) => verifyslugurl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Sub-Category Images</Form.Label>
                      <Form.Control type="file" required onChange={imagesChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 291px * 200px
                      </p>
                      <div>
                        {isSubDeskImageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={desktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col>
                      <Form.Label>Meta Title *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    
                    <Col>
                      <Form.Label>Meta Keyword *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Descriptrion *</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                
                <div className="card-footer ">
                  {checkslugUrl !== "" &&
                  supercatid !=="" &&
                   names !== "" && categoryid !=="" &&
                   !isSubDeskImageLoading ? (
                    <button className="btn btn-primary" type="submit">
                      Add Sub Category
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Sub Category
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddSubCategory;
