import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import {
  superCategoryDelete,
  updateSuperCategory,
} from "../../../redux/superCategory/superCategorySlice";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { useEffect } from "react";

const ShowProducts = () => {
  const dispatch = useDispatch();

  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { adminData } = useSelector((store) => store.auth);

  const [statusG, setStatusG] = useState(false);

  const [massageStatus, setMassageStatus] = useState(false);
  const [supermartStatus, setsupermartStatus] = useState(false);
  const [massageText, setMassageText] = useState("");
  const [massageTextId, setMassageTextId] = useState("");

  useEffect(() => {
    const getData = async () => {
      const url = `${Baseurl}/api/v1/massage/getmassage`;

      const respo = await axios.get(url);
      if (respo.data.success === true) {
        const respoData = respo.data.message;
        setMassageStatus(respoData.massageShown);
        setsupermartStatus(respoData.superMartShown);
        setMassageText(respoData.MassageText);
        setMassageTextId(respoData._id);
      }
    };
    getData();
  }, []);

  const updateMassageClick = async (e) => {
    e.preventDefault();

    const formData = {
      _id: massageTextId,
      MassageText: massageText,
    };
    const config = {
      Headers: { "Content-Type": "application/json" },
    };
    const url = `${Baseurl}/api/v1/massage/${formData._id}`;
    const resp = await axios.put(url, formData, config);
  };

  const statusChangeSupermart = async (e) => {
    e.preventDefault();
    const value = e.target.checked;

    const formData = {
      _id: massageTextId,
      massageShown: value,
    };
    const config = {
      Headers: { "Content-Type": "application/json" },
    };
    const url = `${Baseurl}/api/v1/massage/${formData._id}`;
    const resp = await axios.put(url, formData, config);

    setMassageStatus(value);
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div className="employeesContainer">
            <div className="card m-4">
              <div className="card-footer">
                <h5>Change Massage Status</h5>
              </div>
              <div className="card-body">
                <Row className="g-2  m-2">
                  <Col>
                    <Form.Label>Show Massage</Form.Label>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=" "
                      value={massageStatus}
                      checked={massageStatus}
                      onChange={(e) => statusChangeSupermart(e)}
                    />
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col>
                    <Form.Label>Meta Title *</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Meta Title......"
                      style={{ height: "100px" }}
                      value={massageText}
                      onChange={(e) => setMassageText(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="card-footer ">
                <button
                  className="btn btn-primary"
                  onClick={(e) => updateMassageClick(e)}
                >
                  Massage Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ShowProducts;
