import React from "react";
import { Fragment } from "react";


const DeliveredExport = () => {
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            {/* <form onSubmit={handlesubmit}> */}
            <div className="card m-4">
              <div className="card-footer">
                <h5>Delivered Export</h5>
              </div>
              <div className="card-body m-4">
                <button className="btn btn-primary" type="submit">
                  Export Excel
                </button>
              </div>
            </div>

            <div className="card m-4">
              <div className="card-footer">
                <h5>Cancelled Export</h5>
              </div>
              <div className="card-body m-4">
                <button className="btn btn-primary" type="submit">
                  Export Excel
                </button>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default DeliveredExport;
