import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import {
  subCatBannerPost,
  validateSubCatBannerUrl,
  subCatBannerMobileImages,
  subCatBannerDesktopImages,
  subCatBannerDelete,
} from "../../../redux/subCatBanner/subCatBannerSlice";
import {
  DesktopImages,
  MobileImages,
  MobilePosterImages,
  TodayFeaturedPost,
} from "../../../redux/AllBanner&Slider/TodayFeaturedSlice";

const AddTodayFeatured = () => {
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const { superCatTotal } = useSelector((store) => store.superCategory);
//   const {
//     mobileimage,
//     desktopimage,
//     mobileimageLoading,
//     desktopimageLoading,
//     subcatbannertotal,
//   } = useSelector((store) => store.Subcatbanner);
  const {
    todayfeaturedtotal,
    mobileimage,
    desktopimage,
    posterimage,
    mobileimageLoading,
    desktopimageLoading,
    posterimageLoading,
  } = useSelector((store) => store.TodayFeatured);
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [show, setShow] = useState(Boolean(0));
  const [checkurl, setCheckurl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [subCat, setSubcat] = useState([]);
  const [catbysuper, setCatbysuper] = useState([]);


  const [supcategory, setSupcategory] = useState("");
  const [supcategoryid, setSupcategoryid] = useState("");

  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (cat) => cat.superCategoryId === supcategoryid
    );
    setCatbysuper(catbysupercat);
  }, [supcategoryid, categorytotal]);

  useEffect(() => {
    const subcatbycat = subcategorytotal.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategorytotal]);

  const selectSuperCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;

    if (value === "Select Super Category") {
      setSupcategoryid("");
      setSupcategory("");
    } else {
      setSupcategoryid(value);
      setSupcategory(text);
    }
  };

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

  const desktopChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(DesktopImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const mobileChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(MobileImages({ mobileImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const mobilePosterChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(MobilePosterImages({ posterImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const dispatch = useDispatch();

  const verifyurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckurl("");
    const subcatbannerurl = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setUrl(subcatbannerurl);

    if (subcatbannerurl !== "") {
      let responce = await dispatch(validateSubCatBannerUrl(subcatbannerurl));
      if (responce.payload.success) {
        setError("subcat banner already exist");
        setErrorcolor("red");
      } else if (subcatbannerurl.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckurl("");
      } else if (subcatbannerurl.length >= 3) {
        setErrorcolor(" ");
        setError("");
        setCheckurl(subcatbannerurl);
      }
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (checkurl !== "") {
      const formData = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        URL: url,
        superCategoryId: supcategoryid,
        superCategory: supcategory,
        Category: category,
        CatId: categoryid,
        SubCat: subcategory,
        SubCatId: subcategoryid,
        MobilePoster:posterimage,
        DeskImg: mobileimage,
        MblImg: desktopimage,
        show: show,
      };
      console.log(formData);
      const data = await dispatch(TodayFeaturedPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("SubCat Banner Added");
          setName("");
          setUrl("");
          setCategory("");
          setShow("");
          setCategoryid("");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  // slider list start

  const deleteClick = async (e, id) => {
    dispatch(subCatBannerDelete(id));
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "SLIDER NAME",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "MOBILE IMAGE",
      width: 240,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.MblImg}
              width={100}
              alt="mobileimage"
            />
          </>
        );
      },
    },
    {
      headerName: "DESKTOP IMAGE",
      width: 240,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.DeskImg}
              width={100}
              alt="desktopimage"
            />
          </>
        );
      },
    },
    {
      headerName: "POSTER IMAGE,",
      width: 240,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.MobilePoster}
              width={100}
              alt="MobilePosterimage"
            />
          </>
        );
      },
    },
    {
      field: "ACTION",
      width: 170,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              onClick={(e) => deleteClick(e, cellValues.row._id)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Today Featured Banner</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSuperCategory(e);
                        }}
                        value={superCatTotal._id}
                        name={superCatTotal.name}
                      >
                        <option value="0">{"Select Super Category"}</option>
                        {superCatTotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={catbysuper._id}
                        name={catbysuper.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {catbysuper.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub-Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={subCat._id}
                        name={subCat.name}
                      >
                        <option value="0">{"Select Sub Category"}</option>
                        {subCat.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        required
                        onChange={(e) => verifyurl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    {/* <Col md>
                      <Form.Label htmlFor="disabledTextInput">Url *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={url}
                        required
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </Col> */}
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control
                        type="file"
                        required
                        onChange={desktopChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 382px * 231px
                      </p>
                      <div>
                        {desktopimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={desktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Mobile Image</Form.Label>
                      <Form.Control
                        type="file"
                        required
                        onChange={mobileChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 382px * 231px
                      </p>
                      <div>
                        {mobileimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={mobileimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2 w-50">
                    <Col md>
                      <Form.Label>Mobile Poster Image</Form.Label>
                      <Form.Control
                        type="file"
                        required
                        onChange={mobilePosterChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 869px * 853px
                      </p>
                      <div>
                        {posterimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={posterimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col className="d-flex ">
                      <div className="me-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="show"
                          checked={show === true}
                          onChange={(e) => {
                            setShow(e.target.checked);
                          }}
                        />
                        <Form.Label>Show</Form.Label>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {checkurl !== "" &&
                  categoryid !== "" &&
                  subcategoryid !== "" &&
                  supcategory !== "" &&
                  supcategoryid !== "" &&
                  subcategory !== "" &&
                  !mobileimageLoading &&
                  !posterimageLoading &&
                  !desktopimageLoading ? (
                    <button className="btn btn-primary" type="submit">
                      Add Today Featured Banner
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Today Featured Banner
                    </button>
                  )}
                </div>
              </div>
            </form>

            <div className="card me-2 ms-2">
              <div
                style={{ height: 650, width: "100%"}}
                className="d-flex m-auto text-align-center"
              >
                <DataGrid
                  rows={todayfeaturedtotal}
                  columns={columns}
                  getRowId={(row) => row._id}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  //   checkboxSelection
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddTodayFeatured;
