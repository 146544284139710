import React, { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { stockPost } from "../../../redux/stock/stockSlice";

const AddStock = () => {
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const { grocerytotal } = useSelector((store) => store.groceries);
  console.log(grocerytotal);
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [subcategorymap, setSubcategorymap] = useState([]);
  const [groceryMap, setGroceryMap] = useState([]);

  const [productid, setProductid] = useState("");
  const [product, setProduct] = useState("");
  const [packsize, setPacksize] = useState("");
  const [packsizeid, setPacksizeid] = useState("");
  const [stock, setStock] = useState("");
  const [expiry, setExpiry] = useState("");
  const [batch, setBatch] = useState("");
  const [scanner, setScanner] = useState("");
  const [stocktodispatch, setStocktodispatch] = useState("");
  const [productbyPack, setProductbyPack] = useState([]);

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };
  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };

  useEffect(() => {
    const subcatbycat = subcategorytotal.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcategorymap(subcatbycat);
  }, [categoryid, subcategorytotal]);

  const selectProduct = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setProductid(value);
    setProduct(text);
    console.log(value);
    console.log(text);
  };

  useEffect(() => {
    const prodcutbycat = grocerytotal.filter(
      (product) => product.CatId === categoryid
    );
    console.log(prodcutbycat);
    setGroceryMap(prodcutbycat);
  }, [grocerytotal, categoryid]);

  const selectPacksize = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setPacksizeid(value);
    setPacksize(text);
  };

  useEffect(() => {
    const packdata = grocerytotal.filter((pack) => pack._id === productid);
    console.log(packdata);
    console.log(packdata.PackSizes);
    // setProductbyPack(packdata.PackSizes);
  }, [productid, grocerytotal]);

  const dispatch = useDispatch();
  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      Category: category,
      CatId: categoryid,
      SubCat: subcategory,
      SubCatId: subcategoryid,
      ProductId: productid,
      ProductName: product,
      PackSizeId: packsizeid,
      PackSize: packsize,
      Stock: stock,
      ExpiryDate: expiry,
      BatchNumber: batch,
      ScannerCode: scanner,
      StockToDispatch: stocktodispatch,
    };
    console.log(formData);
    // dispatch(stockPost(formData));
  };
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Stock</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={categorytotal._id}
                        name={categorytotal.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {categorytotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub-Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={subcategorymap._id}
                        name={subcategorymap.name}
                      >
                        <option value="0">{"Select Sub-Category"}</option>
                        {subcategorymap.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectProduct(e);
                        }}
                        value={groceryMap._id}
                        name={groceryMap.ItemName}
                      >
                        <option value="0">{"Select Product"}</option>
                        {groceryMap.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.ItemName}
                            required
                          >
                            {data.ItemName}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Pack-Size
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        // onChange={(e) => {
                        //   selectPacksize(e);
                        // }}
                        // value={productbyPack._id}
                        // name={productbyPack.PackSize}
                      >
                        <option value="0">{"Select Pack-Size"}</option>
                        {/* {productbyPack.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.PackSize}
                            required
                          >
                            {data.PackSize}
                          </option>
                        ))} */}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Stock</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Stock"
                        value={stock}
                        required
                        onChange={(e) => setStock(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Expiry Date *
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Expiry Date"
                        value={expiry}
                        onChange={(e) => setExpiry(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Batch Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Batch Number"
                        value={batch}
                        onChange={(e) => setBatch(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Scanner Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Scanner Code"
                        value={scanner}
                        onChange={(e) => setScanner(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Stock To Dispatch
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Stock To Dispatch"
                        value={stocktodispatch}
                        onChange={(e) => setStocktodispatch(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Add Stock
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddStock;
