import React, { Fragment, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  employeesDelete,
  employeesPost,
  employeesUpdate,
} from "../../../redux/emplyees/employeesSlice";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";

const UpdateEmployee = () => {
  const { employeestotal } = useSelector((store) => store.Employees);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [addressproof, setAddressproof] = useState("");
  const [password, setPassword] = useState("");
  const [delivery, setDelivery] = useState(Boolean(0));
  const [storemanager, setStoremanager] = useState(Boolean(0));
  const [errormassage, setErrormassage] = useState("");
  const [employeeId, setemployeeId] = useState("");

  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setErrormassage("");
    setPhone(numbers);
  };

  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    const employeeid = params.id;
    const updateemployee = employeestotal.find(
      (employee) => employee._id === employeeid
    );
    setemployeeId(updateemployee._id);
    setName(updateemployee.Dname);
    setAddress(updateemployee.Daddress);
    setPhone(updateemployee.Dmobile);
    setAddressproof(updateemployee.Daddressproof);
    setPassword(updateemployee.Dpassword);
    setDelivery(updateemployee.DeliveryBoy);
    setStoremanager(updateemployee.StoreManager);
  }, [params.id]);

  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      Dname: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
      Daddress: address,
      Dmobile: phone,
      Daddressproof: addressproof,
      Dpassword: password,
      DeliveryBoy: delivery,
      StoreManager: storemanager,
      employeeid: employeeId,
    };
    console.log(formData);
    const data = await dispatch(employeesUpdate(formData));

    if (!data) {
      <div></div>;
    } else {
      if (data.payload.success) {
        alert("Employee Updated");
      } else {
        alert(data.message);
        console.log(data.error);
      }
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Update Employee</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update Employee</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Phone Number"
                        value={phone}
                        minLength={10}
                        maxLength={10}
                        pattern="[6789][0-9]{9}"
                        onChange={(e) => handleChange(e)}
                      />
                      <p style={{ color: "tomato" }}>{errormassage}</p>
                    </Col>
                    <Col md>
                      <Form.Label>Address Proof</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Pancard/Adharcard/Driving Licence "
                        value={addressproof}
                        onChange={(e) => setAddressproof(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Col>
                    <Col className="d-flex ">
                      <div className="me-4 mt-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="delivery"
                          checked={delivery === true}
                          onChange={(e) => {
                            setDelivery(e.target.checked);
                          }}
                        />
                        <Form.Label>Delivery</Form.Label>
                      </div>
                    </Col>
                    <Col className="d-flex ">
                      <div className="me-4 mt-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="storemanager"
                          checked={storemanager === true}
                          onChange={(e) => {
                            setStoremanager(e.target.checked);
                          }}
                        />
                        <Form.Label>Store Manager</Form.Label>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Update Employee
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateEmployee;
