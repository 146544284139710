import { configureStore } from "@reduxjs/toolkit";
import clientReducer from "./redux/client/clientSlice";
import authSlice from "./redux/Athentication/AuthSlice";
import superCategoryReducer from "./redux/superCategory/superCategorySlice"
import categoryAllReducer from "./redux/category/categorySlice";
import brandReducer from './redux/brand/brandSlice';
import subCategoriesReducer from "./redux/subCategory/subCategorySlice";
import groceriesReducer from "./redux/grocery/grocerySlice";
import productsReducer from "./redux/product/productSlice";
import subscriptionsReducer from "./redux/subscription/subscriptionSlice";
import ordersReducer from "./redux/order/orderSlice";
import SlidersReducer from "./redux/slider/sliderSlice";
import StocksReducer from "./redux/stock/stockSlice";
import CouponsReducer from './redux/coupon/couponSlice';
import EmployeesReducer from './redux/emplyees/employeesSlice';
import PinamountsReducer from "./redux/pinAmount/pinAmountSlice";
import OffersbannerReducer from "./redux/offersBanner/offersBannerSlice";
import TagsReducer from "./redux/tags/TagSlice";
import SubcatbannerReducer from "./redux/subCatBanner/subCatBannerSlice";
import VegPageReducer from "./redux/vegpage/VegPageSlice";
import TodayFeaturedReducer from "./redux/AllBanner&Slider/TodayFeaturedSlice";
import BestOffersReducer from "./redux/AllBanner&Slider/BestOffersSlice";
import TopBannerReducer from "./redux/AllBanner&Slider/TopBannerSlice";
import InfocusTodayReducer from "./redux/AllBanner&Slider/InFocusTodaySlice";
import FeaturedInWeekReducer from "./redux/AllBanner&Slider/FeaturedInWeekSlice";


export const store = configureStore({
  reducer: {
    clientAll: clientReducer,
    auth: authSlice.reducer,
    superCategory: superCategoryReducer,
    categoryAll: categoryAllReducer,
    brand: brandReducer,
    subCategories: subCategoriesReducer,
    groceries: groceriesReducer,
    products: productsReducer,
    subscriptions: subscriptionsReducer,
    orders: ordersReducer,
    Sliders: SlidersReducer,
    Stocks: StocksReducer,
    Coupons: CouponsReducer,
    Employees: EmployeesReducer,
    Pinamounts: PinamountsReducer,
    Offersbanner: OffersbannerReducer,
    Tags: TagsReducer,
    Subcatbanner: SubcatbannerReducer,
    VegPage: VegPageReducer,
    TodayFeatured: TodayFeaturedReducer,
    BestOffers: BestOffersReducer,
    TopBanner: TopBannerReducer,
    InfocusToday: InfocusTodayReducer,
    FeaturedInWeek: FeaturedInWeekReducer,



  },
});