import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  producttotal: localStorage.getItem("producttotal")
    ? JSON.parse(localStorage.getItem("producttotal"))
    : [],
  electronicsproduct: localStorage.getItem("producttotal")
    ? JSON.parse(localStorage.getItem("producttotal")).filter(
        (electronics) => electronics.superCategory === "Electronics"
      )
    : [],
  clothingproduct: localStorage.getItem("producttotal")
    ? JSON.parse(localStorage.getItem("producttotal")).filter(
        (clothing) => clothing.superCategory === "Clothing"
      )
    : [],
  softtoysproduct: localStorage.getItem("producttotal")
    ? JSON.parse(localStorage.getItem("producttotal")).filter(
        (softtoys) => softtoys.superCategory === "Soft-toys"
      )
    : [],
  mobileimage: "",
  desktopimage: "",
  isproductmobileimageLoading: true,
  isproductdeskimageLoading: true,
  isLoading: true,
  productLoading: true,
  delproductLoading: true,
};

  export const getProduct = createAsyncThunk(
    "Product/getProduct",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/product/all`;
        const resp = await axios(url);
        return resp.data.product;
      } catch (error) {
        return thunkAPI.rejectWithValue("404 Not Found");
      }
    }
  );
  export const productPost = createAsyncThunk(
    "product/productPost",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/product/new`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("product Not create");
      }
    }
  );

  export const productUpdate = createAsyncThunk(
    "product/productUpdate",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        console.log(formData);
        const url = `${Baseurl}/api/v1/product/${formData.productid}`;
        const resp = await axios.put(url, formData, config);
  
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("product Not create");
      }
    }
  );
  export const productDelete = createAsyncThunk(
    "product/productDelete",
    async (id, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/product/${id}`;
        const resp = await axios.delete(url, id, config);
        console.log(resp.data);
        const myreturn = {
          success: resp.data.success,
          id: id,
        };
        return myreturn;
      } catch (error) {
        return thunkAPI.rejectWithValue("product Not create");
      }
    }
  );

  export const productImages = createAsyncThunk(
    "product/productImages",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          maxBodyLength: Infinity,
        };
        const url = `${Baseurl}/api/v1/product/productimages`;
        const resp = await axios.post(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("product Not create");
      }
    }
  );

  export const validateProductSlugUrl = createAsyncThunk(
    "product/validateProductSlugUrl",
    async (slugurl , thunkAPI) => {
      let resp = {
        success: false,
        message: "new slugurl",
      }
      try {
        const url = `${Baseurl}/api/v1/product/slugurl/${slugurl}`;
        const resp = await axios.get(url);
        return resp.data;
      } catch (error) {
        return error;
      }
    }
  );

  
  const ProductSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
      updateProductMobileImage(state, action) {
        state.mobileimage = action.payload;
        state.isproductmobileimageLoading = false;
      },
      updateProductDeskimage(state, action) {
        state.desktopimage = action.payload;
        state.isproductdeskimageLoading = false;
      },
      
    },
    extraReducers: {

      [getProduct.pending]: (state) => {
        state.isLoading = true;
      },
      [getProduct.fulfilled]: (state, action) => {
        state.producttotal = action.payload;
        localStorage.setItem("producttotal", JSON.stringify(state.producttotal ));
        state.isLoading = false;
        state.productLoading = false;
      },
      [getProduct.rejected]: (state, action) => {
        state.isLoading = true;
      },
  
      [productPost.pending]: (state) => {
        state.productLoading = true;
      },
  
      [productPost.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.producttotal = [...state.producttotal, action.payload.product];
          
          state.electronicsproduct = state.producttotal.filter(
            (electronics) => electronics.superCategory === "Electronics"
          );
          state.clothingproduct = state.producttotal.filter(
            (clothing) => clothing.superCategory === "Clothing"
          );
          state.softtoysproduct = state.producttotal.filter(
            (softtoys) => softtoys.superCategory === "Soft-toys"
          );
          state.mobileimage = "";
          state.desktopimage = "";
          state.isproductmobileimageLoading = true;
          state.isproductdeskimageLoading = true;
          localStorage.setItem("producttotal", JSON.stringify(state.producttotal ));
        }
        state.productLoading = false;
      },
  
      [productPost.rejected]: (state, action) => {
        state.productLoading = true;
      },
  


      [productUpdate.pending]: (state) => {
        state.productLoading = true;
      },
  
      [productUpdate.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.producttotal = state.producttotal.filter(
            (product) => product._id !== action.payload.product._id
          );
          state.producttotal = [...state.producttotal, action.payload.product];
          state.mobileimage = "";
          state.desktopimage = "";
          state.isproductmobileimageLoading = true;
          state.isproductdeskimageLoading = true;
          state.isLoading = !state.isLoading;
          localStorage.setItem("producttotal", JSON.stringify(state.producttotal ));
        }
        state.productLoading = false;
        
      },
  
      [productUpdate.rejected]: (state, action) => {
        state.productLoading = true;
      },
  

      [productDelete.pending]: (state) => {
        state.delproductLoading = true;
      },
  
      [productDelete.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.producttotal = state.producttotal.filter(
            (product) => product._id !== action.payload.id
          );
          localStorage.setItem("producttotal", JSON.stringify(state.producttotal ));
        }
        state.delproductLoading = false;
      },
  
      [productDelete.rejected]: (state, action) => {
        state.delproductLoading = true;
      },

      [productImages.pending]: (state) => {
        state.isproductdeskimageLoading = true;
      },
      [productImages.fulfilled]: (state, action) => {
        if (action.payload.success) {
          state.mobileimage = action.payload.mobileimages;
          state.desktopimage = action.payload.desktopImages;
  
        }
  
        state.isproductdeskimageLoading = false;
        state.isproductmobileimageLoading = false;
      },
      [productImages.rejected]: (state) => {
        state.isproductdeskimageLoading = true;
      },
    },
  });
  export const {
    updateProductMobileImage,
    updateProductDeskimage
  } = ProductSlice.actions;
  export default ProductSlice.reducer;