import React from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";

const ClientData = () => {
  const { ordertotal } = useSelector((store) => store.orders);

  const columns = [
    {
      field: "ClientId",
      headerName: "ClientId",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ClientName",
      headerName: "ClientName",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Email",
      headerName: "Email",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Mobile",
      headerName: "Mobile",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
  ];
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            {/* <form onSubmit={handlesubmit}> */}
            <div className="card me-2 ms-2">
              <div className="card-footer">
                <h5>Send SMS</h5>
              </div>
              <div className="card-body">
                <Row className="g-2  m-2 w-50">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Single Message
                    </Form.Label>
                    <Form.Control
                      className="mb-4"
                      type="text"
                      placeholder="Enter Mobile Number"
                      // value={brand}
                      // required
                      // onChange={(e) => verifyslugurl(e)}
                    />
                    <Form.Control
                      as="textarea"
                      placeholder="Your Message"
                      style={{ height: "100px" }}
                      // value={brand}
                      // required
                      // onChange={(e) => verifyslugurl(e)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="card-footer">
                <button className="btn btn-primary ms-3" type="submit">
                  Send
                </button>
              </div>
            </div>
            {/* </form> */}

            <div className="card me-2 ms-2">
              <div className="card-body">
                <Row className="g-2  m-2 w-50">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Message All Client
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Your Message"
                      style={{ height: "100px" }}
                      // value={brand}
                      // required
                      // onChange={(e) => verifyslugurl(e)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="card-footer">
                <button className="btn btn-primary ms-3" type="submit">
                  Send
                </button>
              </div>
            </div>

            <div className="card me-2 ms-2">
              <div className="card-body">
                <Row className="g-2  m-2 w-50">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Search Client
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search Client"
                      // value={brand}
                      // required
                      // onChange={(e) => verifyslugurl(e)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="card-footer">
                <button className="btn btn-primary ms-3" type="submit">
                  Send
                </button>
              </div>
            </div>

            <div className="card me-2 ms-2">
              <div className="card-footer">
                <h5>Client Data</h5>
              </div>
              <div className="card-body m-4">
                <button className="btn btn-primary" type="submit">
                  Export Excel
                </button>
              </div>
            </div>

            <div className="card me-2 ms-2">
              <div className="card-footer">
                <h5>Client Details</h5>
              </div>
              <div className="card-body">
                <div
                  style={{ height: 650, width: "100%" }}
                  className="d-flex m-auto text-align-center"
                >
                  <DataGrid
                    rows={ordertotal}
                    columns={columns}
                    getRowId={(row) => row._id}
                    rowHeight={100}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ClientData;
