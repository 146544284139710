import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import {
  superCategoryDelete,
  updateSuperCategory,
} from "../../../redux/superCategory/superCategorySlice";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import { authActions } from "../../../redux/Athentication/AuthSlice";

const ListSuperCategory = () => {
  const { adminData } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const [statusG, setStatusG] = useState(adminData.showSupermart);

  const { superCatTotal } = useSelector((store) => store.superCategory);

  const [supcat, setSupCat] = useState([]);

  useEffect(() => {
    const slotFilter = [...superCatTotal].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    setSupCat(slotFilter);
  }, [superCatTotal]);

  const deleteClick = async (e, id) => {
    const data = await dispatch(superCategoryDelete(id));

    // if (data.payload.success) {
    //   alert("Category Deleted");
    //   window.location.reload(false);
    // } else {
    //   alert(data.message);
    //   console.log(data.payload.error);
    // }
  };

  const statusChangeSupermart = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;
    if (data !== "") {
      const formData = {
        _id: data._id,
        showSupermart: value,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/admin/${formData._id}`;
      const resp = await axios.put(url, formData, config);

      const respoadmin = resp.data;
      dispatch(
        authActions.signin({
          // token: respoadmin.token,
          isAuth: true,
          name: respoadmin.name,
          email: respoadmin.email,
          admin: respoadmin,
        })
      );
      setStatusG(value);
    }
  };
  const statusChange = async (e, data) => {
    e.preventDefault();
    console.log(data);
    const value = e.target.checked;

    if (data) {
      const formData = {
        supercatid: data._id,
        showProducts: value,
      };
      const updateCat = await dispatch(updateSuperCategory(formData));

      if (data.name === "Supermart") {
        const formData = {
          _id: "657147425855fb1b4052dbd7",
          superMartShown: !value,
        };
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/massage/${formData._id}`;
        const resp = await axios.put(url, formData, config);

      }
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "SUPER CATEGORY NAME",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "thumbnail",
    //   headerName: "IMAGE",
    //   width: 230,
    //   headerAlign: "center",

    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <img
    //           className="d-flex m-auto"
    //           src={params.row.thumbnail}
    //           width={100}
    //           alt="thumnail"
    //         />
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "STATUS",
    //   width: 150,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <div className="d-flex m-auto">
    //           <Col>
    //             <Form.Check
    //               type="switch"
    //               id="custom-switch"
    //               label=" "
    //               value={params.row.showProducts}
    //               checked={params.row.showProducts}
    //               onChange={(e) => statusChange(e, params.row)}
    //             />
    //           </Col>
    //         </div>
    //       </>
    //     );
    //   },
    // },

    {
      field: "ACTION",
      width: 140,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
            <Link to={`/admin/updatesupercategory/${params.row.slugUrl}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "SHOW PRODUCTS",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.showProducts}
                  checked={params.row.showProducts}
                  onChange={(e) => statusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    // {
    //   field: "ACTION.",
    //   width: 240,
    //   headerAlign: "center",
    //   renderCell: (cellValues) => {
    //     return (
    //       <div className="d-flex m-auto">
    //         <Button
    //           variant="contained"
    //           onClick={(e) => deleteClick(e, cellValues.row._id)}
    //           color="error"
    //         >
    //           Delete
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          {/* <div
            style={{ height: 100, width: "100%" }}
            className="d-flex m-auto text-align-center"
          >
            <div className="d-flex m-auto">
              <Col>
                <Form.Label>Show Supermart</Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={statusG}
                  checked={statusG}
                  onChange={(e) => statusChangeSupermart(e, adminData)}
                />
              </Col>
            </div>
          </div> */}
          <div
            style={{ height: 650, width: "100%" }}
            className="d-flex m-auto text-align-center"
          >
            <DataGrid
              rows={supcat}
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ListSuperCategory;
