

 import React, { useState } from "react";
 import { Fragment } from "react";
 import { DataGrid } from "@mui/x-data-grid";
 import { useSelector, useDispatch } from "react-redux";
 import { Button } from "@mui/material";
 import { Link } from "react-router-dom";
import { bannerTagDelete } from "../../../../redux/tags/TagSlice";

 const ListBannerTag = () => {
   const dispatch = useDispatch();

   const { bannertag } = useSelector((store) => store.Tags);

   const deleteClick = async (e, id) => {
     const data = await dispatch(bannerTagDelete(id));

     // if (data.payload.success) {
     //   alert("Category Deleted");
     //   window.location.reload(false);
     // } else {
     //   alert(data.message);
     //   console.log(data.payload.error);
     // }
   };
   // const statusChange = async (e, data) => {
   //   e.preventDefault();
   //   console.log(data);
   //   const value = e.target.checked;

   //   if (data) {
   //     const formData = {
   //       brandid: data._id,
   //       status: value,
   //     };
   //     console.log(formData);
   //     const updateCat = await dispatch(brandUpdate(formData));
   //   }
   // };
   const columns = [
     {
       field: "id",
       headerName: "S. No",
       filterable: false,
       renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
       align: "center",
       headerAlign: "center",
     },
     {
       field: "name",
       headerName: "NAME",
       width: 200,
       align: "center",
       headerAlign: "center",
     },
     {
       headerName: "IMAGE",
       width: 230,
       headerAlign: "center",

       renderCell: (params) => {
         return (
           <>
             <img
               className="d-flex m-auto"
               src={params.row.MblImg}
               width={100}
               alt="thumnail"
             />
           </>
         );
       },
     },
     // {

     //   headerName: "DESKTOP IMAGE",
     //   width: 230,
     //   headerAlign: "center",

     //   renderCell: (params) => {
     //     return (
     //       <>
     //         <img
     //           className="d-flex m-auto"
     //           src={params.row.DeskImg}
     //           width={100}
     //           alt="thumnail"
     //         />
     //       </>
     //     );
     //   },
     // },
     // {
     //   field: "STATUS",
     //   width: 150,
     //   headerAlign: "center",
     //   align: "center",
     //   renderCell: (params) => {
     //     return (
     //       <>
     //         <div className="d-flex m-auto">
     //           <Col>
     //             <Form.Check
     //               type="switch"
     //               id="custom-switch"
     //               label=" "
     //               value={params.row.status}
     //               checked={params.row.status}
     //               onChange={(e) => statusChange(e, params.row)}
     //             />
     //           </Col>
     //         </div>
     //       </>
     //     );
     //   },
     // },

     {
       field: "ACTION",
       width: 150,
       headerAlign: "center",
       renderCell: (params) => {
         return (
           <div className="d-flex m-auto">
             <Link to={`/admin/updatebrand/${params.row.slugUrl}`}>
               <Button variant="contained" color="primary">
                 EDIT
               </Button>
             </Link>
           </div>
         );
       },
     },
     {
       field: "ACTION.",
       width: 150,
       headerAlign: "center",
       renderCell: (cellValues) => {
         return (
           <div className="d-flex m-auto">
             <Button
               variant="contained"
               onClick={(e) => deleteClick(e, cellValues.row._id)}
               color="error"
             >
               Delete
             </Button>
           </div>
         );
       },
     },
   ];

   return (
     <>
       <Fragment>
         <div className="content-wrapper ">
           <div
             style={{ height: 650, width: "100%" }}
             className="d-flex m-auto text-align-center"
           >
             <DataGrid
               rows={bannertag}
               columns={columns}
               getRowId={(row) => row._id}
               pageSize={10}
               rowsPerPageOptions={[10]}
               checkboxSelection
             />
           </div>
         </div>
       </Fragment>
     </>
   );
 };

 export default ListBannerTag;
