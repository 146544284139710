import React, { Fragment } from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { OffersBannerDesktopImages, OffersBannerMobileImages } from "../../../redux/offersBanner/offersBannerSlice";

function AddOffersBanner() {
  const {
    mobileimage,
    desktopimage,
    mobileimageLoading,
    desktopimageLoading,
    offerbannertotal,
  } = useSelector((store) => store.Offersbanner);
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");

  const supercat = [
    { id: "63d767855658519287238b63", name: "Supermart" },
    { id: "63d8da2bd405398b770b508f", name: "Non-veg" },
    { id: "63d8da4ad405398b770b50aa", name: "Vegetables & fruits" },
  ];

  const selectSuperCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercategoryid(value);
    setSupercategory(text);
  };

  const dispatch = useDispatch();
  const verifyurl = async (e) => {
    const value = e.target.value;
    setName(value);
    const offerbannerurl = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setUrl(offerbannerurl);
  };

    const desktopChange = (e) => {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            dispatch(
              OffersBannerDesktopImages({ desktopImage: reader.result })
            );
          }
        };
        reader.readAsDataURL(file);
      });
    };
    const mobileChange = (e) => {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            dispatch(OffersBannerMobileImages({ mobileImage: reader.result }));
          }
        };
        reader.readAsDataURL(file);
      });
    };

  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
      URL: url,
      superCategoryId: supercategoryid,
      superCategory: supercategory,
      DeskImg: desktopimage,
      MblImg: mobileimage,
    };
    console.log(formData);
    //  const data = await dispatch(sliderPost(formData));
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Offers Banner</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSuperCategory(e);
                        }}
                        value={supercat.id}
                        name={supercat.name}
                      >
                        <option value="0">{"Select Super Category"}</option>
                        {supercat.map((data) => (
                          <option
                            key={data.id}
                            value={data.id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        required
                        onChange={(e) => verifyurl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Url *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        // value={url}
                        // required
                        // onChange={(e) => setUrl(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control
                        type="file"
                        required
                        onChange={desktopChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 1500px * 470px
                      </p>
                      <div>
                        {desktopimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={desktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Mobile Image</Form.Label>
                      <Form.Control
                        type="file"
                        required
                        onChange={mobileChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 500px * 226px
                      </p>
                      <div>
                        {mobileimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={mobileimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Add Offers Banner
                  </button>
                </div>
                {/* <div className="card-footer ">
                  {checkurl !== "" &&
                  categoryid !== "" &&
                  !mobileimageLoading &&
                  !desktopimageLoading ? (
                    <button className="btn btn-primary" type="submit">
                      Add Slider
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Slider
                    </button>
                  )}
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
}

export default AddOffersBanner;
